import { Component, DestroyRef, inject, OnInit, Signal } from '@angular/core';
import { Company } from '../../models/company';
import { CorporateHolderModel, IndividualHolderModel } from '../../models/securityRegistryRecord';
import { ChangeDictionaryHelper } from '../../models/shared/change-dictionary-helper.model';
import { ACNPipe } from '../../pipes/acnPipe';
import { ModalFormsService } from '../../services/modal-forms.service';
import { SecurityService } from '../../services/security.service';
import { CompanyProfileService } from '../company-profile/company-profile.service';
import {
  GenerateDocumentsHeaderComponent
} from "./components/generate-documents-header/generate-documents-header.component";
import {
  GenerateDocumentsSidebarComponent
} from "./components/generate-documents-sidebar/generate-documents-sidebar.component";
import { ClickableStepperComponent } from "../components/common/clickable-stepper/clickable-stepper.component";
import { StepperFormComponent } from "../modals/stepper-form/stepper-form.component";
import { IStep } from "../../models/step";
import { GenerateDocumentsStepsEnum } from "../../models/enums/generateDocumentsStepsEnum";
import {
  DocumentsChangesPreviewComponent
} from "./components/documents-changes-preview/documents-changes-preview.component";
import { GenerateDocumentsFormComponent } from "./components/generate-documents-form/generate-documents-form.component";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, firstValueFrom, Observable, of, Subject, tap } from 'rxjs';
import { RelationshipType, RelationshipTypeLabels } from '../../models/enums/relationshipTypeEnum';
import { Document } from "../../models/document";
import { DocumentStatusEnum } from '../../models/enums/documentStatusEnum';
import { DocumentsService } from "../../services/documents.service";
import { MenuService } from "../../services/menu.service";
import { EntityChangeData } from "../../models/entityChangeData";
import { SignDocumentsComponent } from "./components/sign-documents/sign-documents.component";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { Trust } from "../../models/trust";
import { TrustsService } from "../../services/trustsService";
import { EntityType } from "../../models/enums/entityType";
import { ShareholderRelationshipDetails } from "../../models/relationship";
import { BaseStepperFormComponent } from "../modals/stepper-form/base-stepper-component/base-stepper-form.component";
import { DocumentStepEnum } from "../../models/enums/documentStepEnum";
import { LodgeDocumentsComponent } from "./components/lodge-documents/lodge-documents.component";
import { AsicResponseComponent } from "./components/asic-response/asic-response.component";
import { AnnualSignDocumentsComponent } from "../annual-statement/annual-sign-documents/annual-sign-documents.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NgTemplateOutlet } from "@angular/common";
import { LodgementDeadline } from "../../models/documents";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { HttpErrorCode } from "../../models/enums/httpErorrEnum";
import { SigningStatus } from "../../models/enums/annualStatementEnums";
import { documentStatusToStep } from "../helpers/document-status-to-step";
import { AsicStatus } from "../../models/enums/asicStatus";
import { PageTitleComponent } from "../components/common/page-title/page-title.component";
import { ButtonComponent } from "../components/common/button/button.component";
import { AuthService } from '../../services/auth.service';
import { CompanyChangeOfficer } from "../../models/сompanyChangeOfficer";
import { CompanyChangeOfficerType } from "../../models/enums/companyChangeOfficerType";

export const ChangeGenerateDocumentsSteps: IStep<GenerateDocumentsStepsEnum>[] = [
  { label: 'Initiate Change', step: 0, disabled: false },
  { label: 'Generate Documents', step: 1, disabled: true },
  { label: 'Sign and Lodge Documents', step: 2, disabled: true },
  { label: 'ASIC Response', step: 3, disabled: true },
];

@Component({
  selector: 'app-generate-documents',
  standalone: true,
  imports: [
    GenerateDocumentsHeaderComponent,
    GenerateDocumentsSidebarComponent,
    ClickableStepperComponent,
    StepperFormComponent,
    DocumentsChangesPreviewComponent,
    GenerateDocumentsFormComponent,
    SignDocumentsComponent,
    LodgeDocumentsComponent,
    AsicResponseComponent,
    AnnualSignDocumentsComponent,
    NgxSkeletonLoaderModule,
    NgTemplateOutlet,
    PageTitleComponent,
    ButtonComponent,
  ],
  templateUrl: './generate-documents.component.html',
  styleUrl: './generate-documents.component.scss'
})
export class GenerateDocumentsComponent implements OnInit {
  private documentsService: DocumentsService = inject(DocumentsService);
  private companyProfileService = inject(CompanyProfileService);
  private trustService = inject(TrustsService);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private menuService = inject(MenuService);
  private modalFormsService = inject(ModalFormsService);
  private securityService = inject(SecurityService);
  private authService = inject(AuthService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  #destroyRef = inject(DestroyRef);

  steps = ChangeGenerateDocumentsSteps;
  currentStepIndex = 0;
  loading = false;
  updatePreviewTrigger = false;
  readonly notBeLodgedFormTypeList = ['c:ra01', 'c:ra04', 'c:106', 'c:211', 'c:280', 'c:281', 'c:2205', 'Dividend Statement'];

  document!: Document;
  actualCompany!: Company;
  actualTrustProfile: Trust | null = null;
  trustProfileBeforeChanges: Trust | null = null;
  lodgement: LodgementDeadline | undefined;
  documentId = '';
  DocumentStatusEnum = DocumentStatusEnum;
  DocumentStepEnum = DocumentStepEnum;
  relationshipHashset: Map<string, string> = new Map<string, string>();
  saveAction$ = new Subject<boolean>();
  confirmAction$ = new Subject<boolean>();
  confirmSentESignAction$ = new Subject<boolean>();
  confirmPaperSignAction$ = new Subject<boolean>();
  confirmSendEmailAction$ = new Subject<boolean>();
  confirmLodgeDocumentNowAction$ = new Subject<boolean>();
  confirmNotLodgedFormAction$= new Subject<boolean>();
  disabledHeaderBtn$: Observable<boolean>;
  declarationState = false;
  collapsedStateBtn = false;

  constructor() {
    this.disabledHeaderBtn$ = toObservable(this.documentsService.disabledHeaderBtn as Signal<boolean>);
    this.toastr.toastrConfig.positionClass = 'toast-top-right';
  }

  async ngOnInit() {
    this.menuService.setMenuState(true);
    this.documentId = this.route.snapshot.paramMap.get('id') ?? '';

    this.route.queryParams.pipe(
      tap((params: { step?: string }) => {
        if(params?.step !== undefined && params?.step !== null) {
          this.currentStepIndex = +params.step as DocumentStatusEnum;
        }
      }),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe();

    if (this.documentId !== null && this.documentId !== undefined) {
      await this.loadDocument(this.documentId);
    }

    this.getRelationshipHashSet();
    this.openTheOnlyDraftDocumentChange();
    this.disabledHeaderBtn$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(disabledHeaderBtn => {
        const nextStepIndex = this.currentStepIndex + 1;
        if((nextStepIndex + 1) <= this.steps.length) {
          this.steps[this.currentStepIndex + 1].disabled = disabledHeaderBtn;
        }
    });
  }

  async loadDocument(id: string) {
    try {
      this.loading = true;
      const document = await firstValueFrom(this.documentsService.getById(id).pipe(
        catchError((error: HttpErrorResponse) => {
          if((error.status as HttpErrorCode) === HttpErrorCode.NOT_FOUND) {
            this.toastr.error(`Document ${id} not found`, 'Error!');
          } else {
            this.toastr.error(`${error.message}`, 'Error!')
            console.error(error);
          }

          this.loading = false;

          return of(null);
        })
      ));

      this.lodgement = await this.lodgementDedline(id);

      if(document) {
        this.document = document;
        this.changeStepNameForNotBeLodgedForm(document.type);
      } else {
        this.documentsService.disabledHeaderBtn.set(true);
        return;
      }

      if(this.document.documentStatus === DocumentStatusEnum.LodgementPending && this.document.asicStatus !== AsicStatus.NotLodged) {
        this.currentStepIndex = this.document.documentStatus;
      } else {
        this.currentStepIndex = documentStatusToStep(this.document.documentStatus);
      }

      const canSeeAllSteps = this.hasFullAccess;
      if(!canSeeAllSteps) {
        this.currentStepIndex = 0;
      }

      this.steps.forEach((step, index) => {
        if(index <= this.currentStepIndex) {
          step.disabled = false;
        }
      });
      this.addStepToQueryParam(this.currentStepIndex);

      if(
        document.documentSigning?.signingStatus === SigningStatus.Sent
        || document.documentSigning?.signingStatus === SigningStatus.Signed
        || document.documentSigning?.signingStatus === SigningStatus.Rejected
      ) {
        this.steps[DocumentStepEnum.InitiateChange].disabled = true;
        this.steps[DocumentStepEnum.GenerateDocuments].disabled = true;
      }

      await this.loadEntity(this.document);

      this.updatePreviewTrigger = !this.updatePreviewTrigger;
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  changeStepNameForNotBeLodgedForm(documentType: string): void {
    if(this.notBeLodgedFormTypeList.includes(documentType)) {
      this.steps[DocumentStepEnum.AsicResponse].label = 'Lodgement with ASIC';
      this.steps = [...this.steps];
    } else if(this.steps[DocumentStepEnum.AsicResponse].label !== 'ASIC Response') {
      this.steps[DocumentStepEnum.AsicResponse].label = 'ASIC Response';
      this.steps = [...this.steps];
    }
  }

  async loadCompany(id: string, applyIncompleteDocuments = true, excludedDocumentId = '') {
    if(!id) { return null; }
    return await firstValueFrom(this.companyProfileService.getCompanyProfile(id, applyIncompleteDocuments, excludedDocumentId));
  }

  async loadTrust(id: string | null, excludedDocumentId = ''): Promise<Trust | null> {
    if (!id) return null
    const trust= await firstValueFrom(this.trustService.getTrustProfile(id, excludedDocumentId));

    if (trust) {
      trust.relationships.forEach((relationship) => {
        const name = relationship.individualDataOverride?.fullName
          ?? (relationship?.entityDataOverride?.entityNumber
            ? `${ relationship?.entityDataOverride?.name } - ${ relationship?.entityDataOverride?.entityNumber }`
            : relationship?.entityDataOverride?.name)
          ?? '';
        const type = RelationshipTypeLabels[relationship.type];
        this.relationshipHashset.set(relationship.relationshipId, `${ name } (${ type })`);

        if (relationship.details?.$type === 'ShareholderRelationshipDetails') {
          const details = relationship.details as ShareholderRelationshipDetails;
          this.relationshipHashset.set(ChangeDictionaryHelper.BENEFICIAL_OWNER + relationship.relationshipId, details.beneficialOwner);
        }
      });
    }

    return trust;
  }

  getRelationshipHashSet() {
    if (this.actualCompany?.officers && this.actualCompany?.securityholders) {
      const collection = [...this.actualCompany.officers, ...this.actualCompany.securityholders];

      const individualsRecord: Record<string, { fullname: string, types: RelationshipType[] }> = {};
      collection.forEach(officer => {
        if (officer.relationshipId === undefined) {
          return;
        }
        this.relationshipHashset.set(officer.relationshipId, `${ officer.individualDataOverride?.fullName } (${ RelationshipTypeLabels[officer.type] })`);

        if (officer.individualId) {
          if (individualsRecord[officer.individualId]) {
            individualsRecord[officer.individualId].types.push(officer.type);
          } else {
            individualsRecord[officer.individualId] = {
              fullname: officer.individualDataOverride?.fullName ?? '',
              types: [officer.type],
            };
          }
        }
      });

      Object.entries(individualsRecord)
        .forEach(([key, value]) => {
          const types = value.types.map((type) => RelationshipTypeLabels[type]);
          this.relationshipHashset.set(key, `${ value.fullname } (${ types.join(', ') })`);
        });
      this.relationshipHashset.set(ChangeDictionaryHelper.COMPANY_NAME, this.actualCompany.name);
    }

    console.log(this.relationshipHashset);
  }

  editDocumentChange(changeIndex: number): void {
    if (this.modalOpened()) return;
    const modalProps = {
      trustProfile: this.trustProfileBeforeChanges,
      hideApplyChangesButton: true,
      redirectAfterSubmit: false,
    };
    const change = this.document.changes[changeIndex];
    const modalRef = this.modalFormsService.openEntityModalWithDocument(this.document, this.actualCompany, change, modalProps);
    const instance = modalRef.componentInstance as BaseStepperFormComponent<any, any>
    instance.documentSubmitter = ((document: Document, saveAndCompleteLater = false) => {
      this.document.changes[changeIndex] = document.changes[0];
      this.document.documentSigning = null;
      this.document.documentStatus = DocumentStatusEnum.Draft;

      return this.documentsService.createOrUpdateDocument.apply(this.documentsService, [this.document, saveAndCompleteLater])
        .pipe(tap({
          next: () => this.loadDocument(this.documentId)
        }));
    }).bind(this);

    modalRef.result.then(async (result: EntityChangeData[]) => {
      this.document.changes[changeIndex] = result[0];
      this.document.changes[changeIndex].changeType = change.changeType;

      await this.loadEntity(this.document);
      this.updatePreviewTrigger = !this.updatePreviewTrigger;
    }, () => {
    });
  }

  setCurrentStep(newStepIndex: number): void {
    if (newStepIndex > this.currentStepIndex && !this.documentsService.disabledHeaderBtn()) {
      if(this.document.documentStatus && (newStepIndex as DocumentStatusEnum) <= this.document.documentStatus) {
        this.steps[newStepIndex].disabled = false;
        this.currentStepIndex = newStepIndex;
      } else {
        this.steps[newStepIndex].disabled = false;
        this.currentStepIndex = newStepIndex;
        this.confirmAction$.next(true);
      }
    }

    if (newStepIndex < this.currentStepIndex) {
      this.currentStepIndex = newStepIndex;
    }

    this.addStepToQueryParam(this.currentStepIndex);
  }

  async setNextStep(): Promise<void> {
    this.lodgement = await this.lodgementDedline(this.documentId);

    const nextStepIndex = this.currentStepIndex + 1;

    if (nextStepIndex < this.steps.length) {
      this.steps[nextStepIndex].disabled = false;
      this.currentStepIndex = nextStepIndex;
    }
  }

  editDocument(): void {
    this.currentStepIndex = DocumentStepEnum.InitiateChange;
    this.editDocumentChange(0);
  }

  async setLastStep(): Promise<void> {
    this.currentStepIndex = this.DocumentStepEnum.AsicResponse;
    this.steps[this.currentStepIndex].disabled = false;
    await this.updateDocuments();
  }

  async updateDocuments(): Promise<void> {
    await this.loadDocument(this.documentId);
  }

  saveAndCompleteLater(): void {
    this.saveAction$.next(true);
  }

  confirmChanges(): void {
    this.confirmAction$.next(true);
  }

  signDocuments(): void {
    this.confirmSentESignAction$.next(true);
  }

  paperSignDocuments(): void {
    this.confirmPaperSignAction$.next(true);
  }

  sendEmail(): void {
    this.confirmSendEmailAction$.next(true);
  }

  lodgeDocumentNow(): void {
    this.confirmLodgeDocumentNowAction$.next(true);
  }

  confirmNotLodged(): void {
    this.confirmNotLodgedFormAction$.next(true);
  }

  private openTheOnlyDraftDocumentChange() {
    if (this.document && this.document.changes.length === 1 && this.document.documentStatus === DocumentStatusEnum.Draft) {
      this.editDocumentChange(0);
    }
  }

  private async loadActualCompany(excludedDocumentId: string) {
    try {
      const company = await this.loadCompany(this.document.company?.entityId, true, excludedDocumentId);
      if (company) {
        this.actualCompany = company;
        this.getRelationshipHashSet();
      } else {
        this.actualCompany = new Company();
      }
    } catch (error) {
      console.warn(error);
    }
  }

  private async loadEntity(document: Document): Promise<void> {
    try {
      const documentTypes = ['CompanyChangePartyName', 'CompanyChangeOfficer'];
      const documentId = documentTypes.some(t => document.changes.some((change) => {

        if (change.$type == CompanyChangeOfficer.$type && (change as CompanyChangeOfficer).actionType == CompanyChangeOfficerType.Appointment) {
          return false;
        }

        return change.$type === t;
      }))
        ? '' :
        document.documentId;

      if (document.type.startsWith('c:') || document.company?.entityId) {
        await this.loadActualCompany(documentId);
        await this.loadSecurities();
      } else if (document.type.startsWith('t:') || document.entityId) {
        this.trustProfileBeforeChanges = await this.loadTrust(document.entityId, document.documentId);
        this.actualTrustProfile = await this.loadTrust(document.entityId);
      } else {
        console.error('Unrecognized document type:', document.type);
        this.toastr.error(`Unrecognized document type: ${document.type}`, 'Error');
      }
    } catch (error) {
      console.error('Unknown Entity To Load:', error);
      this.toastr.error('Unknown Entity To Load', 'Error');
    }
  }

  private async loadSecurities() {
    this.updatePreviewTrigger = !this.updatePreviewTrigger;
    if (!this.document.company?.entityId) {
      return;
    }

    try {
      const records = await firstValueFrom(this.securityService.getSecurityRegistry(this.document.company.entityId));
      records.forEach(record => {
        record.holders.forEach(holder => {
          if (holder.$type === IndividualHolderModel.$type) {
            const holderType = holder.details.$type.replace('RelationshipDetails', '');
            this.relationshipHashset.set(holder.relationshipId, `${holder.name} (${holderType})`);
          } else if (holder.$type === CorporateHolderModel.$type) {
            const corporateHolder = holder as CorporateHolderModel;
            const companyNumber = corporateHolder.entityData.entityNumber ? ` - ${ new ACNPipe().transform(corporateHolder.entityData.entityNumber) }` : '';
            this.relationshipHashset.set(holder.relationshipId, corporateHolder.entityData.name + companyNumber);
          }

          if (holder.details.beneficialOwner) {
            this.relationshipHashset.set(ChangeDictionaryHelper.BENEFICIAL_OWNER + holder.relationshipId, holder.details.beneficialOwner);
          }
        });
      });
    } catch (error) {
      console.error('Error loading securities:', error);
    }
  }

  private addStepToQueryParam(step: DocumentStepEnum): void {
    const currentUrl = this.router.url.split('?')[0];
    void this.router.navigate([currentUrl], {
      queryParams: { step },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  get entityType(): EntityType {
    if (this.document?.type.startsWith('t:'))
      return EntityType.Trust;

    return EntityType.Company;
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }

  get hasFullAccess() {
    const allowedRoles = ['Admin', 'Manager'];
    const canSeeAllSteps = this.authService.hasRole(allowedRoles);
    return canSeeAllSteps;
  }

  onDeclarationStateChange(state: boolean): void {
    this.declarationState = state;
  }

  async lodgementDedline(documentId: string): Promise<LodgementDeadline | undefined> {
    return await firstValueFrom(this.documentsService.getLodgementDeadline([documentId]).pipe(
      catchError(() => {
        this.loading = false;
        this.toastr.error(`Lodgement loading error`, 'Error!');
        return of(undefined);
      })
    ));
  }
}
