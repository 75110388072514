import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common'

@Pipe({
  standalone: true,
  name: 'dateFormat'
})

export class DateFormatPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) { }

  transform(value: Date | string | undefined | null, formatType: DateFormatType = DateFormatType.Short): string {
    if (value === undefined || value === null) return '-';

    let format: string;
    if (formatType == DateFormatType.Short) {
      format = 'dd/MM/yyyy'; // Example: 31/12/2024
    } else {
      format = 'dd MMMM yyyy'; // Example: 31 December 2024
    }

    const formattedDate = this.datePipe.transform(value, format);
    return formattedDate ?? '-';
  }
}

export enum DateFormatType {
  Short = 'short',
  Long = 'long'
}
