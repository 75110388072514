<div [formGroup]="form">
  <div class="form-group">
    <app-select-groups label="Shareholder Type" placeholder="Select Shareholder Type" formControlName="shareholderType"
      [optionGroups]="shareholderTypesOptionGroups"></app-select-groups>
  </div>

  <div class="form-group" [hidden]="form.controls.shareholderType.value !== ShareholderTypesEnum.ExistingShareholder">
    <app-select-shareholder label="Current Shareholders" 
      formControlName="securityRegistryRecordGroup"
      [groupedSecurityRegistryRecords]="groupedSecurityRegistryRecords"></app-select-shareholder>
  </div>

  <div class="form-group" [hidden]="form.controls.shareholderType.value !== ShareholderTypesEnum.Individual"
    [formGroup]="form">
    <app-individual-data-form-group [formGroup]="form.controls.newIndividualShareholder"
      [individualDataFormLabels]="individualDataFormLabels" [hiddenControls]="hiddenIndividualControls"
      [useInternationalAddresses]="true"></app-individual-data-form-group>
  </div>

  <div [hidden]="form.controls.shareholderType.value !== ShareholderTypesEnum.Corporate" [formGroup]="form">
    <div class="form-group">
      <app-company-name-acn-form-group [formGroup]="form" [hideAcn]="form.controls.isOverseasCompany.value!"
        [shouldLookForCompanyName]="!form.controls.isOverseasCompany.value">
        <div class="form-group">
          <app-checkbox label="This is an overseas company or the company doesn't have an ACN"
            formControlName="isOverseasCompany"></app-checkbox>
        </div>
      </app-company-name-acn-form-group>
    </div>

    <div class="form-group">
      <app-address-form-group [careOfAllowed]="true" [formGroup]="form.controls.registeredAddress"
        [useInternationalAddresses]="form.controls.isOverseasCompany.value!"
        [addressFormLabels]="addressFormLabels"></app-address-form-group>
    </div>
  </div>

  <div class="form-group" [hidden]="form.controls.shareholderType.value !== ShareholderTypesEnum.JointShareholder">
    <app-share-joint-security-holder formControlName="joint"></app-share-joint-security-holder>
  </div>

  <div
    [hidden]="form.controls.shareholderType.value === null || form.controls.shareholderType.value === ShareholderTypesEnum.ExistingShareholder">
    <div class="form-group">
      <app-yes-no-control label="Are the shares owned on behalf of another individual, company, trust or fund?"
        formControlName="isNotBeneficialOwner"></app-yes-no-control>
    </div>

    <div class="form-group" [hidden]="!form.controls.isNotBeneficialOwner.value">
      <app-input label="Shares are owned on behalf of:" formControlName="beneficialOwner"></app-input>
    </div>
  </div>

  <div class="share-numbers-grid">
    <div class="form-group share-types">
      <app-share-security-types-dropdown formControlName="securityTypeId" [options]="securityTypes"
        [allowAddNewShareType]="form.controls.shareholderType.value !== null || form.controls.shareholderType.value !== ShareholderTypesEnum.ExistingShareholder"
        (addShareType)="addShareType($event)"></app-share-security-types-dropdown>
    </div>

    <div class="form-group number-of-shares-cancelled">
      <app-input-number label="Number of Shares Issued" formControlName="numberIncrease" [min]="0"
        [customErrors]="totalNumberOfSharesCustomErrors"></app-input-number>
    </div>

    <div class="form-group total-paid">
      <app-input-number label="Total Paid" formControlName="paidIncrease" [min]="0"
        [customErrors]="totalNumberOfSharesCustomErrors"></app-input-number>
    </div>

    <div class="form-group total-unpaid">
      <app-input-number label="Total Unpaid" formControlName="unpaidIncrease" [min]="0"
        [customErrors]="totalNumberOfSharesCustomErrors"></app-input-number>
    </div>
  </div>

  <div class="form-group" [hidden]="formShareNumberErrorMessageHidden">
    <app-validation-error [control]="form"
      [customErrors]="customErrorsOfShareCancellationNumbers"></app-validation-error>
  </div>
</div>