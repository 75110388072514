import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { DatepickerHelper } from '../../../../../custom-form-validators/date-picker-form-validators';
import { Company } from '../../../../../models/company';
import { SecurityChange } from '../../../../../models/securityChange';
import { SecurityRegistryRecord } from '../../../../../models/securityRegistryRecord';
import { SecurityService } from '../../../../../services/security.service';
import { BaseStepperFormComponent } from '../../../stepper-form/base-stepper-component/base-stepper-form.component';
import { CertificateConsolidationOptions } from '../../share-issue/share-issue.model';

@Component({
  selector: 'app-base-share-form',
  standalone: true,
  template: '',
})
export class BaseShareFormComponent<T, Change extends SecurityChange<Company>> extends BaseStepperFormComponent<T, Change> implements OnInit {
  securityService = inject(SecurityService);
  cdr = inject(ChangeDetectorRef);

  @Input() securityRegistryRecords: SecurityRegistryRecord[] = [];

  readonly certificateConsolidationOptions = CertificateConsolidationOptions;

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  isEstimateLoading = false;
  shouldLoadEstimate = true;
  estimatedSecurityRegistryRecords: SecurityRegistryRecord[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);
    this.setFormValidators();
    this.setupFormChangeListeners();
    this.loadShareholders();
  }

  setFormValidators(): void {
  }

  setupFormChangeListeners(): void {
    this.listenStepperFormChanges();
  }

  override afterSubmit(changes: SecurityChange<Company>[]): void {
    this.setupChange(changes[0] as SecurityChange<Company>);
  }

  override setupChange(change: SecurityChange<Company> = this.formModel): void {
  }

  loadShareholders(): void {
    this.isLoading = true;
    this.securityService.getSecurityRegistry(this.companyChangeData.entityId, true, this.excludedDocumentId)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (shareholders) => {
          this.onShareholdersLoadSuccess(shareholders);
        },
        error: (error) => {
          console.warn('[BaseShareFormComponent] Load Shareholders Error', error);
        }
      });
  }

  onShareholdersLoadSuccess(shareholders: SecurityRegistryRecord[]): void {
  }

  loadEstimates(): void {
    this.isEstimateLoading = true;
    this.securityService.getSecurityEstimate(this.companyChangeData.entityId, [this.buildShareFormChange().prepareToRequest()], this.excludedDocumentId)
      .pipe(finalize(() => {
        this.isEstimateLoading = false;
        this.shouldLoadEstimate = false;
      }))
      .subscribe({
        next: (estimatedSecurityRegistryRecords) => {
          this.onEstimateLoadSuccess(estimatedSecurityRegistryRecords);
        },
        error: (error: HttpErrorResponse) => {
          console.warn('[BaseShareFormComponent.loadEstimates] Load Estimates Error', error);
          const errorMessage = error.error?.toString() ?? '';

          if (errorMessage.includes('Price override deviation is not in allowed bounds')) {
            console.warn('[BaseShareFormComponent.loadEstimates] Price override deviation is not in allowed bounds');
          } else if (errorMessage.includes("Negative changes in transaction when positive were expected")) {
            console.warn('[BaseShareFormComponent.loadEstimates] Negative changes in transaction when positive were expected');
          } else if (errorMessage.includes("Positive changes in transaction when negative were expected")) {
            console.warn('[BaseShareFormComponent.loadEstimates] Positive changes in transaction when negative were expected');
          } else {
            console.warn('[BaseShareFormComponent.loadEstimates] Unknown Message');
          }
        }
      });
  }

  onEstimateLoadSuccess(estimatedSecurityRegistryRecords: SecurityRegistryRecord[]): void {
  }

  buildShareFormChange(): SecurityChange<Company> {
    throw new Error('Method buildShareFormChange not implemented.');
  }

  private listenStepperFormChanges(): void {
    this.stepperForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.shouldLoadEstimate = true);
  }

  get excludedDocumentId(): string {
    return this.isEdit && this.companyChangeData.documentId ? this.companyChangeData.documentId : '';
  }
}
