<app-common-modal-form
  icon="icon ic-edit"
  header="Edit Document Settings"
  confirmText="Save"
  closeText="Cancel"
  [isLoading]="isLoading"
  [isInvalid]="isInvalidSaveButton"
  (confirm)="confirm()"
  (close)="close()"
>
  <div class="edit-document-settings-modal-body" [formGroup]="form">
    <div class="documents-part">
      <div class="part-title">eSigning</div>
      <div class="part-body">
        <app-select
          label="Receive email for eSigned documents. Select recipient(s) from the list"
          formControlName="receiveEmailsForDocs"
          [options]="NotifyWhenAllPartiesSignedDocumentOptions"
          [valuePrimitive]="true"
        />
      </div>
    </div>

    <div class="annual-statement-part">
      <div class="part-title">Annual Statement</div>

      <div class="part-body">
        <app-yes-no-control
          label="Do you want to generate an annual statement automatically?"
          formControlName="generateAutomatically"
        />

        <app-yes-no-control
          label="Do you want to add Director's Signature to the Company Statement?"
          formControlName="addSignatoryToCompanyStatement"
        />

        <app-radio
          [label]="'Choose the type of document to generate'"
          [options]="DocumentTypeToGenerateOptions"
          [(ngModel)]="this.form.value.documentTypeToGenerate"
          [ngModelOptions]="{standalone: true}"
        ></app-radio>

        <app-select
          label="Meeting address type"
          formControlName="meetingLocationType"
          [options]="MeetingLocationTypeOptions"
          [valuePrimitive]="true"
          (ngModelChange)="onMeetingAddressTypeSelect($event)"
        />

        <div class="address"  [formGroup]="formAddress" *ngIf="form.get('meetingLocationType')?.value === MeetingLocationType.Other">
          <app-address-control
            [label]="'Other Address'"
            [expandedAddressForm]="false"
            [halfWidth]="false"
            [isDisabled]="form.get('meetingLocationType')?.value !== MeetingLocationType.Other"
            [addressStatus]="addressStatus"
            (onDataStatusChange)="onDataStatusChange($event)"
          ></app-address-control>
        </div>

        <div *ngIf="form.get('meetingLocationType')?.value === MeetingLocationType.Online">
          <app-select
            label="Meeting online type"
            formControlName="meetingOnlineType"
            [options]="MeetingOnlineTypeOptions"
            [valuePrimitive]="true"
            />
        </div>

        <app-document-selection
          [title]="'Documents to Generate'"
          [documentSelectionList]="asSettings.documentSelectionFiles.length == 0 ? initDocumentSelectionFiles : asSettings.documentSelectionFiles"
          [documentationType]="form.value.documentTypeToGenerate!"
          [isDisabled]="false"
          (documentSelectionChange)="onDocumentSelectionChange($event)"
        ></app-document-selection>
        
      </div>
    </div>
  </div>
</app-common-modal-form>
