@if (!loading && trustProfile) {
  <div class="trust-profile-container">
    <div class="trust-profile-wrapper d-flex gap-24">
      <div class="left d-flex flex-column gap-24">
        <app-trust-profile-card
          [trustProfile]="trustProfile"
          [disableControls]="modalOpened()"
          (onTrustRefreshed)="onTrustRefreshedEvent()"
        ></app-trust-profile-card>
        <app-entity-profile-tags
          [entityProfile]="trustProfile"
          [disableControls]="modalOpened()"
        ></app-entity-profile-tags>
        @if (pendingDocuments.length) {
          <app-company-pending-changes
            [trustView]="true"
            [pendingButtonState]="pendingButtonState"
            [pendingDocuments]="pendingDocuments"
            [disableControls]="modalOpened()"
            (deletePendingDocuments)="deleteDocumentChange($event)"
          ></app-company-pending-changes>
        }
      </div>

      <div class="right d-flex flex-column flex-grow-1">
        <div id="details" class="card mb-4">

          @if (!hideTabs) {
            <div class="card-header">
              <div class="d-flex align-items-center justify-content-between gap-24">
                <div class="left">
                  <div class="tab-container">
                    @for (tab of tabs; track $index) {
                      <app-tab-button
                        [isActive]="activeTab === tab.title"
                        (tabClicked)="updateTab(tab.title)"
                        [isDisabled]="modalOpened()"
                      >{{ tab.title }}
                      </app-tab-button>
                    }
                  </div>
                  <div class="vertical-divider"></div>
                  <div class="notes-alerts-buttons">
                    <app-tab-button
                      iconClass="ic-alert-triangle"
                      [isActive]="alertsVisible"
                      [isDisabled]="!trustProfile.alerts.length || modalOpened()"
                      (tabClicked)="trustProfile.alerts.length ? alertsVisible = true : alertsVisible = false"
                    >{{ alertsLabel }}
                    </app-tab-button>

                    <app-tab-button
                      iconClass="ic-notes"
                      [isActive]="notesVisible"
                      [isDisabled]="modalOpened()"
                      (tabClicked)="notesVisible = true"
                      *appHasRole="['Admin', 'Manager', 'Preparer']"
                    >{{ notesLabel }}
                    </app-tab-button>
                  </div>
                </div>

                <div *appHasRole="['Admin', 'Manager', 'Preparer']">
                  @switch (activeTab) {
                    @case ('Details') {
                      <div ngbDropdown placement="bottom-right" class="dropdown">
                        <button
                          ngbDropdownToggle
                          id="trust-actions-dd"
                          class="btn btn-primary d-flex align-items-center gap-2"
                          [disabled]="modalOpened()"
                        >
                          <span>Actions</span>
                          <i class="icon ic-xs ic-select-icon"></i>
                        </button>

                        <div ngbDropdownMenu aria-labelledby="trust-actions-dd"
                             class="dropdown-menu dropdown-menu-right">
                          <div ngbDropdownItem class="dropdown-item" (click)="edit()">
                            <a class="dropdown-button">
                              <i class="ic-trusts ic-md icon"></i>
                              <div class="title">Update trust details</div>
                            </a>
                          </div>

                          <div ngbDropdownItem class="dropdown-item" (click)="openTrustDistributionModal()">
                            <a class="dropdown-button">
                              <i class="ic-form ic-md icon"></i>
                              <div class="title">Trust Distribution Resolution or Minutes</div>
                            </a>
                          </div>
                        </div>
                      </div>
                    }
                    @case ('Relationships') {
                      @if (trustProfile.trustType == TrustType.Discretionary) {
                        <div ngbDropdown placement="bottom-right" class="dropdown">
                          <app-button
                            ngbDropdownToggle
                            id="trust-actions-relationships-dd"
                            text="Actions"
                            [iconLast]="true"
                            [iconClass]="'icon ic-xs ic-select-icon'"
                            [isDisabled]="modalOpened()"
                          ></app-button>

                          <div ngbDropdownMenu aria-labelledby="trust-actions-relationships-dd"
                               class="dropdown-menu dropdown-menu-right">
                            <div ngbDropdownItem class="dropdown-item" (click)="changeAppointor()">
                              <a class="dropdown-button">
                                <i class="ic-form ic-md icon"></i>
                                <div class="title">Change of Appointor</div>
                              </a>
                            </div>
                          </div>
                        </div>
                      }
                    }
                    @case ('Trustee') {
                      <div ngbDropdown placement="bottom-right" class="dropdown">
                        <button
                          ngbDropdownToggle
                          id="trust-actions-trustee-dd"
                          class="btn btn-primary d-flex align-items-center gap-2"
                          [disabled]="modalOpened()"
                        >
                          <span>Actions</span>
                          <i class="icon ic-xs ic-select-icon"></i>
                        </button>

                        <div ngbDropdownMenu aria-labelledby="trust-actions-trustee-dd"
                             class="dropdown-menu dropdown-menu-right">
                          <div ngbDropdownItem class="dropdown-item" (click)="changeTrustee()">
                            <a class="dropdown-button">
                              <i class="ic-form ic-md icon"></i>
                              <div class="title">Change of Trustee</div>
                            </a>
                          </div>
                        </div>
                      </div>
                    }
                  }
                </div>

              </div>
            </div>
          }

          <div>
            @if (activeTab === 'Details') {
              <app-trust-details [trustProfile]="trustProfile"></app-trust-details>
            } @else if (activeTab === 'Trustee') {
              <div class="card-body">
                <app-list-grid
                  [rowData]="trustProfile.trustees!"
                  [colDefs]="$any(colDefs)"
                  [rowSelection]="rowSelection"
                  [suppressRowClickSelection]="true"
                  [suppressCellFocus]="true"
                  [pagination]="true"
                  [paginationPageSize]="defaultPageSize"
                  [paginationPageSizeSelector]="true"
                  (select)="selectItems($event)"
                ></app-list-grid>
              </div>
            } @else if (activeTab === 'Beneficiaries') {
              <div class="card-body">
                <app-list-grid
                  [rowData]="trustProfile.beneficiaries!"
                  [colDefs]="$any(colDefs)"
                  [rowSelection]="rowSelection"
                  [suppressRowClickSelection]="true"
                  [suppressCellFocus]="true"
                  [pagination]="true"
                  [paginationPageSize]="defaultPageSize"
                  [paginationPageSizeSelector]="true"
                  (select)="selectItems($event)"
                ></app-list-grid>
              </div>
            } @else if (activeTab === 'UnitHolders') {
              <app-securityholders
                [entityId]="trustProfile.entityId"
                (profileViewChange)="profileViewChange($event)"
              ></app-securityholders>
            } @else if (activeTab === 'Relationships') {
              <div class="d-flex justify-content-end">
                <div class="former-relationships-checkbox-wrapper">
                  <app-checkbox
                    label="Show former relationships"
                    [value]="showFormerRelationship"
                    [disabled]="loading || modalOpened()"
                    (change)="filterTrustRelationship()"
                  ></app-checkbox>
                </div>
              </div>

              <div class="card-body">
                <app-list-grid
                  [rowData]="trustRelationships"
                  [colDefs]="$any(relationshipColDefs)"
                  [rowSelection]="rowSelection"
                  [suppressRowClickSelection]="true"
                  [suppressCellFocus]="true"
                  [pagination]="true"
                  [paginationPageSize]="defaultPageSize"
                  [paginationPageSizeSelector]="true"
                  (select)="selectItems($event)"
                ></app-list-grid>
              </div>
            } @else if (activeTab === 'Documents') {
              <app-company-profile-documents
                [documents]="pendingDocuments">
              </app-company-profile-documents>
            }
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-notes
    [entityId]="trustProfile.entityId"
    [opened]="notesVisible"
    (close)="notesVisible = false"
    (notesTotalChanged)="notesTotal = notesTotal + $event"
  ></app-notes>

  <app-alerts-list
    [alerts]="trustProfile.alerts"
    [entityType]="EntityType.Trust"
    [entity]="trustProfile"
    [opened]="alertsVisible"
    (close)="alertsVisible = false"
  ></app-alerts-list>
}

<app-loader-standalone [loading]="loading"></app-loader-standalone>
