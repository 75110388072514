import {EsigningEmailRecipients } from "../../../../../models/documentSettingsData";
import { DocumentationType, MeetingLocationType, MeetingOnlineType } from "../../../../../models/enums/documentConfirmEnums";
import { SelectOption } from "../../../../../models/selectOptions";

export const NotifyWhenAllPartiesSignedDocumentLabelRecord: Record<EsigningEmailRecipients, string> = {
  [EsigningEmailRecipients.SenderOnly]: 'Sender Only',
  [EsigningEmailRecipients.RecipientsOnly]: 'Recipients Only',
  [EsigningEmailRecipients.SenderAndRecipients]: 'Sender and Recipients',
  [EsigningEmailRecipients.DoNotSend]: 'Do not Send',
};

export const NotifyWhenAllPartiesSignedDocumentOptions: SelectOption[] = [
  {
    label: NotifyWhenAllPartiesSignedDocumentLabelRecord[EsigningEmailRecipients.SenderOnly],
    value: EsigningEmailRecipients.SenderOnly
  },
  {
    label: NotifyWhenAllPartiesSignedDocumentLabelRecord[EsigningEmailRecipients.RecipientsOnly],
    value: EsigningEmailRecipients.RecipientsOnly
  },
  {
    label: NotifyWhenAllPartiesSignedDocumentLabelRecord[EsigningEmailRecipients.SenderAndRecipients],
    value: EsigningEmailRecipients.SenderAndRecipients
  },
  {
    label: NotifyWhenAllPartiesSignedDocumentLabelRecord[EsigningEmailRecipients.DoNotSend],
    value: EsigningEmailRecipients.DoNotSend
  },
];

export const DocumentTypeToGenerateOptions: SelectOption[] = [
  {
    label: DocumentationType[DocumentationType.Minutes],
    value: DocumentationType.Minutes
  },
  {
    label: DocumentationType[DocumentationType.Resolution],
    value: DocumentationType.Resolution
  }
];


export const MeetingLocationTypeOptions: SelectOption[] = [
  {
    label: MeetingLocationType[MeetingLocationType.Registered],
    value: MeetingLocationType.Registered
  },
  {
    label: MeetingLocationType[MeetingLocationType.Principal],
    value: MeetingLocationType.Principal
  },
  {
    label: MeetingLocationType[MeetingLocationType.Phone],
    value: MeetingLocationType.Phone
  },
  {
    label: MeetingLocationType[MeetingLocationType.Other],
    value: MeetingLocationType.Other
  },
  {
    label: MeetingLocationType[MeetingLocationType.Online],
    value: MeetingLocationType.Online
  }
];

export const MeetingOnlineTypeOptions: SelectOption[] = [
  {
    label: MeetingOnlineType[MeetingOnlineType.GoogleMeet],
    value: MeetingOnlineType.GoogleMeet
  },
  {
    label: MeetingOnlineType[MeetingOnlineType.Zoom],
    value: MeetingOnlineType.Zoom
  },
  {
    label: MeetingOnlineType[MeetingOnlineType.MicrosoftTeams],
    value: MeetingOnlineType.MicrosoftTeams
  }
];

export const DocumentTypeOptions: SelectOption[] = [
  { label: 'Minutes', value: DocumentationType.Minutes, disabled: false },
  { label: 'Resolution', value: DocumentationType.Resolution, disabled: false },
];



