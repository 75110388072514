import { SecurityRegistryRecord } from '../../models/securityRegistryRecord';

export function groupSecurityRecordsByName(records: SecurityRegistryRecord[]): SecurityRegistryRecord[][] {
  return records.reduce((acc, record) => {
          const groupIndex = acc.findIndex((group) => group[0].fullName === record.fullName);
  
          if (groupIndex > -1 && (record.isBeneficialOwner || record.beneficialOwner)) {
            acc[groupIndex].push(record);
          } else {
            acc.push([record]);
          }
  
          return acc;
        }, [] as SecurityRegistryRecord[][])
          .sort((a, b) => a[0].fullName.localeCompare(b[0].fullName));
}