import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PascalCaseToText } from '../../../../../../functions/enums-to-list-formatter';
import { Address } from '../../../../../../models/address';
import { Company } from '../../../../../../models/company';
import { Document } from '../../../../../../models/document';
import { EntityChangeData } from '../../../../../../models/entityChangeData';
import { CompanyChangeData } from '../../../../../../models/enums/companyChangeData';
import { RelationshipType } from '../../../../../../models/enums/relationshipTypeEnum';
import { SelectOption } from '../../../../../../models/selectOptions';
import { ChangeDictionaryHelper } from '../../../../../../models/shared/change-dictionary-helper.model';
import { Guid } from '../../../../../helpers/guid.helper';
import { A1CompanyAddressChangeComponent } from './a1-company-address-change.component';
import { IPDFSection } from "../../../../../../models/shared/pdf-section.interface";
import { Entity } from "../../../../../../models/entity";

export class CompanyChangeAddress extends EntityChangeData {
  static override readonly $type = "CompanyChangeAddress";
  address: Address;
  applyToRegistered: boolean;
  applyToPrincipal: boolean;
  relationshipIds: string[];
  occupiesTheAddress: boolean;
  occupierNameIfDoesntOccupy: string;
  authorisedPerson: string; // when ind controller will be implemented, this will be changed to ind
  isIndividual?: boolean;

  constructor(data: Partial<CompanyChangeAddress> = {}) {
    super(data);

    this.address = new Address(data.address ?? {});
    this.applyToRegistered = data.applyToRegistered ?? false;
    this.applyToPrincipal = data.applyToPrincipal ?? false;
    this.occupiesTheAddress = data.occupiesTheAddress ?? true;
    this.relationshipIds = data.relationshipIds?.filter(id => Boolean(id) && id !== Guid.EmptyGuid) ?? [];
    this.occupierNameIfDoesntOccupy = data.occupierNameIfDoesntOccupy ?? '';
    this.authorisedPerson = data.authorisedPerson ?? '';
  }

  override toDictionary(entity = new Company() as Entity): { key: string, value: string }[] {
    const company = entity as Company;
    const dict = new ChangeDictionaryHelper();
    const relationships = [...company.officers, ...company.securityholders];
    const usedInFormRelationships = relationships?.filter((r) => this.relationshipIds.includes(r.relationshipId)) ?? [];

    dict
      .add('New Address', this.address.normalizedFullAddress)
      .addYesNo('Registered Office Address', this.applyToRegistered)
      .addYesNo('Principal Place of Business', this.applyToPrincipal);

    if (!this.isIndividual && this.address.normalizedFullAddress && (this.applyToRegistered || this.applyToPrincipal)) {
      dict.addYesNo('Company occupies the premises', this.occupiesTheAddress);
    }

    if (!this.occupiesTheAddress) {
      dict
        .add('Name of Occupier', this.occupierNameIfDoesntOccupy)
        .addYesNo('Does the company have occupiers consent?', !!this.occupierNameIfDoesntOccupy);
    }

    if (this.relationshipIds.length) {
      dict.addRelationshipsIds('Address Apply to:', usedInFormRelationships);
    }

    dict.addDate('Date of Change', this.changeDate);

    return dict.dictionary;
  }

  override prepareToPdf(): IPDFSection[] {
    const sectionDict = new ChangeDictionaryHelper();

    sectionDict
      .addSection('New Address')
      .add('Country', this.address.country)
      .add('Address', this.capitalizeAddress(this.address.normalizedFullAddress))
      .addDate('Effective Date', this.changeDate);

    if (this.address?.normalizedFullAddress) {
      sectionDict
        .addSection('Registered address')
        .addYesNo('Apply change of address?', this.applyToPrincipal);

      if (this.applyToRegistered) {
        if (this.occupiesTheAddress) {
          sectionDict
            .addYesNo('Does the company occupy the premises at the new registered address?', this.occupiesTheAddress);
        } else {
          sectionDict
            .addYesNo('Does the company occupy the premises at the new registered address?', this.occupiesTheAddress)
            .add('Name of Occupier', this.occupierNameIfDoesntOccupy)
            .addYesNo('Does the company have the occupier’s consent?', !!this.occupierNameIfDoesntOccupy);
        }
      }

      sectionDict
        .addSection('Principal Place of Business')
        .addYesNo('Apply change of address?', this.applyToPrincipal);
    }

    return sectionDict.sections;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as A1CompanyAddressChangeComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  capitalizeAddress(address: string): string {
    const australianStates = ['NSW', 'ACT', 'VIC', 'QLD', 'SA', 'WA', 'TAS', 'NT'];

    return address.replace(/\b\w+\b/g, (match) => {
      if (australianStates.includes(match.toUpperCase())) {
        return match.toUpperCase();
      }
      return match.charAt(0).toUpperCase() + match.slice(1).toLowerCase();
    });
  }

  fillModalWithCompany(modalRef: NgbModalRef, company: Company = new Company(), isEdit = false): NgbModalRef {
    const instance = modalRef.componentInstance as A1CompanyAddressChangeComponent;

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = this;

    // TODO: remove after individualId works fine
    const officerIndividualsIdDictionary: Record<string, string> = {};

    const nonCorporateSecurityHolders = company.securityholders.filter((securityholder) => securityholder?.individualDataOverride);

    instance.officersSelectOptions = structuredClone([...company.officers.filter(o => !o.end), ...nonCorporateSecurityHolders])
      .reduce((officersSelectOptions: SelectOption[], officer) => {
        // TODO: remove after individualId works fine
        if (!(officer.individualDataOverride!.fullName in officerIndividualsIdDictionary)) {
          officerIndividualsIdDictionary[officer.individualDataOverride!.fullName] = Math.random().toString(36).substring(7);
        }

        // TODO: remove after individualId works fine
        officer.key = officer.individualId ?? officerIndividualsIdDictionary[officer.individualDataOverride!.fullName];

        const officerOption: SelectOption = {
          label: officer.individualDataOverride!.fullName,
          value: officer.key,
        };

        if (!(officer.key in instance.officers)) {
          instance.officers[officer.key] = [];

          officersSelectOptions?.push(officerOption);
        }

        instance.officers[officer.key]?.push(officer);

        return officersSelectOptions;
      },
        []
      )
      .sort((a, b) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase()));

    instance.officersSelectOptions = instance.officersSelectOptions.map((officerOption) => {
      const officerTypes = (instance.officers[officerOption.value as string])
        .reduce((types: string[], officer) => {
          const type = officer.type === RelationshipType.Securityholder
            ? 'Shareholder'
            : PascalCaseToText(RelationshipType[officer.type]);

          if (!types.includes(type)) {
            types.push(type);
          }

          return types;
        }, []);

      return {
        label: `${officerOption.label} (${officerTypes.join(', ')})`,
        value: officerOption.value,
      };
    });

    console.log('officersSelectOptions', instance.officersSelectOptions);

    return modalRef;
  }

  override prepareToRequest(): CompanyChangeAddress {
    return {
      ...this,
      ...super.prepareToRequest(),
    };
  }
}

export const poBosString = 'po box';

export function addressIncludesPoBox(address: string) {
  return address
    .toLowerCase()
    .replaceAll('  ', ' ')
    .includes(poBosString);
}

export const poBoxAddressCustomErrors = {
  poBoxAddress: 'Invalid Address: A PO Box or GPO Box cannot be used as a registered address, principal place of business or officeholder address. Please provide a street address.',
};

export const customForm484A1Errors = {
  required: 'You have to pick one of the options above.',
  foreignAddressForCompany: `Changing the company's address to a foreign address is not allowed.`,
  foreignAddressForAllDirectors: `It's now allowed to change address of all directors to a foreign address.`,
  careOfResidential: 'It is not allowed to use Care Of for residential addresses.',
  ...poBoxAddressCustomErrors
};
