import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { DatePipe } from "@angular/common";
import { phoneFormatter } from '../../../../../../functions/phone-formatter';

@Component({
  selector: 'app-ag-phone-number',
  standalone: true,
  imports: [
    DatePipe,
  ],
  templateUrl: './ag-phone-number.component.html',
  styleUrl: './ag-phone-number.component.scss'
})
export class AgPhoneNumberComponent {
  phone: string = '';

  agInit(params: ICellRendererParams): void {
    this.phone = phoneFormatter(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.phone = phoneFormatter(params);
    return true;
  }
}
