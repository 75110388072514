import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { CorporateHolderModel, IndividualHolderModel, SecurityRegistryRecord } from '../../../../../models/securityRegistryRecord';
import { BeneficialOwnerComponent } from '../../../../components/common/beneficial-owner/beneficial-owner.component';
import { InputNumberComponent } from '../../../../components/common/input-number/input-number.component';
import { ValidationErrorComponent } from '../../../../components/common/validation-error/validation-error.component';
import {
  SelectShareholderComponent
} from '../../../../components/shares/select-shareholder/select-shareholder.component';
import { customSignErrors } from '../../share-cancellation/company-security-cancellation.model';
import { groupSecurityRecordsByName } from '../../../../helpers/security.helper';
import { CompanySecurityTransfer } from '../../share-transfer/share-transfer.model';

export interface ShareholderWithSignControls {
  securityRegistryRecordGroup: FormControl<SecurityRegistryRecord[] | null>;
}

@Component({
  selector: 'app-shareholder-with-nbo-sign',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SelectShareholderComponent,
    InputNumberComponent,
    BeneficialOwnerComponent,
    ValidationErrorComponent
  ],
  templateUrl: './shareholder-with-nbo-sign.component.html',
  styleUrl: './shareholder-with-nbo-sign.component.scss',
})
export class ShareholderWithNboSignComponent implements OnInit, OnChanges {
  @Input() formGroup: FormGroup<ShareholderWithSignControls> = ShareholderWithNboSignComponent.defineForm();
  @Input() securityRegistryRecords: SecurityRegistryRecord[] = [];

  groupedSecurityRegistryRecords: SecurityRegistryRecord[][] = [];
  selectedSecurityGroupHoldersGroupedByName;

  ngOnInit(): void {
    this.setSignValidator();

    this.formGroup.controls.securityRegistryRecordGroup.valueChanges.subscribe((value: SecurityRegistryRecord[] | null) => {
      const record = (value?.flatMap(r => r.holders) ?? [])
        .reduce((acc, holder) => {
          const key = holder.name;

          if (acc[key]) {
            acc[key].push(holder);
          } else {
            acc[key] = [holder];
          }

          return acc;
        }, {} as Record<string, (IndividualHolderModel | CorporateHolderModel)[]>)

      this.selectedSecurityGroupHoldersGroupedByName = Object.entries(record).map(([key, value]) => ({
        key,
        value,
        relationshipIds: value.map(v => v.relationshipId)
      }));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['securityRegistryRecords']) {
      this.groupedSecurityRegistryRecords = groupSecurityRecordsByName(this.securityRegistryRecords);
    }
  }

  signDataSaved(): void {
    this.formGroup.controls.securityRegistryRecordGroup.updateValueAndValidity();
  }

  private setSignValidator(): void {
    this.formGroup.controls.securityRegistryRecordGroup.setValidators([((control: FormControl<SecurityRegistryRecord[] | null>) => {
      const group = control.value;
      if (group === null) {
        return null;
      }

      const everyNBOHoldersHaveSigns = group.flatMap(r => r.holders)
        .filter(holder => !holder.details.isBeneficialOwner)
        .every(holder => holder.details.beneficialOwner);

      return everyNBOHoldersHaveSigns ? null : { nonBeneficialOwnersNotSigned: true };
    }) as ValidatorFn]);
  }

  get beneficialOwnersSignsErrorMessageHidden(): boolean {
    return this.formGroup.touched && this.formGroup.controls.securityRegistryRecordGroup.errors?.['nonBeneficialOwnersNotSigned'];
  }

  static defineForm(): FormGroup<ShareholderWithSignControls> {
    return new FormGroup({
      securityRegistryRecordGroup: new FormControl<SecurityRegistryRecord[] | null>(null, [Validators.required]),
    });
  }

  protected readonly customSignErrors = customSignErrors;

  setupChange(change: CompanySecurityTransfer) {
    const selectedSecurityRegistryGroup =
      this.groupedSecurityRegistryRecords.find((group) => group.some((record) => record?.holdings.some((holder) => holder.securityHoldingId === change.transferFrom.securityHoldingId)));

    if (selectedSecurityRegistryGroup) {

      this.formGroup.controls.securityRegistryRecordGroup.setValue(selectedSecurityRegistryGroup ?? null);
    }
  }
}
