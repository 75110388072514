import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { setControlDisabled } from '../../../../functions/set-control-disabled';
import { Address } from '../../../../models/address';
import { Document } from '../../../../models/document';
import { EntityChangeData } from '../../../../models/entityChangeData';
import { EntityData } from '../../../../models/entityData';
import { SecurityTransactionType } from '../../../../models/enums/SecurityTransactionType';
import { IndividualData } from '../../../../models/individualData';
import { ShareholderRelationshipDetails } from '../../../../models/relationship';
import { SecurityHolding } from '../../../../models/securityHolding';
import {
  CorporateHolderModel,
  IndividualHolderModel,
  SecurityRegistryRecord
} from '../../../../models/securityRegistryRecord';
import { SecurityTransactionIncremental } from '../../../../models/securityTransactionIncremental';
import { SecurityType } from '../../../../models/securityType';
import { NumbersValidators } from '../../../../validators/numbers.validators';
import { DatePickerComponent } from '../../../components/common/date-picker/date-picker.component';
import { DisclaimerComponent } from '../../../components/common/disclaimer/disclaimer.component';
import { InputNumberComponent } from '../../../components/common/input-number/input-number.component';
import { RadioComponent } from '../../../components/common/radio/radio.component';
import { SelectComponent } from '../../../components/common/select/select.component';
import { TextareaComponent } from '../../../components/common/textarea/textarea.component';
import { YesNoControlComponent } from '../../../components/common/yes-no-control-component/yes-no-control.component';
import { Guid } from '../../../helpers/guid.helper';
import { StepperFormComponent } from '../../stepper-form/stepper-form.component';
import { BaseShareFormComponent } from '../components/base-share-form/base-share-form.component';
import { ShareEstimateTableComponent } from '../components/share-estimate-table/share-estimate-table.component';
import {
  CompanySecurityIssue,
  ConsiderationTypesOptions,
  ShareholderTypesEnum,
  ShareIssueStepsEnum
} from './share-issue.model';
import {
  IndividualDataFormGroupComponent, IndividualDataFormGroupControls
} from "../../../components/reusable-form-groups/individual-data-form-group/individual-data-form-group.component";
import {
  StepperFormDescriptionComponent
} from "../../stepper-form/stepper-form-description/stepper-form-description.component";
import { AuthorisedSignatoriesStepComponent } from '../../documents/asic-forms/components/authorised-signatories-step/authorised-signatories-step.component';
import { IStep } from '../../../../models/step';
import { InputCertificateNumberComponent } from '../../../components/common/input-certificate-number/input-certificate-number.component';
import { ClientSecurityTransaction } from '../../../../models/clientSecurityTransaction';
import { ShareIssueBlock } from "../components/share-issue-block/share-issue-block.component";

export const ShareIssueSteps: IStep<ShareIssueStepsEnum>[] = [
  { step: ShareIssueStepsEnum.FormDescription, label: 'Form Description' },
  { step: ShareIssueStepsEnum.Shareholders, label: 'Shareholders' },
  { step: ShareIssueStepsEnum.ShareIssuesAndCertificateDetails, label: 'Share Issues And Certificate Details' },
  { step: ShareIssueStepsEnum.AuthorisedSignatories, label: 'Authorised Signatories' },
  { step: ShareIssueStepsEnum.Estimate, label: 'Estimate' }
];

@Component({
  selector: 'app-share-issue',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    InputNumberComponent,
    RadioComponent,
    YesNoControlComponent,
    TextareaComponent,
    DisclaimerComponent,
    StepperFormComponent,
    ShareEstimateTableComponent,
    DatePickerComponent,
    AuthorisedSignatoriesStepComponent,
    StepperFormDescriptionComponent,
    InputCertificateNumberComponent,
    ShareIssueBlock
  ],
  templateUrl: './share-issue.component.html',
  styleUrls: [
    '../../stepper-form/base-stepper-component/base-stepper-form.component.scss',
    '../share-cancellation/share-cancellation.component.scss'
  ]
})
export class ShareIssueComponent extends BaseShareFormComponent<ShareIssueStepsEnum, CompanySecurityIssue> implements OnInit {
  @Input() companySecurityStructures: SecurityHolding[] = [];
  @ViewChild(ShareIssueBlock) shareIssueBlock!: ShareIssueBlock;

  authorisedSecurityRegistryRecords: SecurityRegistryRecord[] = [];
  readonly ShareholderTypesEnum = ShareholderTypesEnum;

  readonly considerationTypesOptions = ConsiderationTypesOptions;
  override readonly StepsEnum = ShareIssueStepsEnum;
  override steps = ShareIssueSteps;
  override currentStepIndex = 0;
  override currentStep = this.steps[0].step;

  transactionsList: ClientSecurityTransaction[] = [];

  securityTypes: SecurityType[] = [];

  override stepperForm = new FormGroup({
    [ShareIssueStepsEnum.FormDescription]: new FormGroup({}),
    [ShareIssueStepsEnum.Shareholders]: new FormGroup({
      date: new FormControl<Date | null>(null, [Validators.required]),
      ...ShareIssueBlock.defineFormControls(),
    }),
    [ShareIssueStepsEnum.ShareIssuesAndCertificateDetails]: new FormGroup({
      issueForCashConsidarationOnly: new FormControl<boolean | null>(true, [Validators.required]),
      nonCashConsiderationDetails: new FormControl<string | null>(null, [Validators.required]),
      sharesWereWrittenUnderContract: new FormControl<boolean | null>(false, [Validators.required]),
      shareCertificateNumber: new FormControl<string | null>(null, [NumbersValidators.isInteger]),
      consolidateCertificate: new FormControl<boolean | null>(false, [Validators.required]),
    }),
    [ShareIssueStepsEnum.AuthorisedSignatories]: new FormGroup({
      authorisedSignatories: AuthorisedSignatoriesStepComponent.defineForm(),
    }),
    [ShareIssueStepsEnum.Estimate]: new FormGroup({}),
  });

  constructor() {
    super();
    this.updateFormSteps();
    this.redirectAfterSubmit = false;
    this.shareholdersForm.controls.securityRegistryRecordGroup.valueChanges
      .subscribe(() => this.updateFormSteps());
    this.shareholdersForm.controls.securityTypeId.valueChanges
      .subscribe(() => this.updateFormSteps());
  }

  private updateFormSteps(): void {
    const steps = ShareIssueSteps.filter((step) => {
      if (step.step === ShareIssueStepsEnum.AuthorisedSignatories) {
        return this.shouldEnableAuthorisedSignatories();
      }

      return true;
    });

    this.steps = steps;
  }

  override setupFormChangeListeners(): void {
    super.setupFormChangeListeners();

    this.listenIssueForCashConsidarationOnlyChanges();
    this.listenShareholderDetailsFormChange();
  }

  private listenIssueForCashConsidarationOnlyChanges(): void {
    this.issuesCertificateForm.controls.issueForCashConsidarationOnly.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((issueForCashConsidarationOnly) => {
        setControlDisabled(this.issuesCertificateForm.controls.nonCashConsiderationDetails, issueForCashConsidarationOnly !== false);
      });
  }

  private listenShareholderDetailsFormChange(): void {
    this.shareholdersForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.shouldLoadEstimate = true);
  }

  override setCurrentStep(newStepIndex: number): void {
    this.currentStep = this.steps[newStepIndex].step;
    this.currentStepIndex = newStepIndex;

    if (this.currentStep === ShareIssueStepsEnum.AuthorisedSignatories) {
      const holderType = this.shareholdersForm.controls.shareholderType.value;

      if (holderType === ShareholderTypesEnum.Corporate) {
        const entityData = new EntityData({
          name: this.shareholdersForm.value.name ?? '',
          entityNumber: this.shareholdersForm.value.acn ?? '',
          registeredAddress: new Address(this.shareholdersForm.value.registeredAddress as Partial<Address>)
        });
        const newCorporateSecurityHolder = new CorporateHolderModel({ entityData });
        newCorporateSecurityHolder.isPosted = false;
        
        this.authorisedSecurityRegistryRecords = [new SecurityRegistryRecord({
          holders: [newCorporateSecurityHolder],
          holdings: [new SecurityHolding({
            securityType: this.securityTypes.find(type => type.securityTypeId === this.shareholdersForm.value.securityTypeId)!,
            number: this.shareholdersForm.value.numberIncrease!,
            paid: this.shareholdersForm.value.paidIncrease!,
            unpaid: this.shareholdersForm.value.unpaidIncrease!
          })]
        })];
      } else if (this.shareholdersForm.controls.securityRegistryRecordGroup.value) {
        const record = this.shareholdersForm.controls.securityRegistryRecordGroup.value[0];
        this.authorisedSecurityRegistryRecords = [record];
      } else if (this.shareholdersForm.controls.joint.value) {
        const holders = this.shareholdersForm.controls.joint.value
          .filter(holder => holder.$type === CorporateHolderModel.$type)
          .map(holder => holder as CorporateHolderModel);

        this.authorisedSecurityRegistryRecords = holders.map(holder => {
          const record = new SecurityRegistryRecord({
            holders: [holder],
            holdings: [
              new SecurityHolding({
                securityType: this.securityTypes.find(type => type.securityTypeId === this.shareholdersForm.value.securityTypeId)!,
                number: this.shareholdersForm.value.numberIncrease!,
                paid: this.shareholdersForm.value.paidIncrease!,
                unpaid: this.shareholdersForm.value.unpaidIncrease!
              })
            ]
          });
          record.holders = [holder]
          return record;
        });
      }
    }

    if (this.currentStep === ShareIssueStepsEnum.Estimate && this.shouldLoadEstimate) {
      this.loadEstimates();
    }

    this.updateFormSteps();
  }

  private shouldEnableAuthorisedSignatories(): boolean {
    const holderType = this.shareholdersForm.controls.shareholderType.value;
    const holderGroup = this.shareholdersForm.controls.securityRegistryRecordGroup.value!;

    if (holderType === ShareholderTypesEnum.Corporate) {
      return true;
    } else if (holderType === ShareholderTypesEnum.ExistingShareholder) {
      const record = holderGroup[0];
      const holder = record.holders[0];
      const isCorporateHolder = holder.$type === CorporateHolderModel.$type;
      return isCorporateHolder;
    } else if (holderType === ShareholderTypesEnum.JointShareholder) {
      const joints = this.shareholdersForm.controls.joint.value;
      return joints!.some(holder => holder.$type === CorporateHolderModel.$type);
    }
    
    return false;
  }

  override afterSubmit(changes: EntityChangeData[]) {
    const change = changes[0] as CompanySecurityIssue;
    change.issue.securityType.securityTypeId = this.shareholdersForm.value.securityTypeId ?? '';
    this.setupChange(change);
  }

  override setupChange(change: CompanySecurityIssue = this.formModel) {
    this.disableUnnecessaryControls();
    this.issuesCertificateForm.controls.issueForCashConsidarationOnly.setValue(this.issuesCertificateForm.value.issueForCashConsidarationOnly ?? null);

    if (!this.isEdit && change === this.formModel) {
      return;
    }

    this.shareIssueBlock.setupChange(change);

    this.shareholdersForm.patchValue({
      date: new Date(change.issue.transactionDate)
    });

    this.issuesCertificateForm.patchValue(change as any);
    this.issuesCertificateForm.patchValue(change.issue);

    this.cdr.detectChanges();
    this.issuesCertificateForm.controls.issueForCashConsidarationOnly.setValue(this.issuesCertificateForm.value.issueForCashConsidarationOnly ?? null);
    this.issuesCertificateForm.controls.sharesWereWrittenUnderContract.setValue(change.sharesWereWrittenUnderContract ?? null);
  }

  override onShareholdersLoadSuccess(shareholders: SecurityRegistryRecord[]): void {
    this.securityRegistryRecords = shareholders.filter(holder => holder?.holders.length > 0);
    this.isLoading = false;

    const allSecurityTypes = shareholders.flatMap(shareholder => shareholder.holdings.map(holding => holding.securityType));
    this.securityTypes = [...new Map<string, SecurityType>(allSecurityTypes.map(type => [type.securityTypeId, type])).values()]
      .filter(type => type.entityId && type.entityId !== Guid.EmptyGuid);
    this.cdr.detectChanges();
    this.setupChange();
  }

  override onEstimateLoadSuccess(estimatedSecurityRegistryRecords: SecurityRegistryRecord[]): void {
    this.estimatedSecurityRegistryRecords = estimatedSecurityRegistryRecords.filter(record => record?.holders.length > 0);
    this.shouldLoadEstimate = false;
  }

  override buildDocument(): Document | null {
    const companySecurityIssue = this.buildShareFormChange();

    // Document created that way because of FE models difficulties
    return {
      changes: [companySecurityIssue],
      entityId: this.companyChangeData?.entityId,
      type: 'c:484',
      documentId: this.companyChangeData?.documentId ?? Guid.EmptyGuid,
    } as any as Document;
  }

  override buildShareFormChange(): CompanySecurityIssue {
    const companySecurityIssue = this.shareIssueBlock.buildShareFormChange();

    companySecurityIssue.changeDate = this.shareholdersForm.value.date!;
    companySecurityIssue.issueForCashConsidarationOnly = this.issuesCertificateForm.value.issueForCashConsidarationOnly!;
    companySecurityIssue.nonCashConsiderationDetails = this.issuesCertificateForm.value.nonCashConsiderationDetails!;
    companySecurityIssue.consolidateCertificate = this.issuesCertificateForm.value.consolidateCertificate!;
    companySecurityIssue.sharesWereWrittenUnderContract = this.issuesCertificateForm.controls.sharesWereWrittenUnderContract.value!;

    companySecurityIssue.issue.transactionDate = this.shareholdersForm.value.date!;
    companySecurityIssue.issue.shareCertificateNumber = this.issuesCertificateForm.value.shareCertificateNumber ?? null;
   
    return companySecurityIssue;
  }

  private disableUnnecessaryControls(): void {
    this.shareIssueBlock.disableUnnecessaryControls();
  }

  get shareholdersForm() {
    return this.stepperForm.controls[ShareIssueStepsEnum.Shareholders];
  }

  get issuesCertificateForm() {
    return this.stepperForm.controls[ShareIssueStepsEnum.ShareIssuesAndCertificateDetails];
  }

  get authorisedSignatoriesForm() {
    return this.stepperForm.controls[ShareIssueStepsEnum.AuthorisedSignatories];
  }

}
