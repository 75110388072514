import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges
} from '@angular/core';
import { InputComponent } from "../common/input/input.component";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { TextareaComponent } from "../common/textarea/textarea.component";
import { catchError, debounceTime, map, Observable, of, switchMap, tap } from "rxjs";
import { IEmailPreviewResponse } from "../../../models/email-templates/IEmailPreviewResponse";
import { EmailTemplatesHelper, IEmailAndTextTemplatePayloadData } from "../../../models/email-templates/emailTemplatesHelper";
import { EmailTemplate } from "../../../models/email-templates/emailTemplate";
import { SelectOption } from "../../../models/selectOptions";
import { CustomFormValidators } from "../../../custom-form-validators/custom-form-validators";
import { OrganisationService } from "../../../services/organisation.service";
import { EmailTemplatesService } from "../../../services/email-templates.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { SendOption } from "../../../models/enums/annualStatementEnums";
import { ESignBody } from "../../../models/signDocuments";
import { ShadowDomViewerComponent } from "../common/shadow-dom-viewer/shadow-dom-viewer.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { LodgementDeadline } from "../../../models/documents";
import { ButtonComponent } from "../common/button/button.component";
import { EmailPreviewContentRequest } from '../../../models/email-templates/emailPreviewRequest';
import { DocumentBase } from '../../../models/documentEnteties/document-base';
import { EmailTemaplatePrefillService } from '../../../services/email.tempalte.prefill.service';
import { EmailPayloadBase } from '../../../models/email-templates/emailPayloadBase';

@Component({
  selector: 'app-email-preview',
  standalone: true,
  imports: [
    InputComponent,
    ReactiveFormsModule,
    TextareaComponent,
    FormsModule,
    ShadowDomViewerComponent,
    NgxSkeletonLoaderModule,
    ButtonComponent
],
  templateUrl: './email-preview.component.html',
  styleUrl: './email-preview.component.scss'
})
export class EmailPreviewComponent implements OnInit, OnChanges {
  @Input() documentBase!: DocumentBase;
  @Input() lodgement: LodgementDeadline | undefined;
  @Input() sendByMethod: SendOption | undefined;
  @Input() isDocument = false;
  @Input() previewMode = false;
  @Output() changeEmail = new EventEmitter<Partial<ESignBody>>();

  private organisationService = inject(OrganisationService);
  private emailTemplatesService = inject(EmailTemplatesService);
  private sanitizer = inject(DomSanitizer);
  private toastr = inject(ToastrService);
  private destroyRef = inject(DestroyRef);
  #emailTemaplatePrefillService = inject(EmailTemaplatePrefillService);

  template!: EmailTemplate;
  emailTemplateOptions: SelectOption[] = [];
  emailTemplate = '';
  emailTemplates: EmailTemplate[] = [];
  payloadMockedModel: Partial<IEmailAndTextTemplatePayloadData> | undefined;
  SendOption = SendOption;

  loading = false;
  showFooter = false;
  maxBodyRows = 8;
  maxFooterRows = 4;
  readonly PREVIEW_LOADING_DEBOUNCE_TIME = 1000;
  readonly customStyle = `.container {margin: 0 !important}`;

  form = new FormGroup({
    emailTemplateId: new FormControl<string | null>(null),
    organisationId: new FormControl<string | null>(null),
    code: new FormControl<string | null>(null),
    subjectTemplate: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.minLength(1), CustomFormValidators.maxLength(200)]),
    bodyTemplate: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.minLength(1), CustomFormValidators.maxLength(1000)]),
    footerTemplate: new FormControl<string | null>(null, [CustomFormValidators.maxLength(1000)]),
  });

  htmlContent = signal<SafeHtml | null>(null);
  logo = toSignal(this.organisationService.getSmallLogo()
    .pipe(map(b => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(b))))
    .pipe(catchError(() => of('assets/svg/bolt-logo-icon.svg'))));

  ngOnInit(): void {
    this.loading = true;

    this.emailTemplatesService.loadEmailTemplates().pipe(
      tap((templates) => {
        this.emailTemplateOptions = templates.map(template => ({
          label: template.subjectTemplate,
          value: template.emailTemplateId
        }));

        this.emailTemplates = templates;
        this.setEmailPreview();
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(); 

    this.watchChanges(); 
  }

  watchChanges(): void {
    this.form.valueChanges.pipe(
      debounceTime(this.PREVIEW_LOADING_DEBOUNCE_TIME),
      tap(value => {
        this.loading = true;
        if(value) {
          value.subjectTemplate = this.replaceNewLine(value.subjectTemplate ?? '');
          value.bodyTemplate = this.replaceNewLine(value.bodyTemplate ?? '');
          value.footerTemplate = this.replaceNewLine(value.footerTemplate ?? '');

          const emailData: Partial<ESignBody> = {
            payloadModel: this.payloadMockedModel,
            emailTemplate: value as EmailTemplate
          }

          this.changeEmail.emit(emailData);
        }
      }),
      switchMap(() => this.loadPreview()),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sendByMethod']?.currentValue && this.emailTemplates.length) {
      this.setEmailPreview();
    }
  }

  private setEmailPreview(): void {
    const templateCode = this.getTemplateCode();
    if (templateCode) {
      this.selectEmailTemplate(templateCode);
    }
  }
  
  private getTemplateCode(): string | null {
    if (this.isDocument && this.sendByMethod === SendOption.Email) return 'asic-documents';
    if (this.isDocument && this.sendByMethod === SendOption.Sign) return 'asic-documents-esign';
    if (!this.isDocument && this.sendByMethod === SendOption.Email) return 'as-email';
    if (!this.isDocument && this.sendByMethod === SendOption.Sign) return 'as-esign';
    return null;
  }

  selectEmailTemplate(templateCode: string): void {
    const emailTemplate = this.emailTemplates.filter(template => template.code === templateCode);
    if(emailTemplate.length) {
      this.template = emailTemplate[0];

      const prefillResult = this.#emailTemaplatePrefillService.fillPayload(templateCode, this.documentBase, this.lodgement);
      this.loadPreviewContent(prefillResult.payload, prefillResult.ignoredValueSubstitution);

      this.payloadMockedModel = prefillResult.payload;
    }
  }

  private loadPreviewContent(payload: EmailPayloadBase, ignoredValueSubstitution: string[]): void {
    this.emailTemplatesService.loadPreviewContent(
      new EmailPreviewContentRequest({ template: this.template, payloadModel: payload, ignoreValueSubstitution: ignoredValueSubstitution })
    )
      .pipe(
        tap((res) => {
          this.form.patchValue({
            emailTemplateId: this.template.emailTemplateId,
            organisationId: this.template.organisationId,
            code: this.template.code,
            subjectTemplate: this.prepareTemplateString(res.subject),
            bodyTemplate: this.prepareTemplateString(res.body),
            footerTemplate: this.prepareTemplateString(res.footer),
          });
        })
      )
      .subscribe();
  }

  loadPreview(): Observable<IEmailPreviewResponse> {
    const errorFormResponse = of({ subject: '', htmlContent: '' });

    return this.emailTemplatesService.loadPreview(EmailTemplatesHelper.buildEmailPreviewRequest(this.buildTemplate(), this.payloadMockedModel as EmailPayloadBase))
      .pipe(
        tap({
          next: (res) => {
            this.htmlContent.set(this.sanitizer.bypassSecurityTrustHtml(res.htmlContent) ?? '');
            this.loading = false;
          },
          error: (error) => {
            console.warn('[loadPreview] error: ', error);
            this.toastr.error("Something happened while loading preview", "Error");
            this.loading = false;
          }
        }),
        catchError(() => errorFormResponse),
      );
  }

  private buildTemplate(): EmailTemplate {
    return new EmailTemplate({
      ...this.template,
      subjectTemplate: this.previewMode ? this.template?.subjectTemplate: this.subjectTemplate,
      bodyTemplate: this.previewMode ? this.template?.bodyTemplate : this.bodyTemplate ,
      footerTemplate: this.previewMode ? this.template?.footerTemplate : this.footerTemplate,
    });
  }

  private replaceNewLine(text: string | null): string {
    return text?.replaceAll('\n', '</br>') ?? '';
  }

  private prepareTemplateString(text: string | null): string {
    return (text ?? '')
      .replaceAll('\n', '')
      .replaceAll('<br />', '\n')
      .replaceAll('</br>', '\n');
  }

  public toggleFooter(): void {
    this.showFooter = !this.showFooter;
  }

  get subjectTemplate(): string {
    return this.replaceNewLine(this.form.controls.subjectTemplate.value);
  }

  get bodyTemplate(): string {
    return this.replaceNewLine(this.form.controls.bodyTemplate.value);
  }

  get footerTemplate(): string {
    return this.replaceNewLine(this.form.controls.footerTemplate.value);
  }
}
