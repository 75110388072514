import { ChangeDetectorRef, Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SelectGroupsComponent } from "../../../../components/common/select-groups/select-groups.component";
import { CompanySecurityIssue, ShareholderTypesEnum, shareholderTypesOptionGroups } from '../../share-issue/share-issue.model';
import { SelectShareholderComponent } from "../../../../components/shares/select-shareholder/select-shareholder.component";
import { IndividualDataFormGroup, IndividualDataFormGroupComponent, IndividualDataFormGroupControls } from "../../../../components/reusable-form-groups/individual-data-form-group/individual-data-form-group.component";
import { CompanyNameAcnFormGroupComponent, CompanyNameAcnFormGroupControls } from "../../../../components/reusable-form-groups/company-name-acn-form-group/company-name-acn-form-group.component";
import { AddressFormGroupComponent, AddressFormGroupControls } from "../../../../components/reusable-form-groups/address-form-group/address-form-group.component";
import { ShareJointSecurityHolderComponent } from "../share-joint-security-holder/share-joint-security-holder.component";
import { YesNoControlComponent } from "../../../../components/common/yes-no-control-component/yes-no-control.component";
import { InputComponent } from "../../../../components/common/input/input.component";
import { CheckboxComponent } from '../../../../components/common/checkbox/checkbox.component';
import { ShareSecurityTypesDropdownComponent } from "../../../../components/shares/share-security-types-dropdown/share-security-types-dropdown.component";
import { InputNumberComponent } from "../../../../components/common/input-number/input-number.component";
import { ValidationErrorComponent } from "../../../../components/common/validation-error/validation-error.component";
import { FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { ReusableFormGroupComponent } from '../../../../components/reusable-form-groups/reusable-form-group/reusable-form-group.component';
import { CorporateHolderModel, IndividualHolderModel, SecurityRegistryRecord } from '../../../../../models/securityRegistryRecord';
import { NumbersValidators } from '../../../../../validators/numbers.validators';
import { AddressFormGroup } from '../../../../components/common/address-control/address-control.component';
import { setControlDisabled } from '../../../../../functions/set-control-disabled';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SecurityType } from '../../../../../models/securityType';
import { acnValidator } from '../../../../../validators/acn.validator';
import { companyNameValidator } from '../../../../../validators/compny-name.validator';
import { customErrorsOfShareCancellationNumbers, totalNumberOfSharesCustomErrors } from '../../share-cancellation/company-security-cancellation.model';
import { SecurityTransactionIncremental } from '../../../../../models/securityTransactionIncremental';
import { Guid } from '../../../../helpers/guid.helper';
import { CompanyChangeData } from '../../../../../models/enums/companyChangeData';
import { SecurityTransactionType } from '../../../../../models/enums/SecurityTransactionType';
import { EntityData } from '../../../../../models/entityData';
import { Address } from '../../../../../models/address';
import { ShareholderRelationshipDetails } from '../../../../../models/relationship';
import { IndividualData } from '../../../../../models/individualData';
import { groupSecurityRecordsByName } from '../../../../helpers/security.helper';

export interface IShareIssueFormGroupControls extends CompanyNameAcnFormGroupControls {
  shareholderType: FormControl<ShareholderTypesEnum | null>,
  securityRegistryRecordGroup: FormControl<SecurityRegistryRecord[] | null>,
  securityTypeId: FormControl<string | null>,
  numberIncrease: FormControl<number | null>,
  paidIncrease: FormControl<number | null>,
  unpaidIncrease: FormControl<number | null>,
  isNotBeneficialOwner: FormControl<boolean | null>,
  beneficialOwner: FormControl<string | null>,

  newIndividualShareholder: IndividualDataFormGroup,

  isOverseasCompany: FormControl<boolean>,
  registeredAddress: AddressFormGroup,

  joint: FormControl<(IndividualHolderModel | CorporateHolderModel)[] | null>
}

export type IShareIssueFormGroup = FormGroup<IShareIssueFormGroupControls>;

@Component({
  selector: 'app-share-issue-block',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SelectGroupsComponent,
    SelectShareholderComponent,
    IndividualDataFormGroupComponent,
    CompanyNameAcnFormGroupComponent,
    AddressFormGroupComponent,
    ShareJointSecurityHolderComponent,
    YesNoControlComponent,
    InputComponent,
    CheckboxComponent,
    ShareSecurityTypesDropdownComponent,
    InputNumberComponent,
    ValidationErrorComponent
  ],
  templateUrl: './share-issue-block.component.html',
  styleUrl: './share-issue-block.component.scss'
})
export class ShareIssueBlock extends ReusableFormGroupComponent<IShareIssueFormGroup> implements OnInit, OnChanges {
  @Input({ required: true }) securityRegistryRecords: SecurityRegistryRecord[] = [];
  @Input({ required: true }) securityTypes: SecurityType[] = [];
  @Input({ required: true }) companyChangeData!: CompanyChangeData;
  @Input() authorisedSecurityRegistryRecords: SecurityRegistryRecord[] | undefined;
  destroyRef: DestroyRef = inject(DestroyRef);
  cdr = inject(ChangeDetectorRef);

  readonly totalNumberOfSharesCustomErrors = totalNumberOfSharesCustomErrors;
  readonly customErrorsOfShareCancellationNumbers = customErrorsOfShareCancellationNumbers;
  readonly ShareholderTypesEnum = ShareholderTypesEnum;
  readonly shareholderTypesOptionGroups = shareholderTypesOptionGroups;
  readonly individualDataFormLabels: Record<keyof Partial<IndividualDataFormGroupControls>, string> = { fullName: 'Individual Name' } as Record<keyof Partial<IndividualDataFormGroupControls>, string>;
  readonly addressFormLabels: Record<keyof Partial<AddressFormGroupControls>, string> = { normalizedFullAddress: 'Registered address' } as Record<keyof Partial<AddressFormGroupControls>, string>;
  readonly hiddenIndividualControls: (keyof IndividualDataFormGroupControls)[] = ['formerName', 'dob', 'birthCity', 'birthCountry'];

  selectedIndividualShareholder: IndividualHolderModel | null = null;
  selectedCompanyShareholder: EntityData | null = null;
  selectedShareholderShareType: SecurityType | null = null;

  groupedSecurityRegistryRecords: SecurityRegistryRecord[][] = [];

  public override ngOnInit(): void {
    super.ngOnInit();
    this.setupFormChangeListeners();
    this.setCustomFormValidators();
    this.disableUnnecessaryControls();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['securityRegistryRecords']) {
      this.groupedSecurityRegistryRecords = groupSecurityRecordsByName(this.securityRegistryRecords);
    }
  }

  static override defineFormControls() {
    return {
      shareholderType: new FormControl<ShareholderTypesEnum | null>(null, [Validators.required]),
      securityRegistryRecordGroup: new FormControl<SecurityRegistryRecord[] | null>(null, [Validators.required]),
      securityTypeId: new FormControl<string | null>(null, [Validators.required]),
      numberIncrease: new FormControl<number | null>(null, [Validators.required, NumbersValidators.min(0), NumbersValidators.isInteger]),
      paidIncrease: new FormControl<number | null>(null, [Validators.required, NumbersValidators.min(0)]),
      unpaidIncrease: new FormControl<number | null>(null, [Validators.required, NumbersValidators.min(0)]),
      isNotBeneficialOwner: new FormControl<boolean | null>(false, [Validators.required]),
      beneficialOwner: new FormControl<string | null>(null, [Validators.required]),

      newIndividualShareholder: IndividualDataFormGroupComponent.defineForm(),

      isOverseasCompany: new FormControl<boolean>(false),
      registeredAddress: AddressFormGroupComponent.defineForm(),

      ...CompanyNameAcnFormGroupComponent.defineFormControls(), // name and acn controls

      joint: new FormControl<(IndividualHolderModel | CorporateHolderModel)[] | null>(null, [Validators.required])
    };
  }

  setupFormChangeListeners() {
    this.listenShareholderTypeChanges();
    this.listenNumberIncreaseChange();
    this.listenShareTypeChanges();
    this.listenIsSharesOwnedOnBehalfChanges();
    this.listenIsOverseasCompanyChange();
  }

  private listenShareholderTypeChanges(): void {
    this.form.controls.shareholderType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((shareholderType) => {
        if (shareholderType === null) {
          return;
        }

        // set state of the existing security holder
        setControlDisabled(this.form.controls.securityRegistryRecordGroup, shareholderType !== ShareholderTypesEnum.ExistingShareholder);
        setControlDisabled(this.form.controls.isNotBeneficialOwner, shareholderType === null || shareholderType === ShareholderTypesEnum.ExistingShareholder);
        setControlDisabled(this.form.controls.beneficialOwner, shareholderType === null || shareholderType === ShareholderTypesEnum.ExistingShareholder);

        // set state of the new Individual Shareholder control
        setControlDisabled(this.form.controls.newIndividualShareholder, shareholderType !== ShareholderTypesEnum.Individual);
        this.hiddenIndividualControls.forEach(key => setControlDisabled(this.form.controls.newIndividualShareholder.controls[key]));

        // set state of the new Corporate Shareholder control
        setControlDisabled(this.form.controls.acn, shareholderType !== ShareholderTypesEnum.Corporate);
        setControlDisabled(this.form.controls.name, shareholderType !== ShareholderTypesEnum.Corporate);
        setControlDisabled(this.form.controls.registeredAddress, shareholderType !== ShareholderTypesEnum.Corporate);

        // set state of the new Joint Shareholder control
        setControlDisabled(this.form.controls.joint, shareholderType !== ShareholderTypesEnum.JointShareholder);

        if (shareholderType !== ShareholderTypesEnum.ExistingShareholder) {
          this.form.controls.isNotBeneficialOwner.patchValue(this.form.controls.isNotBeneficialOwner.value ?? null);
        }
      });
  }

  private listenNumberIncreaseChange(): void {
    this.form.controls.numberIncrease.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((numberIncrease) => {
        if (numberIncrease === null || this.selectedShareholderShareType === null) {
          return;
        }

        this.form.controls.paidIncrease.patchValue(numberIncrease * this.selectedShareholderShareType.paidAmount);
        this.form.controls.unpaidIncrease.patchValue(numberIncrease * this.selectedShareholderShareType.unpaidAmount);
      });
  }

  private listenShareTypeChanges(): void {
    this.form.controls.securityTypeId.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((securityTypeId) => {
        this.selectedShareholderShareType = this.securityTypes.find(type => type.securityTypeId === securityTypeId) ?? null;
        this.form.controls.numberIncrease.patchValue(this.form.value.numberIncrease ?? null);
      });
  }

  private listenIsSharesOwnedOnBehalfChanges(): void {
    this.form.controls.isNotBeneficialOwner.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isSharesOwnedOnBehalf) => {
        setControlDisabled(this.form.controls.beneficialOwner, !isSharesOwnedOnBehalf);
      });
  }

  private listenIsOverseasCompanyChange(): void {
    this.form.controls.isOverseasCompany.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isOverseasCompany) => {
        if (isOverseasCompany) {
          this.form.controls.acn.clearValidators();
          this.form.controls.name.setValidators([Validators.required, Validators.minLength(7)]);
        } else {
          this.form.controls.acn.setValidators([Validators.required, acnValidator()]);
          this.form.controls.name.setValidators([Validators.required, Validators.minLength(7), companyNameValidator()]);
        }

        this.cdr.detectChanges();
        this.form.updateValueAndValidity();
        this.form.controls.acn.updateValueAndValidity();
        this.form.controls.acn.patchValue(this.form.value.acn ?? '');
      });
  }

  addShareType(newSecurityHolding: SecurityType): void {
    this.securityTypes.push(newSecurityHolding);
    this.form.controls.securityTypeId.setValue(newSecurityHolding.securityTypeId);
  }

  disableUnnecessaryControls() {
    this.hiddenIndividualControls.forEach(key => {
      setControlDisabled(this.form.controls.newIndividualShareholder.controls[key])
    })
  }


  setCustomFormValidators(): void {
    const paidTotalValidator: ValidatorFn = () => {
      if (this.selectedShareholderShareType === null || !this.form.value.paidIncrease || !this.form.value.numberIncrease) {
        return null;
      }
      // (Math.abs(prefilled total paid - total paid))/prefilled total paid < 0.05

      const calculatedTotalPaid = this.selectedShareholderShareType.paidAmount * this.form.value.numberIncrease;
      return (Math.abs(calculatedTotalPaid - this.form.value.paidIncrease) / calculatedTotalPaid) < 0.05
        ? null
        : { paidChangeIsBiggerThan5Percent: true };
    };

    const unpaidTotalValidator: ValidatorFn = () => {
      if (this.selectedShareholderShareType === null || !this.form.value.unpaidIncrease || !this.form.value.numberIncrease) {
        return null;
      }
      // (Math.abs(prefilled total unpaid - total unpaid))/prefilled total unpaid < 0.05

      const calculatedTotalPaid = this.selectedShareholderShareType.unpaidAmount * this.form.value.numberIncrease;
      return (Math.abs(calculatedTotalPaid - this.form.value.unpaidIncrease) / calculatedTotalPaid) < 0.05
        ? null
        : { unpaidChangeIsBiggerThan5Percent: true };
    };

    // total paid + total unpaid > 0
    const paidUnpaidSumValidator: ValidatorFn = () => {
      const totalPaid = this.form.controls.paidIncrease.getRawValue();
      const totalUnpaid = this.form.controls.unpaidIncrease.getRawValue();
      if (totalPaid === null || totalUnpaid === null) {
        return null;
      }

      return (totalPaid + totalUnpaid) > 0
        ? null
        : { paidUnpaidSumIsNotPositive: true };
    };

    this.form.setValidators([
      paidTotalValidator,
      unpaidTotalValidator,
      paidUnpaidSumValidator
    ]);
  }

  get formShareNumberErrorMessageHidden(): boolean {
    return Boolean(this.form.controls.numberIncrease?.untouched
      && this.form.controls.paidIncrease?.untouched
      && this.form.controls.unpaidIncrease?.untouched
      && !this.form?.errors);
  }

  public buildShareFormChange(): CompanySecurityIssue {
    const securityType = structuredClone(this.securityTypes.find(type => type.securityTypeId === this.form.value.securityTypeId)!);
    const companySecurityIssue = new CompanySecurityIssue({
      $type: 'CompanySecurityIssue',
      issue: new SecurityTransactionIncremental({
        securityHoldingId: Guid.EmptyGuid,
        entityId: this.companyChangeData?.entityId,
        organisationId: this.companyChangeData?.organisationId,
        documentId: this.companyChangeData?.documentId ?? null,
        transactionType: SecurityTransactionType.Issue,
        numberIncrease: this.form.controls.numberIncrease.value!,
        paidIncrease: this.form.controls.paidIncrease.value!,
        unpaidIncrease: this.form.controls.unpaidIncrease.value!,
        securityTypeId: securityType.securityTypeId,
        securityType,
      })
    });

    if (!companySecurityIssue.issue.securityType.securityTypeId || !companySecurityIssue.issue.securityType.entityId) {
      delete (companySecurityIssue.issue.securityType as any).entityId;
      delete (companySecurityIssue.issue.securityType as any).securityTypeId;
    }

    switch (this.form.value.shareholderType) {
      case ShareholderTypesEnum.ExistingShareholder: {
        const selectedHolders = this.form.value.securityRegistryRecordGroup![0]?.holders;
        companySecurityIssue.securityHolders = this.buildShortBaseHolders(selectedHolders.filter(Boolean));
        break;
      }
      case ShareholderTypesEnum.Individual: {
        const individualData = IndividualDataFormGroupComponent.toIndividualData(this.form.controls.newIndividualShareholder as FormGroup<Partial<IndividualDataFormGroupControls>>);
        const newIndividualSecurityHolder = new IndividualHolderModel({
          individualData,
        });
        companySecurityIssue.securityHolders = [this.buildShortIndividualHolderModel(newIndividualSecurityHolder)];
        break;
      }
      case ShareholderTypesEnum.Corporate: {
        const entityData = new EntityData({
          name: this.form.value.name ?? '',
          entityNumber: this.form.value.acn ?? '',
          registeredAddress: new Address(this.form.value.registeredAddress as Partial<Address>),
        });
        const newCorporateSecurityHolder = new CorporateHolderModel({ entityData });

        newCorporateSecurityHolder.details.authorisedSignatories = this.getHolderAuthorisedSignatories(newCorporateSecurityHolder);
        companySecurityIssue.securityHolders = [this.buildShortCorporateHolderModel(newCorporateSecurityHolder)];
        break;
      }
      case ShareholderTypesEnum.JointShareholder: {
        companySecurityIssue.securityHolders = this.buildShortBaseHolders(this.form.value.joint ?? []);
        break;
      }
      default:
        break;
    }
    companySecurityIssue.issue.relationshipIds = companySecurityIssue.securityHolders.map(holder => holder.relationshipId);
    companySecurityIssue.issue.documentId = this.companyChangeData.documentId ?? null as unknown as string;
    return companySecurityIssue;
  }

  private buildShortBaseHolders(holders: (IndividualHolderModel | CorporateHolderModel)[]): (IndividualHolderModel | CorporateHolderModel)[] {
    return holders.map(holder => {
      if (holder.$type === IndividualHolderModel.$type) {
        return this.buildShortIndividualHolderModel(holder as IndividualHolderModel);
      } else if (holder.$type === CorporateHolderModel.$type) {
        return this.buildShortCorporateHolderModel(holder as CorporateHolderModel);
      }
      return holder;
    });
  }

  private buildShortIndividualHolderModel(individualHolder: IndividualHolderModel): IndividualHolderModel {
    const isBeneficialOwner = this.form.value.isNotBeneficialOwner !== null
      ? !this.form.value.isNotBeneficialOwner
      : individualHolder.details.isBeneficialOwner;
    const individualHolderModel = {
      $type: IndividualHolderModel.$type,
      relationshipId: individualHolder.relationshipId || Guid.generate(),
      details: {
        isBeneficialOwner,
        $type: new ShareholderRelationshipDetails().$type,
        beneficialOwner: this.form.value.beneficialOwner ?? individualHolder.details.beneficialOwner ?? '',
      } as unknown as ShareholderRelationshipDetails,
      individualData: {
        firstName: individualHolder.individualData.firstName,
        middleName1: individualHolder.individualData.middleName1,
        middleName2: individualHolder.individualData.middleName2,
        lastName: individualHolder.individualData.lastName,
        address: individualHolder.individualData.address
      } as IndividualData
    } as IndividualHolderModel;

    if (individualHolder.individualId) {
      individualHolderModel.individualId = individualHolder.individualId;
    }

    return individualHolderModel;
  }

  private buildShortCorporateHolderModel(corporateHolder: CorporateHolderModel): CorporateHolderModel {
    const isBeneficialOwner = this.form.value.isNotBeneficialOwner !== null
      ? !this.form.value.isNotBeneficialOwner
      : corporateHolder.details.isBeneficialOwner;
    const corporateHolderModel = {
      $type: 'CorporateHolderModel',
      relationshipId: corporateHolder.relationshipId || Guid.generate(),
      details: {
        isBeneficialOwner,
        authorisedSignatories: this.getHolderAuthorisedSignatories(corporateHolder),
        $type: new ShareholderRelationshipDetails().$type,
        beneficialOwner: this.form.value.beneficialOwner ?? corporateHolder.details.beneficialOwner ?? '',
      } as unknown as ShareholderRelationshipDetails,
      entityData: ({
        name: corporateHolder.entityData.name,
        entityNumber: corporateHolder.entityData.entityNumber,
        registeredAddress: corporateHolder.entityData.registeredAddress
      } as EntityData),
    } as CorporateHolderModel;

    return corporateHolderModel;
  }

  private getHolderAuthorisedSignatories(holder: CorporateHolderModel): IndividualData[] {
    if (!this.authorisedSecurityRegistryRecords) {
      return [];
    }

    return this.authorisedSecurityRegistryRecords
      .find(record => record.holders.some(h => h.name === holder.name))
      ?.holders[0].details.authorisedSignatories ?? [];
  }

  setupChange(change: CompanySecurityIssue) {
    // check if the form needs a user-created security type.
    const usersSecurityType = this.securityTypes.find((securityType) => securityType.securityTypeId === change.issue.securityTypeId);
    if (change.issue.securityTypeId && !usersSecurityType) {
      this.securityTypes.push(change.issue.securityType);
    }

    this.form.patchValue(change as any);
    this.form.patchValue({
      ...change.issue,
    });


    const selectedSecurityRegistryRecordGroup = this.groupedSecurityRegistryRecords
      .find((group) => group.some(record => record?.holders
        .every(holder => change.issue.relationshipIds?.includes(holder.relationshipId))));

    // set the shareholder type
    if (selectedSecurityRegistryRecordGroup) {
      this.form.controls.shareholderType.patchValue(ShareholderTypesEnum.ExistingShareholder);
      this.form.controls.securityRegistryRecordGroup.patchValue(selectedSecurityRegistryRecordGroup);
      this.cdr.detectChanges();
    } else if (change.securityHolders.length >= 2) {
      this.form.controls.shareholderType.patchValue(ShareholderTypesEnum.JointShareholder);
      this.form.controls.joint.setValue(change.securityHolders);
      this.form.controls.isNotBeneficialOwner.patchValue(!change.securityHolders[0].details.isBeneficialOwner);
      this.form.controls.beneficialOwner.patchValue(change.securityHolders[0].details.beneficialOwner);
    } else if (change.securityHolders.length === 1) {
      const holder = change.securityHolders[0];

      if (holder.$type === IndividualHolderModel.$type) {
        this.selectedIndividualShareholder = holder as IndividualHolderModel;
        this.form.controls.shareholderType.patchValue(ShareholderTypesEnum.Individual);
        this.form.controls.newIndividualShareholder.patchValue(this.selectedIndividualShareholder.individualData);
      } else {
        this.selectedCompanyShareholder = (holder as CorporateHolderModel).entityData;
        this.form.controls.shareholderType.patchValue(ShareholderTypesEnum.Corporate);
        this.form.controls.isOverseasCompany.patchValue(this.selectedCompanyShareholder.registeredAddress.country !== 'AU');
        this.form.patchValue({
          name: this.selectedCompanyShareholder.name,
          acn: this.selectedCompanyShareholder.entityNumber,
          registeredAddress: this.selectedCompanyShareholder.registeredAddress
        });
      }

      this.form.controls.isNotBeneficialOwner.patchValue(!holder.details.isBeneficialOwner);
      this.form.controls.beneficialOwner.patchValue(holder.details.beneficialOwner);
    }

    this.form.controls.securityTypeId.setValue(change.issue.securityType?.securityTypeId ?? null);
    this.form.patchValue({
      numberIncrease: change.issue.numberIncrease,
      paidIncrease: change.issue.paidIncrease,
      unpaidIncrease: change.issue.unpaidIncrease,
    });
  }
}
