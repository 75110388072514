import { EntityChangeData } from "../../../../models/entityChangeData";
import { ChangeDictionaryHelper } from "../../../../models/shared/change-dictionary-helper.model";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Document } from "../../../../models/document";
import { Company } from "../../../../models/company";
import { CompanyChangeData } from "../../../../models/enums/companyChangeData";
import { SelectOption } from "../../../../models/selectOptions";
import { PascalCaseToText } from "../../../../functions/enums-to-list-formatter";
import { RelationshipType } from "../../../../models/enums/relationshipTypeEnum";
import { BulkChangeNameFormComponent } from "./bulk-change-name-form.component";
import { Relationship } from "../../../../models/relationship";
import { DocumentationType } from "../../../../models/enums/documentConfirmEnums";

export class BulkCompanyChangeName extends EntityChangeData {
  static override readonly $type = 'CompanyChangePartyName';

  firstName: string;
  middleName1: string;
  middleName2: string;
  lastName: string;
  companyName: string;
  relationshipIds: string[];
  documentationType: DocumentationType;
  relationships: Relationship[];

  constructor(data: Partial<BulkCompanyChangeName> = {}) {
    super(data);

    this.firstName = data?.firstName ?? '';
    this.middleName1 = data?.middleName1 ?? '';
    this.middleName2 = data?.middleName2 ?? '';
    this.lastName = data?.lastName ?? '';
    this.companyName = data?.companyName ?? '';
    this.relationshipIds = data?.relationshipIds?.filter(Boolean) ?? [];
    this.documentationType = data.documentationType ?? DocumentationType.Default;
    this.relationships = data.relationships ?? [];
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict.addDate('Date of Change', this.changeDate);

    // if (this.relationshipIds.length)
    //   dict.addIds('Date of Change', this.relationshipIds);


    if (this.companyName) {
      dict.add('New Company name', this.companyName);
    } else {
      const newName = [this.firstName, this.middleName1, this.middleName2, this.lastName]
        .filter(Boolean)
        .join(' ');
      dict.add('New name', newName);
    }

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as BulkChangeNameFormComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as BulkChangeNameFormComponent;

    instance.isEdit = isEdit;
    instance.formModel = structuredClone(this);
    instance.companyChangeData = new CompanyChangeData(company);

    // TODO: remove after individualId works fine
    const officerIndividualsIdDictionary: Record<string, string> = {};

    instance.officersChangeNameOptions = structuredClone(company.officers.filter(r => !r.end))
      ?.reduce((officersSelectOptions: SelectOption[], officer) => {
          // TODO: remove after individualId works fine
          if (!(officer.individualDataOverride!.fullName in officerIndividualsIdDictionary)) {
            officerIndividualsIdDictionary[officer.individualDataOverride!.fullName] = Math.random().toString(36).substring(7);
          }

          // TODO: remove after individualId works fine
          officer.key = officer.individualId ?? officerIndividualsIdDictionary[officer.individualDataOverride!.fullName];

          const officerOption: SelectOption = {
            label: officer.individualDataOverride!.fullName,
            value: officer.key,
          };

          if (!(officer.key in instance.officers)) {
            instance.officers[officer.key] = [];

            officersSelectOptions?.push(officerOption);
          }

          instance.officers[officer.key]?.push(officer);

          return officersSelectOptions;
        },
        []
      ).map((officer) => {
        const officerTypes = instance.officers[officer.value as string].map(officer => PascalCaseToText(RelationshipType[officer.type]));

        return {
          label: officer.label + ` (${officerTypes.filter(Boolean).join(', ')})`,
          value: officer.value
        };
      })
      .sort((a, b) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase()));

    return modalRef;
  }
}
