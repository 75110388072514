import { AsicDocumentsEmailPayload } from "./AsicDocumentsEmailPayload";

export class AsicDocumentsEsignEmailPayload extends AsicDocumentsEmailPayload {
  static override $type = 'AsicDocumentsEsignEmailPayload';

  signUrl: string;

  constructor(data: Partial<AsicDocumentsEsignEmailPayload> = {}) {
    super(data);
    this.signUrl = data.signUrl ?? '';
  }
}
