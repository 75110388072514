import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../../../models/company';
import { Document } from '../../../../models/document';
import { CompanyChangeData } from '../../../../models/enums/companyChangeData';
import { SecurityChange } from '../../../../models/securityChange';
import { IEdgeDocument } from '../../../../models/edgeDocument';
import { HistoricalTransactionComponent } from './historical-transaction.component';

export enum HistoricalTransactionStepsEnum {
  TransactionType = 0,
  Issue = 1,
}

export class HistoricalTransaction extends SecurityChange<Company> implements IEdgeDocument {
  static override readonly $type = 'HistoricalTransaction';

  constructor(data: Partial<HistoricalTransaction> = {}) {
    super(data);
  }


  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as HistoricalTransactionComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as HistoricalTransactionComponent;
    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = this;

    return modalRef;
  }

  override prepareToRequest(): HistoricalTransaction {
    throw new Error('Method not implemented.');
  }
}
