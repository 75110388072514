import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, Observable, of } from "rxjs";
import { environment } from "../environments/environment";
import { EmailTemplate } from "../models/email-templates/emailTemplate";
import { TemplateSettingsBase } from "../models/email-templates/templateSettingsBase";
import { EmailPreviewContentRequest, EmailPreviewRequest } from "../models/email-templates/emailPreviewRequest";
import { IEmailPreviewContentResponse, IEmailPreviewResponse } from "../models/email-templates/IEmailPreviewResponse";
import { EmailSendRequest } from "../models/email-templates/emailSendRequest";
import { IServerSideGetRowsRequest } from "ag-grid-enterprise";
import { PageResult } from "../models/pageResult";
import { reorderGridFilterModel } from "../app/helpers/reorder-grid-filter";
import { EmailRecord } from "../models/email-templates/emailRecord";
import { EmailModel } from "../models/email-templates/emailModel";
import { EmailBulkSendRequest } from "../models/email-templates/emailBulkSendRequest";

@Injectable()
export class EmailTemplatesService {
  private api = inject(HttpClient);

  loadPreview(payload: EmailPreviewRequest): Observable<IEmailPreviewResponse> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.api.post<IEmailPreviewResponse>(`${ environment.api_url }/emails/preview`, payload, { headers });
  }

  loadPreviewContent(payload: EmailPreviewContentRequest): Observable<IEmailPreviewContentResponse> {
    return this.api.post<IEmailPreviewContentResponse>(`${ environment.api_url }/emails/preview/content`, payload);
  }

  loadEmailTemplates(): Observable<EmailTemplate[]> {
    return this.api.get<EmailTemplate[]>(`${ environment.api_url }/emails/templates`);
  }

  loadEmailDefaultTemplates(): Observable<Record<string, TemplateSettingsBase>> {
    return this.api.get<Record<string, TemplateSettingsBase>>(`${ environment.api_url }/emails/templates/default`);
  }

  saveEmailTemplate(emailTemplate: EmailTemplate): Observable<EmailTemplate> {
    return this.api.post<EmailTemplate>(`${ environment.api_url }/emails/templates`, emailTemplate);
  }

  sendEmailTemplate(emailTemplate: EmailSendRequest): Observable<string> {
    return this.api.post<string>(`${ environment.api_url }/emails/send`, emailTemplate);
  }

  sendBulkEmailTemplate(emailTemplate: EmailBulkSendRequest, documentId: string): Observable<string> {
    return this.api.post<string>(`${ environment.api_url }/emails/send/bulk/${documentId}`, emailTemplate);
  }

  getEmailList(): Observable<{ email: string, fullName: string }[]> {
    const emailList = [
      { email: 'luna.blackwood@innovatechsolutions1.com', fullName: 'Luna Blackwood1' },
      { email: 'luna.blackwood@innovatechsolutions2.com', fullName: 'Luna Blackwood2' },
      { email: 'luna.blackwood@innovatechsolutions3.com', fullName: 'Luna Blackwood3' }
    ];

    return of(emailList);
  }

  getOutgoingEmailMessagesList(request: IServerSideGetRowsRequest, search?: string): Observable<PageResult<EmailRecord>> {
    request = reorderGridFilterModel(request);

    let params = new HttpParams();
    if (search) {
      params = params.append('search', search);
    }

    return this.api.post<PageResult<EmailRecord>>(`${ environment.api_url }/emails/grid`, request, { params: params })
      .pipe(map((response) => {
        response.records = response.records.map((emailRecord) => new EmailRecord(emailRecord));
        return response;
      }));
  }

  getEmailDetails(emailMessageId: string): Observable<EmailModel> {
    return this.api.get(`${ environment.api_url }/emails/${ emailMessageId }/details`)
      .pipe(map((emailModel) => new EmailModel(emailModel)));
  }

  resendOutgoingEmailMessage(emailMessageId: string): Observable<string> {
    return this.api.get<string>(`${ environment.api_url }/emails/${ emailMessageId }/resend`)
  }

  deleteOutgoingEmailMessage(emailMessageId: string): Observable<void> {
    return this.api.delete<void>(`${ environment.api_url }/emails/${ emailMessageId }`)
  }
}
