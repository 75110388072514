import { inject, Injectable } from "@angular/core";
import { DocumentBase } from "../models/documentEnteties/document-base";
import { AsicDocumentsEsignEmailPayload } from "../models/email-templates/payloads/AsicDocumentsEsignEmailPayload";
import { UIProfile } from "../models/uiProfile";
import { DocumentTypePipe } from "../pipes/document-type.pipe";
import { EmailPayloadBase } from "../models/email-templates/emailPayloadBase";
import { EmailTemplateCodesToTypes } from "../models/email-templates/emailTemplatesHelper";
import { OrganisationService } from "./organisation.service";
import { LodgementDeadline } from "../models/documents";
import { DateFormatPipe, DateFormatType } from "../pipes/dateFormatPipe";
import { AnnualStatementEmailPayload } from "../models/email-templates/payloads/AnnualStatementEmailPayload";
import { AnnualStatement } from "../models/annualStatement";
import { CurrencyPipe, formatDate } from "@angular/common";
import { AnnualStatementEsignEmailPayload } from "../models/email-templates/payloads/AnnualStatementEsignEmailPayload";
import { AsicDocumentsEmailPayload } from "../models/email-templates/payloads/AsicDocumentsEmailPayload";
import { AsicDebtReminderEmailPayload } from "../models/email-templates/payloads/AsicDebtReminderEmailPayload";
import { Company } from "../models/company";
import { ChangeAuthorisation } from "../models/changeAuthorisation";
import { AnnualStatementDebtReminderEmailPayload } from "../models/email-templates/payloads/AnnualStatementDebtReminderEmailPayload";

interface IPrefillResult{
  payload: EmailPayloadBase;
  ignoredValueSubstitution: string[];
}

@Injectable({
    providedIn: 'root'
})
export class EmailTemaplatePrefillService{
  #documentTypePipe = inject(DocumentTypePipe);
  #organisationService = inject(OrganisationService);
  #dateFormatPipe = inject(DateFormatPipe);
  #currencyPipe = inject(CurrencyPipe);

  profile: UIProfile | undefined;

  constructor(){
    this.profile = this.#organisationService.getCachedProfile();
    if(!this.profile){
      this.#organisationService.getProfile().subscribe(profile => this.profile = profile);
    }
  }

  fillPayload(code: string, documentBase: DocumentBase, lodgement: LodgementDeadline | undefined): IPrefillResult{
    const type = EmailTemplateCodesToTypes[code];
    switch(type){
      case AsicDocumentsEsignEmailPayload.$type:
        return this.prefillAsicDocumentsEsignEmailPayload(documentBase, lodgement!);
      case AsicDocumentsEmailPayload.$type:
        return this.prefillAsicDocumentsEmailPayload(documentBase, lodgement!);
      case AnnualStatementEmailPayload.$type:
        return this.prefillAnnualStatementEmailPayload(documentBase, lodgement!);
      case AnnualStatementEsignEmailPayload.$type:
        return this.prefillAnnualStatementEsignEmailPayload(documentBase, lodgement!);
      default:  throw new Error(`Email template type ${type} is not supported`);
    }
  }

  fillAdHocReminderPayload(code: string, company: Company, changeAuthorisation: ChangeAuthorisation): EmailPayloadBase{
    const type = EmailTemplateCodesToTypes[code];
    switch(type){
      case AnnualStatementDebtReminderEmailPayload.$type:
        return this.prefillAnnualStatementDebtReminderEmailPayload(company, changeAuthorisation);
      case AsicDebtReminderEmailPayload.$type:
        return this.prefillAsicDebtReminderEmailPayload(company, changeAuthorisation);
      default:  throw new Error(`Email template type ${type} is not supported`);
    }
  }

  prefillAsicDebtReminderEmailPayload(company: Company, changeAuthorisation: ChangeAuthorisation): EmailPayloadBase {
    const payload = new AsicDebtReminderEmailPayload();
    payload.companyACN = company.acn;
    payload.companyName = company.name;

    payload.organisationName = this.profile!.organisationName ?? 'N/A';
    payload.organisationEmail = this.profile!.organisationEmail ?? 'N/A';
    payload.organisationPhone = this.profile!.organisationPhone ?? 'N/A';

    payload.billingContactFirstName = company.billingContact?.firstName ?? 'N/A';
    payload.billingContactFullName = company.billingContact?.fullName ?? 'N/A';

    payload.partner = this.profile!.users?.find(user => user.id === company.partnerManagerId)?.fullName ?? 'N/A';
    payload.accountManager = this.profile!.users?.find(user => user.id === company.accountManagerId)?.fullName ?? 'N/A';
    payload.asicContact = '' //TODO get from settings, need to refactor asic contact as it is string

    payload.directorFirstName = changeAuthorisation?.authorisingParty?.individualDataOverride?.firstName ?? changeAuthorisation?.chairperson?.individualDataOverride?.firstName ?? 'N/A',
    payload.directorFullName = changeAuthorisation?.authorisingParty?.individualDataOverride?.fullName ?? changeAuthorisation?.chairperson?.individualDataOverride?.fullName ?? 'N/A',
    payload.username = this.#organisationService.getCurrentUser()?.fullName ?? 'N/A';

    payload.payUrl = '';

    return payload;
  }
  
  prefillAnnualStatementDebtReminderEmailPayload(company: Company, changeAuthorisation: ChangeAuthorisation): EmailPayloadBase {
    const payload = new AnnualStatementDebtReminderEmailPayload();
    payload.companyACN = company.acn;
    payload.companyName = company.name;

    payload.organisationName = this.profile!.organisationName ?? 'N/A';
    payload.organisationEmail = this.profile!.organisationEmail ?? 'N/A';
    payload.organisationPhone = this.profile!.organisationPhone ?? 'N/A';

    payload.billingContactFirstName = company.billingContact?.firstName ?? 'N/A';
    payload.billingContactFullName = company.billingContact?.fullName ?? 'N/A';

    payload.partner = this.profile!.users?.find(user => user.id === company.partnerManagerId)?.fullName ?? 'N/A';
    payload.accountManager = this.profile!.users?.find(user => user.id === company.accountManagerId)?.fullName ?? 'N/A';
    payload.asicContact = '' //TODO get from settings, need to refactor asic contact as it is string

    payload.directorFirstName = changeAuthorisation?.authorisingParty?.individualDataOverride?.firstName ?? changeAuthorisation?.chairperson?.individualDataOverride?.firstName ?? 'N/A',
    payload.directorFullName = changeAuthorisation?.authorisingParty?.individualDataOverride?.fullName ?? changeAuthorisation?.chairperson?.individualDataOverride?.fullName ?? 'N/A',
    payload.username = this.#organisationService.getCurrentUser()?.fullName ?? 'N/A';

    payload.payUrl = '';

    return payload;
  }

  prefillAsicDocumentsEsignEmailPayload(documentBase: DocumentBase, lodgement: LodgementDeadline): IPrefillResult {
    const payload = new AsicDocumentsEsignEmailPayload();

    const emailPayload = this.prefillAsicDocumentsEmailPayload(documentBase, lodgement);

    Object.assign(payload, this.omitProperty(emailPayload.payload, '$type'));

    payload.signUrl = '';

    return {payload, ignoredValueSubstitution: emailPayload.ignoredValueSubstitution};
  }

  prefillAsicDocumentsEmailPayload(documentBase: DocumentBase, lodgement: LodgementDeadline): IPrefillResult {
    const payload = new AsicDocumentsEmailPayload();

    payload.companyACN = documentBase.company.acn;
    payload.companyName = documentBase.company.name;

    payload.documentName = `ASIC ${this.#documentTypePipe.transform(documentBase.type)}`;
    payload.documentType = this.#documentTypePipe.transform(documentBase.type);
    
    const signatories = documentBase.documentSigning?.signatures[0]
    payload.directorFirstName = signatories?.signatory?.firstName ?? 'N/A';
    payload.directorFullName = signatories?.signatory?.fullName ?? 'N/A';

    payload.organisationName = this.profile!.organisationName ?? 'N/A';
    payload.organisationEmail = this.profile!.organisationEmail ?? 'N/A';
    payload.organisationPhone = this.profile!.organisationPhone ?? 'N/A';
    payload.username = this.#organisationService.getCurrentUser()?.fullName ?? 'N/A';

    payload.lodgementDeadline = this.#dateFormatPipe.transform(lodgement.lodgementDeadline, DateFormatType.Long);
    payload.lodgementDeadlineShortFormat = this.#dateFormatPipe.transform(lodgement.lodgementDeadline, DateFormatType.Short);
    const tenDaysBeforeLodgement = new Date(lodgement.lodgementDeadline);
    tenDaysBeforeLodgement.setDate(tenDaysBeforeLodgement.getDate() - 10);
    payload.tenDaysBeforeLodgementDeadline = this.#dateFormatPipe.transform(tenDaysBeforeLodgement, DateFormatType.Long);
    payload.tenDaysBeforeLodgementDeadlineShortFormat = this.#dateFormatPipe.transform(tenDaysBeforeLodgement, DateFormatType.Short);

    payload.billingContactFirstName = documentBase.company.billingContact?.firstName ?? 'N/A';
    payload.billingContactFullName = documentBase.company.billingContact?.fullName ?? 'N/A';

    payload.partner = this.profile!.users?.find(user => user.id === documentBase.company.partnerManagerId)?.fullName ?? 'N/A';
    payload.accountManager = this.profile!.users?.find(user => user.id === documentBase.company.accountManagerId)?.fullName ?? 'N/A';
    payload.asicContact = '';

    const ignoredValueSubstitution: string[] = ["directorFirstName", "directorFullName"];
    return {payload, ignoredValueSubstitution};
  }

  prefillAnnualStatementEsignEmailPayload(documentBase: DocumentBase, lodgement: LodgementDeadline): IPrefillResult {
    const payload = new AnnualStatementEsignEmailPayload();

    const emailPayload = this.prefillAnnualStatementEmailPayload(documentBase, lodgement);

    Object.assign(payload, this.omitProperty(emailPayload.payload, '$type'));

    payload.signUrl = '';

    return {payload, ignoredValueSubstitution: emailPayload.ignoredValueSubstitution};
  }

  prefillAnnualStatementEmailPayload(documentBase: DocumentBase, lodgement: LodgementDeadline): IPrefillResult {
    const payload = new AnnualStatementEmailPayload();
    const annualStatement = documentBase as AnnualStatement;
    payload.companyACN = documentBase.company.acn;
    payload.companyName = documentBase.company.name;

    const signatories = documentBase.documentSigning?.signatures[0]
    payload.directorFirstName = signatories?.signatory?.firstName ?? 'N/A';
    payload.directorFullName = signatories?.signatory?.fullName ?? 'N/A';

    payload.year = annualStatement.reviewDate ? formatDate(new Date(annualStatement.reviewDate) ?? '', 'yyyy', 'en-US') : '';
    
    payload.annualReviewFeeDeadline = this.#dateFormatPipe.transform(annualStatement.paymentDeadline, DateFormatType.Long);
    payload.annualReviewFeeDeadlineShortFormat = this.#dateFormatPipe.transform(annualStatement.paymentDeadline, DateFormatType.Short);

    payload.debt = this.#currencyPipe.transform(annualStatement.company.companyDebt?.amountOwing ?? 0, '$') ?? '',
    

    // payload.lateFeeForOneMonth = `${lodgement.lateFee}`;
    // payload.lateFeeForMoreThanOneMonth = `${lodgement.lateFee}`; // TODO get late fee from config???

    payload.organisationName = this.profile!.organisationName ?? 'N/A';
    payload.organisationEmail = this.profile!.organisationEmail ?? 'N/A';
    payload.organisationPhone = this.profile!.organisationPhone ?? 'N/A';
    payload.username = this.#organisationService.getCurrentUser()?.fullName ?? 'N/A';

    payload.billingContactFirstName = documentBase.company.billingContact?.firstName ?? 'N/A';
    payload.billingContactFullName = documentBase.company.billingContact?.fullName ?? 'N/A';

    payload.partner = this.profile!.users?.find(user => user.id === documentBase.company.partnerManagerId)?.fullName ?? 'N/A';
    payload.accountManager = this.profile!.users?.find(user => user.id === documentBase.company.accountManagerId)?.fullName ?? 'N/A';
    payload.asicContact = '' //TODO get from settings, need to refactor asic contact as it is string

    const ignoredValueSubstitution: string[] = ["directorFirstName", "directorFullName"];

    return {payload, ignoredValueSubstitution};
  }

  private omitProperty<T extends object, K extends keyof T>(obj: T, keyToRemove: K): Omit<T, K> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [keyToRemove]: _, ...filteredObj } = obj;
    return filteredObj;
  }
}
