import { EntityChangeData } from "../../../../models/entityChangeData";
import { OfficerRelationshipDetails, Relationship } from "../../../../models/relationship";
import { CompanyChangeOfficerType } from "../../../../models/enums/companyChangeOfficerType";
import { ChangeDictionaryHelper } from "../../../../models/shared/change-dictionary-helper.model";
import { RelationshipType, RelationshipTypeLabels } from "../../../../models/enums/relationshipTypeEnum";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Document } from "../../../../models/document";
import { Company } from "../../../../models/company";
import { OfficerAppointmentComponent } from "../../officer-appointment/officer-appointment.component";
import { CompanyChangeData } from "../../../../models/enums/companyChangeData";
import { SelectOption } from "../../../../models/selectOptions";
import { enumToSelectOptions } from "../../../../functions/enums-to-list-formatter";

export class BulkCompanyChangeOfficer extends EntityChangeData {
  static override readonly $type = 'CompanyChangeOfficer';
  offices: Relationship[];
  companiesList: Partial<Company>[];
  actionType: CompanyChangeOfficerType;
  cessationReason: ReasonOfCessation | null;
  noticeOfResignation: string | null;

  constructor(data: Partial<BulkCompanyChangeOfficer> = {}) {
    super(data);

    this.changeType = 'c:484:b12';

    this.offices = data.offices?.filter(Boolean) ?? [];
    this.companiesList = data.companiesList ?? [];
    this.actionType = data.actionType ?? CompanyChangeOfficerType.Appointment;
    this.cessationReason = data.cessationReason ?? null;
    this.noticeOfResignation = data.noticeOfResignation ?? null;
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();
    const positions = this.offices
      .map((relationship) => RelationshipTypeLabels[relationship.type])
      .join(', ');
    const officerWithRole = (role: RelationshipType) => this.offices.find(relationship => relationship.type === role);
    if (this.actionType === CompanyChangeOfficerType.Removal) {
      // B1 - Resignation
      // Officer Name
      // Position
      // Date of Cessation
      // Reason of Cessation
      // Notice of Resignation (If reason of cessation is Resignation)

      dict
        .add('Name of Officeholder', this.offices[0].individualDataOverride?.fullName.trim() ?? '')
        .add('Position', positions)
        .addDate('Date of Cessation', this.changeDate)
        .add('Reason of Cessation', ReasonOfCessation[this.cessationReason as ReasonOfCessation] ?? '')
        .addIfValueIsNotEmpty('Notice of Resignation', this.noticeOfResignation?.toString()?.trim() ?? '');
    } else if (this.actionType === CompanyChangeOfficerType.Appointment) {
      // B2 - Appointment
      // Officer Name
      // Former name
      // Address
      // Date of birth
      // Place of birth
      // Director ID (if officer is director or alternative director)
      // The appointed “Alternate director” is alternate for (if officer is alternative director)
      // Terms of appointment (if officer is alternative director)
      // Position
      // Date of Appointment

      const alternativeDirectorOfficer = officerWithRole(RelationshipType.AlternativeDirector);
      const directorOfficer = officerWithRole(RelationshipType.Director);

      dict
        .add('Name of Officeholder', this.offices[0].individualDataOverride?.fullName.trim() ?? '')
        .add('Former Name', this.offices[0].individualDataOverride?.formerName.fullName)
        .add('Address', this.offices[0]?.individualDataOverride?.address?.normalizedFullAddress ?? '')
        .addDate('Date of Birth', this.offices[0]?.individualDataOverride?.dob)
        .addCountry('Place of Birth (Country / State)', this.offices[0]?.individualDataOverride?.birthCountry ?? '')
        .add('Place of Birth (Suburb / City)', this.offices[0].individualDataOverride?.birthCity ?? '');


      if (directorOfficer || alternativeDirectorOfficer) {
        dict.addDin('Director ID', (directorOfficer || alternativeDirectorOfficer)?.individualDataOverride?.din?.trim());
      }

      if (alternativeDirectorOfficer) {
        dict
          .add('The appointed "Alternate director" is alternate for', (alternativeDirectorOfficer?.details as OfficerRelationshipDetails)?.alternativeDirectorFor || '')
          .add('Terms of Appointment', (alternativeDirectorOfficer?.details as OfficerRelationshipDetails)?.termsOfAppointment ?? '');
      }

      dict
        .add('Position', positions)
        .addDate('Date of Appointment', this.changeDate);
    }

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    const instance = (modalRef.componentInstance as OfficerAppointmentComponent);
    instance.companyChangeData.documentId = document.documentId;

    this.offices = this.offices.map(officer => {
      officer.key = officer.individualId ?? instance.officersSelectOptions.find(option => option.label === officer?.individualDataOverride?.fullName)?.value as string ?? '';
      return officer;
    });

    return modalRef;
  }

  fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit = false): NgbModalRef {
    const instance = (modalRef.componentInstance as OfficerAppointmentComponent);

    instance.isEdit = isEdit;
    instance.formModel = this;
    instance.companyChangeData = new CompanyChangeData(company);

    // TODO: remove after individualId works fine
    const officerIndividualsIdDictionary: Record<string, string> = {};

    instance.officersSelectOptions = structuredClone(company.officers)
      .reduce((officersSelectOptions: SelectOption[], officer) => {
        if (officer.end) {
          return officersSelectOptions;
        }

        if (officer.individualDataOverride) {
          // TODO: remove after individualId works fine
          if (!officerIndividualsIdDictionary[officer.individualDataOverride.fullName]) {
            officerIndividualsIdDictionary[officer.individualDataOverride.fullName] = Math.random().toString(36).substring(7);
          }

          // TODO: remove after individualId works fine
          officer.key = officer.individualId ?? officerIndividualsIdDictionary[officer.individualDataOverride.fullName];

          const officerOption: SelectOption = {
            label: officer.individualDataOverride.fullName,
            value: officer.key,
          };

          if (!instance.officers[officer.key]) {
            instance.officers[officer.key] = [];

            officersSelectOptions?.push(officerOption);
          }

          if (officer.type === RelationshipType.Director) {
            instance.allDirectorsOptions?.push(officerOption);
          }

          instance.officers[officer.key]?.push(officer);

          return officersSelectOptions;
        } else {
          return [];
        }
      },[])
      .sort((a, b) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase()));

    return modalRef;
  }

  override prepareToRequest(): BulkCompanyChangeOfficer {
    return {
      ...this,
      ...super.prepareToRequest(),
      offices: this.offices.map((officer) => Relationship.prepareToRequest(officer))
    };
  }
}

export enum ReasonOfCessation {
  Resignation = 0,
  Removal = 1,
  Death = 2
}

export const reasonOfCessationOptions: SelectOption[] = enumToSelectOptions(ReasonOfCessation);
