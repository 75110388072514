<app-stepper-form
  header="Issue"
  subheader="Form 484C2"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content full-width" [formGroup]="stepperForm">
    <div class="step-content">
      <app-stepper-form-description
        formKey="484C2"
        [hidden]="currentStep !== StepsEnum.FormDescription"
      ></app-stepper-form-description>
    </div>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.Shareholders"
      [hidden]="currentStep !== StepsEnum.Shareholders"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-date-picker
          label="Date of Issue"
          formControlName="date"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [smallWidth]="true"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <app-share-issue-block
        [formGroup]="shareholdersForm"
        [securityRegistryRecords]="securityRegistryRecords"
        [securityTypes]="securityTypes"
        [companyChangeData]="companyChangeData"
        [authorisedSecurityRegistryRecords]="authorisedSecurityRegistryRecords"
      >
      </app-share-issue-block>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.ShareIssuesAndCertificateDetails"
      [hidden]="currentStep !== StepsEnum.ShareIssuesAndCertificateDetails"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-radio
          label="Consideration Type"
          formControlName="issueForCashConsidarationOnly"
          [options]="considerationTypesOptions"
        ></app-radio>
      </div>

      <div class="form-group" [hidden]="issuesCertificateForm.controls.issueForCashConsidarationOnly.value !== false">
        <app-textarea
          label="Non Cash Consideration Details"
          formControlName="nonCashConsiderationDetails"
        ></app-textarea>
      </div>

      <div class="form-group">
        <app-yes-no-control
          label="Were some or all of the shares issued under a written contract?"
          formControlName="sharesWereWrittenUnderContract"
        ></app-yes-no-control>
      </div>

      <app-disclaimer
        icon="ic-info-circle"
        color="gray"
        [hidden]="issuesCertificateForm.controls.sharesWereWrittenUnderContract.value !== true"
      >
        Proprietary companies must also lodge a <a href="https://download.asic.gov.au/media/1181281/207z_20140701.pdf"
                                                   target="_blank">Form 207Z</a> certifying that all stamp duties have
        been paid. Public companies must also lodge a
        <a href="https://download.asic.gov.au/media/1181281/207z_20140701.pdf" target="_blank">Form 207Z</a> and either
        a <a href="https://download.asic.gov.au/media/1181287/208_20140701.pdf" target="_blank">Form 208</a> or a copy
        of the contract.
      </app-disclaimer>

      <div class="form-group">
        <app-select
          label="Certificate Consolidation"
          formControlName="consolidateCertificate"
          [options]="certificateConsolidationOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      <div class="form-group">
        <input-certificate-number
          label="Certificate Number"
          formControlName="shareCertificateNumber"
          [entityId]="companyChangeData.entityId"
          [transactionsList]="transactionsList"
        ></input-certificate-number>
      </div>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.AuthorisedSignatories"
      [hidden]="currentStep !== StepsEnum.AuthorisedSignatories"
    >
      <app-authorised-signatories-step
        [title]="'Authorised Signatories'"
        [formGroup]="authorisedSignatoriesForm"
        [securityRegistryRecords]="authorisedSecurityRegistryRecords"
      />
    </section>

    <section
      class="step-section w-100"
      [formGroupName]="StepsEnum.Estimate"
      [hidden]="currentStep !== StepsEnum.Estimate"
    >
      <h1 class="h1-heading">Estimated Shareholding after Transaction</h1>

      <app-share-estimate-table
        [isEstimateLoading]="isEstimateLoading"
        [estimatedSecurityRegistryRecords]="estimatedSecurityRegistryRecords"
      ></app-share-estimate-table>
    </section>
  </div>
</app-stepper-form>
