<!--status === null || documentId === null is the same as
 AnnualStatementStatus.Expected || status === AnnualStatementStatus.NotReceived-->

<div class="action-container" *appHasRole="['Admin', 'Manager', 'Preparer']">
  @if(status === null || documentId === null) {
    <i class="icon ic-eye" (click)="view()"></i>
  }

  @if(status === null || documentId === null || (signingStatus !== SigningStatus.ReadyToSend && signingStatus !== SigningStatus.Created)) {
    <i class="icon ic-send disabled"></i>
  } @else {
    <app-tooltip
      tooltipText="View and Send"
      iconClass="icon ic-send"
      [widthAuto]="true"
      (action)="send()"
    ></app-tooltip>
  }

  @if(status !== null && documentId !== null) {
    <app-tooltip
      [iconClass]="'icon ic-edit'"
      [tooltipText]="tooltipMessage"
      [widthAuto]="true"
      (action)="edit()"
    ></app-tooltip>
  }

  @if(annualStatementsService.pageFilter() !== AnnualStatementPageFilter.Expected) {
    <div
      ngbDropdown
      [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']"
      class="dropdown d-inline-flex"
      container="body"
    >
      <i class="icon ic-md ic-three-dots" ngbDropdownToggle></i>

      <div ngbDropdownMenu tooltip = "sdf" class="annual-menu dropdown-menu dropdown-menu-right">
        @for (item of menu; track $index) {
          <div ngbDropdownItem class="dropdown-item" (click)="item.action()">
            <a class="menu-action-button">
              <i class="{{item.iconClass}}"></i>
              <span>{{item.name}}</span>
            </a>
          </div>
        }
      </div>
    </div>
  }
</div>
