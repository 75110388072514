import { ChangeDetectorRef, Component, DestroyRef, EventEmitter, inject, Input, Output, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from "@angular/forms";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { ReusableFormGroupComponent } from "../reusable-form-group/reusable-form-group.component";
import { AddressAutocompleteComponent } from "../../common/address-autocomplete/address-autocomplete.component";
import { AutocompleteComponent } from "../../common/autocomplete/autocomplete.component";
import { InputComponent } from "../../common/input/input.component";
import { SelectComponent } from "../../common/select/select.component";
import { AuxiliaryService } from "../../../../services/auxiliary.service";
import { CountriesService } from "../../../../services/countries.service";
import { GoogleMapsService } from "../../../../services/google-maps.service";
import { Address } from "../../../../models/address";
import { AddressStatus } from "../../../../models/enums/addressEnums";
import { stateOptions } from '../../../constants/au-states.constant';
import { autocompleteServiceToken } from "../../../../services/autocomplete.service";
import { debounceTime, filter, finalize, map, startWith, Subject, switchMap, tap } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ManualAddressService } from "../../../../services/manual-address.service";
import { SelectOption } from "../../../../models/selectOptions";
import { TitleCasePipe } from "@angular/common";
import { SuburbsAutocompleteComponent } from "./suburbs-autocomplete/suburbs-autocomplete.component";

export interface AddressFormGroupControls {
  normalizedFullAddress: FormControl<string | null>;
  careOf: FormControl<string | null>;
  street: FormControl<string | null>;
  line2: FormControl<string | null>;
  suburb: FormControl<string | null>;
  state: FormControl<string | null>;
  postcode: FormControl<string | null>;
  country: FormControl<string | null>;
  gnafID: FormControl<string | null>;
  meshBlock: FormControl<string | null>;
  level: FormControl<string | null>;
  buildingName: FormControl<string | null>;
  region: FormControl<string | null>;
}

export type AddressFormGroup = FormGroup<AddressFormGroupControls>;

@Component({
  selector: 'app-address-form-group',
  standalone: true,
  imports: [
    AddressAutocompleteComponent,
    AutocompleteComponent,
    InputComponent,
    ReactiveFormsModule,
    SelectComponent,
    SuburbsAutocompleteComponent
  ],
  templateUrl: './address-form-group.component.html',
  styleUrl: './address-form-group.component.scss',
  providers: [
    { provide: autocompleteServiceToken, useClass: AuxiliaryService },
    GoogleMapsService,
    ManualAddressService
  ],
})
export class AddressFormGroupComponent extends ReusableFormGroupComponent<AddressFormGroup> {
  private addressService = inject(AuxiliaryService);
  private googleMapsService = inject(GoogleMapsService);
  private countriesService = inject(CountriesService);
  private destroyRef = inject(DestroyRef);
  private cdr = inject(ChangeDetectorRef);
  private manualAddressService = inject(ManualAddressService);

  @Input() useInternationalAddresses = false;
  @Input() label = '';
  @Input() expandedAddressForm = false;
  @Input() addressStatus: AddressStatus | undefined;
  @Input() isDisabled = false;
  @Input() noValidators = false;
  @Input() suggestedAddresses: Address[] = [];
  @Input() careOfAllowed = false;

  @Input('addressFormLabels') set labels(labels: Record<keyof Partial<AddressFormGroupControls>, string>) {
    this.addressFormLabels = Object.assign(this.addressFormLabels, labels);
  }

  @Output() onDataStatusChange = new EventEmitter<AddressStatus | undefined>();

  readonly countryOptions = this.countriesService.getCountries();
  readonly stateOptions = stateOptions;
  readonly AddressStatus = AddressStatus;

  parseTrigger = new Subject<string>();
  isAustraliaSelected = signal(false);
  isFocused = signal(false);
  isValidating = signal(true);
  isSearching = signal(false);
  addressFormLabels: Record<keyof AddressFormGroupControls, string> = {
    normalizedFullAddress: 'Address',
    careOf: 'Care of',
    street: 'Street Address',
    line2: 'Level, Unit or Shop Number',
    suburb: 'Suburb',
    state: 'State',
    postcode: 'Postcode',
    country: 'Country',
    gnafID: 'GNAF ID',
    meshBlock: 'Mesh Block',
    level: 'Level',
    buildingName: 'Building Name',
    region: 'Region'
  };

  override ngOnInit(): void {
    super.ngOnInit();
    this.listenManualAddressFilling();

    this.listenCountryChanges();
    this.isAustraliaSelected.set(CountriesService.isAustralia(this.form.controls.country.value));

    if (this.noValidators)
      this.clearValidators();

    this.listenParsing();
    this.checkValidity();
  }

  switchAddressForm(): void {
    this.expandedAddressForm = !this.expandedAddressForm;

    if (this.expandedAddressForm && new Address(this.form.value as Address).isEmpty(['normalizedFullAddress', 'country'])) {
      this.parseWithNormalisedFullAddress();
    }

    this.checkValidity();
  }

  parseAddress(value: Address | string | null): void {
    this.isFocused.set(false);
    if (value instanceof Address) {
      this.addressStatus = AddressStatus.CHECKED;
      value.suburb = new TitleCasePipe().transform(value.suburb);
      this.form.patchValue(value);
      this.checkValidity();
      return;
    }

    if (!value) {
      if (this.expandedAddressForm && new Address(this.form.value as Address).isEmpty(['normalizedFullAddress', 'country'])) {
        this.parseWithNormalisedFullAddress();
      }
      return;
    }

    this.addressService.getParseAddress(this.form.controls.normalizedFullAddress?.value as string)
      .pipe(
        filter(normalizedFullAddress => !!normalizedFullAddress),
        finalize(() => this.setIsValidating(false)),
        map(({ address }) => ({
          ...address,
          country: CountriesService.isAustralia(address.country) ? 'Australia' : address.country,
          careOf: this.form.controls.careOf.value
        }))
      )
      .subscribe(parsedAddress => {
        if (parsedAddress === null) {
          this.addressStatus = AddressStatus.WARNING;
          this.parseWithNormalisedFullAddress();
        } else {
          this.addressStatus = AddressStatus.CHECKED;
          parsedAddress.suburb = new TitleCasePipe().transform(parsedAddress.suburb);
          this.form.patchValue(parsedAddress);
          this.setIsValidating(false);
        }
      });
  }

  textChange(searchText: string): void {
    if (!searchText) {
      const address = new Address();
      this.form.patchValue({ ...address, country: this.form.controls.country.value });
    }
  }

  dataStatusChange(addressStatus: AddressStatus | undefined): void {
    this.addressStatus = addressStatus;
    this.onDataStatusChange.emit(addressStatus);
  }

  selectSuburb(selectSuburbEvent: { value: SelectOption, index: number }): void {
    if (selectSuburbEvent.value.value === -1 || !selectSuburbEvent.value || (!this.isAustraliaSelected() && this.useInternationalAddresses))
      return;

    const data = this.manualAddressService.getAddressPartByIndex(selectSuburbEvent.value.value as number);
    this.form.controls.postcode.setValue(data.postcode.toString());
    this.form.controls.state.setValue(data.state);
  }

  onTypingAddress(value: any): void {
    this.form.controls.normalizedFullAddress.setValue(value ?? null);
    this.isFocused.set(true);

    if (!value) {
      this.form.controls.postcode.reset();
      this.form.controls.line2.reset();
      this.form.controls.street.reset();
      this.form.controls.state.setValue('');
      this.form.controls.suburb.setValue(null);
      this.form.controls.gnafID.reset();
      this.form.controls.meshBlock.reset();
      this.form.controls.careOf.reset();
    }
  }

  onBlurAddressSearch(): void {
    if (this.isFocused()) {
      this.parseTrigger.next(this.form.controls.normalizedFullAddress.value ?? '');
    }
    this.isFocused.set(false);
  }

  onFocusSearchControl(): void {
    this.isFocused.set(true);
  }

  setIsSearching(isSearching: boolean): void {
    this.isSearching.set(isSearching);
    this.form.controls.normalizedFullAddress.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  parseWithNormalisedFullAddress(): void {
    if (CountriesService.isAustralia(this.form.controls.country.value)) {
      this.parseTrigger.next(this.form.controls.normalizedFullAddress.value ?? '');
    }
  }

  private setAustraliaFormValidators(): void {
    if (this.noValidators)
      return;
    this.form.controls.normalizedFullAddress.setValidators([Validators.required, this.isValidatingValidator(), this.isSearchingValidator()]);
    this.form.controls.street.setValidators([Validators.required, CustomFormValidators.streetValidator]);
    this.form.controls.suburb.setValidators([Validators.required, CustomFormValidators.suburbValidator]);
    this.form.controls.state.setValidators([Validators.required]);
    this.form.controls.postcode.setValidators([Validators.required, CustomFormValidators.postCodeValidator]);
    this.form.controls.country.setValidators([Validators.required]);
    this.form.controls.state.updateValueAndValidity();
  }

  private setInternationalFormValidators(): void {
    this.form.controls.normalizedFullAddress.clearValidators();
    this.form.controls.street.clearValidators();
    this.form.controls.suburb.clearValidators();
    this.form.controls.state.clearValidators();
    this.form.controls.state.updateValueAndValidity();
    if (this.noValidators)
      return;
    this.form.controls.postcode.setValidators([Validators.maxLength(10)]);
  }

  private listenCountryChanges(): void {
    this.form.controls.country.valueChanges
      .pipe(
        startWith(this.form.controls.country.value),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((country) => {
        this.isAustraliaSelected.set(CountriesService.isAustralia(country));

        if (this.isAustraliaSelected()) {
          this.setAustraliaFormValidators();
        } else {
          this.setInternationalFormValidators();
        }

        this.form.updateValueAndValidity();
        this.cdr.detectChanges();
      });
  }

  private clearValidators(): void {
    const addressFormGroupControls = this.form.controls;
    Object.keys(addressFormGroupControls)
      .forEach((key) => (addressFormGroupControls[key] as FormControl).clearValidators());
    this.form.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  private listenManualAddressFilling(): void {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((form) => {
        if (!this.expandedAddressForm)
          return;

        this.form.controls.normalizedFullAddress.setValue(Address.buildNormalizedFullAddress(form as Address), { emitEvent: false });
      });
  }

  private listenParsing(): void {
    this.parseTrigger
      .pipe(
        filter(normalizedFullAddress => normalizedFullAddress.length >= 4 && this.isAustraliaSelected()),
        tap(() => this.setIsValidating()),
        debounceTime(500),
        switchMap((normalizedFullAddress) =>
          this.addressService.getParseAddress(normalizedFullAddress)
            .pipe(finalize(() => this.setIsValidating(false))))
      )
      .subscribe(({ address, valid }) => {
        this.form.patchValue(address);
        this.addressStatus = valid ? AddressStatus.CHECKED : AddressStatus.WARNING;
      });
  }

  private checkValidity(): void {
    this.setIsValidating();
    const normalizedFullAddress = this.form.controls.normalizedFullAddress.value ?? '';
    if (this.isAustraliaSelected() && normalizedFullAddress) {
      this.addressService.getParseAddress(normalizedFullAddress)
        .pipe(finalize(() => this.setIsValidating(false)))
        .subscribe(({ valid }) => this.addressStatus = valid ? AddressStatus.CHECKED : AddressStatus.WARNING);
      return;
    }
    this.setIsValidating(false);
  }

  private isValidatingValidator(): ValidatorFn {
    return (() => {
      if (this.isDisabled) return null;
      return this.isValidating()
        ? { isValidating: true }
        : null;
    }).bind(this);
  }

  private isSearchingValidator(): ValidatorFn {
    return (() => {
      if (this.isDisabled) return null;
      return this.isSearching()
        ? { isSearching: true }
        : null;
    }).bind(this);
  }

  private setIsValidating(isValidating = true): void {
    this.isValidating.set(isValidating);
    this.form.controls.normalizedFullAddress.updateValueAndValidity();
  }

  static override defineForm(): AddressFormGroup {
    return new FormGroup<AddressFormGroupControls>(AddressFormGroupComponent.defineFormControls());
  }

  static override defineFormControls(): AddressFormGroupControls {
    return {
      normalizedFullAddress: new FormControl<string | null>('', [Validators.required]),
      careOf: new FormControl<string | null>(''),
      street: new FormControl<string | null>('', [Validators.required, CustomFormValidators.streetValidator]),
      line2: new FormControl<string | null>(''),
      suburb: new FormControl<string | null>('', [Validators.required, CustomFormValidators.suburbValidator]),
      state: new FormControl<string | null>('', Validators.required),
      postcode: new FormControl<string | null>('', [Validators.required, CustomFormValidators.postCodeValidator]),
      country: new FormControl<string | null>('Australia', Validators.required), 
      gnafID: new FormControl<string | null>(''),
      meshBlock: new FormControl<string | null>(''),
      level: new FormControl<string | null>(''),
      buildingName: new FormControl<string | null>(''),
      region: new FormControl<string | null>(''),
    };
  }
}
