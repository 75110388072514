import { Component, inject, OnInit } from '@angular/core';
import { catchError, forkJoin, Observable, tap, throwError } from 'rxjs';
import { UserPermissionsFilterOptions, UserRecord, UserStatus } from '../../../../models/userRecord';
import { UsersService } from '../../../../services/users.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../../../modals/confirm/confirm.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { GridApi } from "ag-grid-enterprise";
import {
  AccountManagementGridActions
} from "../../../components/common/grid/components/grid-actions/account-management-grid-actions";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FilterOption } from "../../../../models/gridFilter";
import { AgUserRolesComponent } from "./components/ag-user-permissions/ag-user-roles.component";
import { CellDataType } from "../../../../models/enums/agGridEnums";
import {
  AgCompanyRegistrationDateComponent
} from "../../../components/common/grid/components/ag-company-registration-date/ag-company-registration-date.component";
import { ColumnWithExportName } from "../../../../models/columnWithExportName";
import { AgUserStatusComponent } from "./components/ag-user-status/ag-user-status.component";
import { convertEnumToFilterOption } from "../../../helpers/enum-to-filter-option.helper";
import { CreateEditUserModalComponent } from "./components/create-edit-user-modal/create-edit-user-modal.component";
import { AuthService } from "../../../../services/auth.service";
import { PageTitleComponent } from "../../../components/common/page-title/page-title.component";
import { ButtonComponent } from "../../../components/common/button/button.component";
import { GridComponent } from "../../../components/common/grid/components/grid/grid.component";
import { HasRoleDirective } from '../../../../directives/has-role.directive';
import { UserInvitationsService } from '../../../../services/user-invitations.service';
import { UserInvitationRecord } from '../../../../models/userInvitationRecord';
import { AgPhoneNumberComponent } from '../../../components/common/grid/components/ag-phone-number/ag-phone-number.component';

@Component({
  selector: 'app-accounts',
  standalone: true,
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss', '../../settings-styles.scss'],
  imports: [
    PageTitleComponent,
    ButtonComponent,
    GridComponent,
    HasRoleDirective
  ]
})
export class AccountsComponent implements OnInit {
  private authService = inject(AuthService)
  private usersService = inject(UsersService);
  private userInvitationsService = inject(UserInvitationsService);
  private modalService = inject(NgbModal);
  private toastr = inject(ToastrService);
  private fb: FormBuilder = inject(FormBuilder);

  readonly defaultPageSize = 20;
  form!: FormGroup;
  gridApi!: GridApi;
  colDefs: ColumnWithExportName[] = [];
  users: (UserRecord | UserInvitationRecord)[] = [];
  checkedUsers: UserRecord[] = [];
  rowSelection: 'single' | 'multiple' = 'multiple';
  loading = false;
  createEditUserModalOpened = false;

  permissionFilterOptions: FilterOption[] = [];
  statusFilterOptions: FilterOption[] = [];
  dateCreatedFilterOptions: FilterOption[] = [];
  twoFaFilterOptions: FilterOption[] = [];
  lastLoginFilterOptions: FilterOption[] = [];

  ngOnInit(): void {
    this.form = this.fb.group({
      search: [''],
    });

    this.loadUsers().subscribe();
  }

  loadUsers(): Observable<{ users: UserRecord[], invitations: UserInvitationRecord[] }> {
    this.loading = true;
  
    return forkJoin({
      users: this.usersService.getUsers(),
      invitations: this.userInvitationsService.getUserInvitations()
    }).pipe(
      tap(({ users, invitations }) => {
        this.users = [...invitations, ...users];
  
        this.setIndividualFilterOptions(users);
        this.setGridConfig();
        this.loading = false;
      })
    );
  }

  setGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'User Name',
        field: 'fullName',
        flex: 2,
        editable: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerName: 'Email',
        field: 'email',
        flex: 2,
      },
      {
        headerName: 'Mobile Number',
        field: 'phoneNumber',
        cellRenderer: AgPhoneNumberComponent,
        flex: 2,
      },
      {
        headerName: 'Access Permissions',
        field: 'roles',
        flex: 1,
        cellRenderer: AgUserRolesComponent,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.permissionFilterOptions
        },
      },
      {
        headerName: 'Last Login',
        field: 'lastLogin',
        cellRenderer: AgCompanyRegistrationDateComponent,
        cellDataType: CellDataType.DATE_STRING,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.lastLoginFilterOptions
        },
        hide: true,
      },
      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: AgUserStatusComponent,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: convertEnumToFilterOption(UserStatus)
        },
        hide: true,
      },
      {
        headerName: '2FA Method',
        field: 'twoFa',
        valueGetter: () => '2FA Method',
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.twoFaFilterOptions
        },
        hide: true,
      },
      {
        headerName: 'Date Created',
        field: 'dateCreated',
        cellRenderer: AgCompanyRegistrationDateComponent,
        cellDataType: CellDataType.DATE_STRING,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.dateCreatedFilterOptions
        },
        hide: true,
      },
      {
        width: 150,
        minWidth: 150,
        sortable: false,
        cellRenderer: AccountManagementGridActions,
        cellClass: 'actions-button-cell',
        cellRendererParams: {
          edit: this.openCreateEditUserModal.bind(this),
          delete: this.deleteRecord.bind(this),
          resetMFA: this.resetMFA.bind(this),
          resetPassword: this.resetPassword.bind(this)
        },
        suppressHeaderMenuButton: true,
      },
    ];
  }

  openCreateEditUserModal(user: UserRecord | null = null): void {
    if (this.createEditUserModalOpened) return;
    this.createEditUserModalOpened = true;
    const modalRef = this.modalService.open(CreateEditUserModalComponent, { size: 'lg' });
    const instance = modalRef.componentInstance as CreateEditUserModalComponent;

    if (user) {
      instance.user = new UserRecord(structuredClone(user));
    } else {
      instance.usersEmails = this.users.map((user) => user.email);
    }

    modalRef.result.then(
      () => {
        this.loadUsers().subscribe();
        this.createEditUserModalOpened = false;
      },
      () => this.createEditUserModalOpened = false
    );
  }

  deleteRecord(data: UserRecord | UserInvitationRecord): void {
    if ('userInvitationId' in data) {
      this.deleteInvitation(data as UserInvitationRecord);
    } else {
      this.deleteUser(data as UserRecord);
    }
  }

  deleteInvitation(invitation: UserInvitationRecord): void {
    const modalRef = this.modalService.open(ConfirmComponent);
    const instance = modalRef.componentInstance as ConfirmComponent;
    instance.title = 'Delete invitation';
    instance.message = 'User will not be able to join using invitation link. Are you sure you want to proceed?';
    instance.icon = 'ic-trash';
    instance.confirmText = 'Yes';
    instance.closeText = 'No';

    instance.confirm = () => this.userInvitationsService.deleteInvitation(invitation.userInvitationId)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.toastr.error("Error while deleting user", "Error");
        return throwError(() => error);
      }));

    modalRef.result.then(
      () => {
        this.toastr.success('Invitation deleted', 'Success');
        this.loadUsers().subscribe();
      },
      () => {
      }
    );
  }

  deleteUser(user: UserRecord): void {
    const modalRef = this.modalService.open(ConfirmComponent);
    const instance = modalRef.componentInstance as ConfirmComponent;
    instance.title = 'Delete account';
    instance.message = 'Deleting the user will block the user\'s access to the portal. Are you sure you want to proceed?';
    instance.icon = 'ic-trash';
    instance.confirmText = 'Yes';
    instance.closeText = 'No';

    instance.confirm = () => this.usersService.deleteUser(user.externalId)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.toastr.error("Error while deleting user", "Error");
        return throwError(() => error);
      }));

    modalRef.result.then(
      () => {
        this.toastr.success('Account deleted', 'Success');

        if (user.externalId === this.authService.currentUserProfile()?.externalUserId) {
          this.authService.logOut();
          return
        }

        this.loadUsers().subscribe();
      },
      () => {
      }
    );
  }

  resetMFA(user: UserRecord): void {
    const modalRef = this.modalService.open(ConfirmComponent);
    const instance = modalRef.componentInstance as ConfirmComponent;
    instance.title = 'Reset MFA';
    instance.message = 'An email will be sent to the user for MFA reset. Do you want to proceed?';
    instance.icon = 'ic-lock';
    instance.confirmText = 'Yes';
    instance.closeText = 'No';

    // TODO: implement the request calling when BE done with it

    modalRef.result.then(
      () => {
        // this.toastr.success('MFA reset', 'Success');
        this.loadUsers().subscribe();
      },
      () => {
      }
    );
  }

  resetPassword(user: UserRecord): void {
    const modalRef = this.modalService.open(ConfirmComponent);
    const instance = modalRef.componentInstance as ConfirmComponent;
    instance.title = 'Reset Password';
    instance.message = 'An email will be sent to the user to reset the login password. Do you want to proceed?';
    instance.icon = 'ic-lock';
    instance.confirmText = 'Yes';
    instance.closeText = 'No';

    instance.confirm =
      () => this.usersService.triggerPasswordReset(user.externalId)
        .pipe(catchError((error: HttpErrorResponse) => {
          this.toastr.error('Error while initiating password reset!', 'Error');
          return throwError(() => error);
        }));

    modalRef.result.then(
      () => {
        this.toastr.success('Password reset', 'Success');
        this.loadUsers().subscribe();
      },
      () => {
      }
    );
  }

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
  }

  selectUsers(users: UserRecord[]): void {
    this.checkedUsers = users;
  }

  setIndividualFilterOptions(users: UserRecord[]): void {
    // Permission (Admin, Manager, Preparer, User)
    // Status
    // Date Created
    // Last Login
    // 2FA Method

    this.permissionFilterOptions = UserPermissionsFilterOptions;
    this.statusFilterOptions = convertEnumToFilterOption(UserStatus);
    // TODO: uncomment when UserRecord model updated with dateCreated and lastLogin props
    // this.dateCreatedFilterOptions = users.map(user => {
    //   return {
    //     label: user.dateCreated ? formatDate(user.dateCreated ?? '', 'dd MMM yyyy', 'en') : '',
    //     value: user.dateCreated ?? '',
    //     active: false
    //   };
    // });
    // this.twoFaFilterOptions = [];
    // this.lastLoginFilterOptions = users.map(user => {
    //   return {
    //     label: user.lastLogin ? formatDate(user.lastLogin ?? '', 'dd MMM yyyy', 'en') : '',
    //     value: user.lastLogin ?? '',
    //     active: false
    //   };
    // });
  }
}
