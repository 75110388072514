export enum AnnualStatementPageFilter {
  Burning,
  ReadyToSend,
  NotReceived,
  Expected,
  Sent,
  PaymentDue,
  Overdue,
  Archived,
  All
}

export enum AnnualStatementStatus {
  ReadyToPrepare,
  ReadyToSend,
  Sent,
  Signed,
  Finalized,
  Archived,
  Ignored
}

export enum SigningStatus {
  ReadyToSend,
  Created,
  Sent,
  Signed,
  Rejected
}

export enum AnnualStep {
  Authorization = 0,
  GenerateDocuments = 1,
  SignDocuments = 2
}

export enum SignGroup {
  Approver = 1,
  Signatories = 2,
  CarbonCopy = 3
}

export enum SendOption {
  Sign = 'SIGN',
  Email = 'Email',
  Paper = 'Paper',
  Sms = 'SMS'
}
