import { Component } from '@angular/core';
import { ButtonComponent } from "../../../../button/button.component";
import { DividerComponent } from "../../../../divider/divider.component";
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams} from "ag-grid-community";
import { ExportTypeEnum } from "../../../../../../../models/enums/exportTypeEnum";
import { ListGridComponent } from "../../list-grid/list-grid.component";
import { Relationship } from "../../../../../../../models/relationship";
import { HasRoleDirective } from '../../../../../../../directives/has-role.directive';

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<Relationship> & {
  exportShares: ActionEvent,
  resolveIssue: ActionEvent,
  issueTransaction: ActionEvent,
  transferTransaction: ActionEvent,
  cancelTransaction: ActionEvent,
  subdivisionOrConsolidationTransaction: ActionEvent,
  conversionTransaction: ActionEvent,
  changeAmountPaidTransaction: ActionEvent,
  addHistoryTransaction: ActionEvent,
  hasIssue: boolean
};

@Component({
  selector: 'app-ag-share-group-buttons',
  standalone: true,
  imports: [
    ButtonComponent,
    DividerComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    ListGridComponent,
    HasRoleDirective
  ],
  templateUrl: './ag-share-group-buttons.component.html',
  styleUrl: './ag-share-group-buttons.component.scss'
})
export class AgShareGroupButtonsComponent implements ICellRendererAngularComp {
  public addTransactionMenu: { name: string; action: () => void }[] = [
    { name: 'Issue', action: () => this.issueTransaction() },
    { name: 'Transfer', action: () => this.transferTransaction() },
    { name: 'Cancellation', action: () => this.cancelTransaction() },
    { name: 'Subdivision or Consolidation', action: () => this.subdivisionOrConsolidationTransaction() },
    { name: 'Conversion', action: () => this.conversionTransaction() },
    { name: 'Change Amount Paid', action: () => this.changeAmountPaidTransaction() },
    { name: 'Add Historical Transaction', action: () => this.addHistoryTransaction() },
  ];

  params!: CellRendererParams;
  hasIssue = false;
  checkedShares = [];

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.hasIssue = params.hasIssue;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return false;
  }

  exportSharesList(status: boolean, exportType: ExportTypeEnum): void {
    this.params.exportShares(exportType);
  }

  resolveIssue(): void {
    this.params.resolveIssue(true);
  }

  issueTransaction(): void {
    this.params.issueTransaction(true);
  }

  transferTransaction(): void {
    this.params.transferTransaction(true);
  }

  cancelTransaction(): void {
    this.params.cancelTransaction(true);
  }

  subdivisionOrConsolidationTransaction(): void {
    this.params.subdivisionOrConsolidationTransaction(true);
  }

  conversionTransaction(): void {
    this.params.conversionTransaction(true);
  }

  changeAmountPaidTransaction(): void {
    this.params.changeAmountPaidTransaction(true);
  }

  addHistoryTransaction(): void {
    this.params.addHistoryTransaction(true);
  }

  protected readonly ExportTypeEnum = ExportTypeEnum;
}
