import { EntityChangeData } from "../../../../models/entityChangeData";
import { Address } from "../../../../models/address";
import { Guid } from "../../../helpers/guid.helper";
import { ChangeDictionaryHelper } from "../../../../models/shared/change-dictionary-helper.model";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Company } from "../../../../models/company";
import { Document } from '../../../../models/document';
import { BulkChangeAddressFormComponent } from "./bulk-change-address-form.component";
import { SelectOption } from "../../../../models/selectOptions";
import { Relationship } from "../../../../models/relationship";
import { RelationshipType } from "../../../../models/enums/relationshipTypeEnum";
import { PascalCaseToText } from "../../../../functions/enums-to-list-formatter";
import { CompanyChangeData } from "../../../../models/enums/companyChangeData";
import { DocumentationType } from "../../../../models/enums/documentConfirmEnums";
import { MatchesSelected } from "../../../../models/bulkChangeData";

export class BulkCompanyChangeAddress extends EntityChangeData {
  static override readonly $type = "CompanyChangeAddress";
  address: Address;
  applyToRegistered: boolean;
  applyToPrincipal: boolean;
  relationshipIds: string[];
  relationships?: Relationship[];
  occupiesTheAddress: boolean;
  occupierNameIfDoesntOccupy: string;
  authorisedPerson: string; // when ind controller will be implemented, this will be changed to ind
  documentationType: DocumentationType;
  bulkMatchesSelected: MatchesSelected[];
  trustOnly: boolean;
  isIndividual?: boolean;
  individualAddress?: string;

  constructor(data: Partial<BulkCompanyChangeAddress> = {}) {
    super(data);

    this.address = data.address ?? new Address();
    this.applyToRegistered = data.applyToRegistered ?? false;
    this.applyToPrincipal = data.applyToPrincipal ?? false;
    this.occupiesTheAddress = data.isIndividual ? false : (data.occupiesTheAddress ?? true);
    this.relationshipIds = data.relationshipIds?.filter(id => Boolean(id) && id !== Guid.EmptyGuid) ?? [];
    this.relationships = data.relationships ?? [];
    this.occupierNameIfDoesntOccupy = data.occupierNameIfDoesntOccupy ?? '';
    this.authorisedPerson = data.authorisedPerson ?? '';
    this.documentationType = data.documentationType ?? DocumentationType.Default;
    this.bulkMatchesSelected = data.bulkMatchesSelected ?? [];
    this.trustOnly = data.trustOnly ?? false;
    this.isIndividual = data?.isIndividual ?? false;
    this.individualAddress = data?.individualAddress ?? '';
  }

  override toDictionary(): { key: string, value: string }[] {
    if(this.isIndividual) {
      this.occupiesTheAddress = false;
    }

    const dict = new ChangeDictionaryHelper();

    dict
      .add('New Address', this.address.normalizedFullAddress)
      .addYesNo('Registered Office Address', this.applyToRegistered)
      .addYesNo('Principal Place of Business', this.applyToPrincipal);

    if(!this.isIndividual) {
      dict.addYesNo('Company occupies the premises', this.occupiesTheAddress);
    }

    if (!this.occupiesTheAddress) {
      dict
        .add('Name of Occupier', this.occupierNameIfDoesntOccupy)
        .addYesNo('Does the company have occupiers consent?', !!this.occupierNameIfDoesntOccupy);
    }

    if (this.relationshipIds.length) {
      dict.addIds('Address Apply To:', this.relationshipIds ?? []);
    }

    dict.addDate('Change of Date', this.changeDate);

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as BulkChangeAddressFormComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit = false): NgbModalRef {
    const instance = modalRef.componentInstance as BulkChangeAddressFormComponent;

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = structuredClone(this);

    // TODO: remove after individualId works fine
    const officerIndividualsIdDictionary: Record<string, string> = {};

    const nonCorporateSecurityHolders = company.securityholders.filter((securityholder) => securityholder?.individualDataOverride);

    instance.officersSelectOptions = structuredClone([...company.officers.filter(o => !o.end), ...nonCorporateSecurityHolders])
      .reduce((officersSelectOptions: SelectOption[], officer) => {
          // TODO: remove after individualId works fine
          if (!(officer.individualDataOverride!.fullName in officerIndividualsIdDictionary)) {
            officerIndividualsIdDictionary[officer.individualDataOverride!.fullName] = Math.random().toString(36).substring(7);
          }

          // TODO: remove after individualId works fine
          officer.key = officer.individualId ?? officerIndividualsIdDictionary[officer.individualDataOverride!.fullName];

          const officerOption: SelectOption = {
            label: officer.individualDataOverride!.fullName,
            value: officer.key,
          };

          if (!(officer.key in instance.officers)) {
            instance.officers[officer.key] = [];

            officersSelectOptions?.push(officerOption);
          }

          instance.officers[officer.key]?.push(officer);

          return officersSelectOptions;
        },
        []
      )
      .sort((a, b) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase()));

    instance.officersSelectOptions = instance.officersSelectOptions.map((officerOption) => {
      const officerTypes = (instance.officers[officerOption.value as string] as Relationship[])
        .reduce((types: string[], officer) => {
          const type = officer.type === RelationshipType.Securityholder
            ? 'Shareholder'
            : PascalCaseToText(RelationshipType[officer.type]);

          if (!types.includes(type)) {
            types.push(type);
          }

          return types;
        }, []);

      return {
        label: `${ officerOption.label } (${ officerTypes.join(', ') })`,
        value: officerOption.value,
      };
    });

    return modalRef;
  }
}

export interface ChangeAddressCompanyList {
  entityId: string,
  entityNumber: string,
  name: string,
  dateOfEstablishment: Date | undefined
}

export const customForm484A1Errors = {
  required: 'You have to pick one of the options above.',
  foreignAddressForCompany: `Changing the company's address to a foreign address is not allowed`,
  foreignAddressForAllDirectors: `It's now allowed to change address of all directors to a foreign address.`
}
