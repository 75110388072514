import { EmailTemplate } from "./emailTemplate";
import { EmailPayloadBase } from "./emailPayloadBase";
import { OrganisationBrandModel } from "../OrganisationBrandModel";

export class EmailPreviewRequest {
  payloadModel: EmailPayloadBase;
  template: EmailTemplate;
  logo: any | null;
  brand: OrganisationBrandModel | null;

  constructor(data: Partial<EmailPreviewRequest> = {}) {
    this.payloadModel = data.payloadModel!;
    this.template = data.template!;
    this.logo = data.logo ?? null;
    this.brand = data.brand ?? null;
  }
}

export class EmailPreviewContentRequest {
  payloadModel: EmailPayloadBase;
  template: EmailTemplate;
  ignoreValueSubstitution?: string[];

  constructor(data: Partial<EmailPreviewContentRequest> = {}) {
    this.payloadModel = data.payloadModel!;
    this.template = data.template!;
    this.ignoreValueSubstitution = data.ignoreValueSubstitution ?? [];
  }
}
