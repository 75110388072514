<div [formGroup]="formGroup">
  <app-select-shareholder
    label="Current Shareholders"
    formControlName="securityRegistryRecordGroup"
    [groupedSecurityRegistryRecords]="groupedSecurityRegistryRecords"
  ></app-select-shareholder>

  @if (formGroup.value.securityRegistryRecordGroup && selectedSecurityGroupHoldersGroupedByName) {
    @for (group of selectedSecurityGroupHoldersGroupedByName; track group) {
      @if (!group.value[0].details.isBeneficialOwner) {
        <div class="form-group">
          <app-beneficial-owner
            [controlLike]="true"
            [details]="group.value[0].details"
            [relationshipIds]="group.relationshipIds"
            [showOpenIcon]="false"
            (onDataSaved)="signDataSaved()">
          </app-beneficial-owner>
        </div>
      }
    }
  }

  <div class="form-group" [hidden]="beneficialOwnersSignsErrorMessageHidden">
    <app-validation-error
      [control]="formGroup"
      [customErrors]="customSignErrors"
    ></app-validation-error>
  </div>
</div>
