import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { CheckboxComponent } from "../../../components/common/checkbox/checkbox.component";
import { DatePickerComponent } from "../../../components/common/date-picker/date-picker.component";
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { SelectComponent } from "../../../components/common/select/select.component";
import { StepperFormComponent } from "../../stepper-form/stepper-form.component";
import {
    StepperFormDescriptionComponent
} from "../../stepper-form/stepper-form-description/stepper-form-description.component";
import { TextareaComponent } from "../../../components/common/textarea/textarea.component";
import { ValidationErrorComponent } from "../../../components/common/validation-error/validation-error.component";
import { Relationship } from "../../../../models/relationship";
import { SelectOption } from "../../../../models/selectOptions";
import {
  DatePickerFormValidators,
  DatepickerHelper
} from "../../../../custom-form-validators/date-picker-form-validators";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import {
  ReasonOfCessation,
  reasonOfCessationOptions
} from "../../../../models/сompanyChangeOfficer";
import { EntityChangeData } from "../../../../models/entityChangeData";
import { clearFormArray } from "../../../../functions/clear-form-array";
import { Document } from "../../../../models/document";
import { CompanyChangeOfficerType } from "../../../../models/enums/companyChangeOfficerType";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { RelationshipType } from "../../../../models/enums/relationshipTypeEnum";
import {catchError, combineLatest, forkJoin, map, of, startWith} from "rxjs";
import { setControlDisabled } from "../../../../functions/set-control-disabled";
import { OfficerAppointmentTypeLabels } from "../../../../models/officerAppointmentForm";
import { ACNPipe } from "../../../../pipes/acnPipe";
import { BaseBulkStepperFormComponent } from "../../stepper-form/base-bulk-stepper-form/base-bulk-stepper-form.component";
import { BulkCompanyChangeOfficer } from "../bulk-appointment-officeholder/BulkCompanyChangeOfficer.model";
import { RadioComponent } from "../../../components/common/radio/radio.component";
import {
  DocumentationType,
  MeetingLocationType,
  MinutesMeetingPresence
} from "../../../../models/enums/documentConfirmEnums";
import { CompaniesService } from "../../../../services/companies.service";
import { ColDef, RowClassRules } from "ag-grid-community";
import {
  AgEntityNameAcnAbnComponent
} from "../../../components/common/grid/components/ag-entity-name-acn-abn/ag-entity-name-acn-abn.component";
import { OfficeHoldersByCompany } from "../../../../models/bulkChangeData";
import { Company } from "../../../../models/company";
import { NgClass } from "@angular/common";
import { ChangeAuthorisation, MinutesMeetingAttendee } from "../../../../models/changeAuthorisation";
import { ListGridComponent } from "../../../components/common/grid/components/list-grid/list-grid.component";
import { SplitByCapitalLetterPipePipe } from "../../../../pipes/split-by-capital-letter-pipe.pipe";
import { BulkAddressChangeStepsEnum } from "../../../../models/enums/bulkAddressChangeStepsEnum";
import {
  AgSelectChangeNameComponent
} from "../../../components/common/grid/components/ag-select-change-name/ag-select-change-name.component";
import { CompanyProfileService } from "../../../company-profile/company-profile.service";

enum BulkCeaseCompanyOfficeholderStepsEnum {
  FormDescription = 0,
  CeaseCompanyOfficeholder = 1,
  Summary = 2,
}

interface AppliedChanges {
  name: string,
  acn: string,
  fullName: string,
  relationshipId: string,
  entityId: string;
  selectedOption: SelectOption;
  options: SelectOption[];
  officeHolders: Relationship[]
  error: string,
}

@Component({
  selector: 'app-bulk-cease-officeholder',
  standalone: true,
  imports: [
    CheckboxComponent,
    DatePickerComponent,
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    TextareaComponent,
    ValidationErrorComponent,
    RadioComponent,
    NgClass,
    ListGridComponent
  ],
  providers: [SplitByCapitalLetterPipePipe],
  templateUrl: './bulk-cease-officeholder.component.html',
  styleUrls: ['./bulk-cease-officeholder.component.scss', '../../stepper-form/base-bulk-stepper-form/base-bulk-stepper-form.component.scss']
})
export class BulkCeaseOfficeholderComponent extends BaseBulkStepperFormComponent<BulkCeaseCompanyOfficeholderStepsEnum, BulkCompanyChangeOfficer> implements OnInit {
  cdr = inject(ChangeDetectorRef);
  companiesService = inject(CompaniesService);
  companyProfileService = inject(CompanyProfileService);
  splitByCapitalLetterPipePipe = inject(SplitByCapitalLetterPipePipe);

  @Input() officers: Record<string, Relationship[]> = {};
  @Input() officersSelectOptions: SelectOption[] = [];

  readonly customDatepickerErrors = DatePickerFormValidators.errorMessagesWithDateOfEstablishmentErrorMessages;
  readonly reasonOfCessationOptions = reasonOfCessationOptions;
  override readonly StepsEnum = BulkCeaseCompanyOfficeholderStepsEnum;
  officerPositionTypesOptions: SelectOption[] = [];

  documentTypeOptions: SelectOption[] = [
    { label: 'Minutes', value: DocumentationType.Minutes, disabled: true },
    { label: 'Resolution', value: DocumentationType.Resolution, disabled: true },
    { label: 'ASIC Form only', value: DocumentationType.FormOnly, disabled: false },
  ];

  colDefs: ColDef[] = [
    {
      headerName: 'Entity Name',
      field: 'name',
      sort: 'asc',
      flex: 0.6,
      cellRenderer: AgEntityNameAcnAbnComponent,
      menuTabs: []
    },
    {
      headerName: 'Authorising Officeholder',
      field: 'fullName',
      flex: 1,
      cellRenderer: AgSelectChangeNameComponent,
      cellRendererParams: {
        selectName: this.selectName.bind(this),
      },
      menuTabs: []
    },
  ];
  private readonly colDefsWithErrorLength = 3;

  public rowClassRules: RowClassRules = {
    'error-row-bg': (params: { data: { error: string }}) => !!params.data.error,
  };

  companiesList: Partial<Company>[] = []
  rows: AppliedChanges[] = [];
  officeHoldersList: Relationship[] = [];
  uniqueOfficeHoldersList: Relationship[] = [];
  officeHoldersByCompany: OfficeHoldersByCompany[] = [];
  changeAuthorisationByCompany: { companyId: string, changeAuthorisation: ChangeAuthorisation }[] = [];
  selectedOfficeholder: Relationship | undefined;
  rowHasError = false;

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  form = new FormGroup({
    changeDate: new FormControl<Date | null>(null),
    appointedOfficerId: new FormControl<string | null>(null, [Validators.required]),
    types: new FormArray<FormControl<boolean>>([]),
    cessationReason: new FormControl<ReasonOfCessation | null>(null, [Validators.required]),
    noticeOfResignation: new FormControl<string | null>(null, [Validators.required]),
    position: new FormControl<number | null>(null, [Validators.required]),
    documentationType: new FormControl<DocumentationType>(DocumentationType.FormOnly, [Validators.required]),
    cessationError: new FormControl<boolean | null>(null, [Validators.required]),
    changeDateError: new FormControl<boolean | null>(null, [Validators.required]),
    changeDateErrorState: new FormControl<boolean | null>(null)
  });

  override stepperForm = new FormGroup({
    [BulkCeaseCompanyOfficeholderStepsEnum.FormDescription]: new FormGroup({}),
    [BulkCeaseCompanyOfficeholderStepsEnum.CeaseCompanyOfficeholder]: this.form,
    [BulkCeaseCompanyOfficeholderStepsEnum.Summary]: this.form
  });

  constructor() {
    super();
    this.setupSteps(BulkCeaseCompanyOfficeholderStepsEnum);
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDefaultMinDateStruct();
    this.maxDate = DatepickerHelper.getTodayStruct();
    this.form.controls.changeDate.setValue(DatepickerHelper.getToday());

    this.listenReasonOfCessationChange();
    this.listenOfficerTypesChange();
    this.listenAppointedOfficerIdChanges();
    this.listenChangeDate();

    this.setupChange();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes[0] as BulkCompanyChangeOfficer);
  }

  private getOfficeholders(): void {
    const getOfficeHolders$ = this.companiesList
      .map(company => {
        const entity = { name: company.name ?? '', entityNumber: company.acn ?? '' };
        return this.companyProfileService.getCompanyProfile(company.entityId ?? '', true, '').pipe(
          map(companyProfile => {
            return companyProfile.officers.filter(o => !o.end).map(officer => {
              return ({ isSigningContact: false, relationship: { ...officer, entity } })
            }) as { isSigningContact: boolean, relationship: Relationship }[];
          })
        );
      });

    forkJoin(getOfficeHolders$)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(error => {
          console.error('Error getting office holders:', error);
          return of([] as { isSigningContact: boolean, relationship: Relationship }[][]);
        })
      )
      .subscribe(officeHolders => {
        if(officeHolders.length) {
          this.officeHoldersByCompany = officeHolders.map((item) => {
            const relationship = item[0].relationship;
            this.rows.push({
              name: relationship.entity?.name ?? '',
              fullName: '',
              acn: relationship.entity?.entityNumber ?? '',
              error: '',
              relationshipId: '',
              entityId: item[0].relationship.entityId,
              selectedOption: { label: '', value: '' },
              options: [],
              officeHolders: item.map(el => el.relationship)
            });

            return ({ companyId: relationship.entityId, officeHolders: item });
          });

          this.changeAuthorisationByCompany = this.officeHoldersByCompany.map((officeHolder, index) => {
            let documentationType = DocumentationType.FormOnly;
            const changeAuthorisation = new ChangeAuthorisation();

            const relationShipOfficeHolders = officeHolder.officeHolders.map(item => item.relationship);
            const signingContact = officeHolder.officeHolders.find(item => item.isSigningContact)?.relationship;
            const directors = this.getOfficers(false,false, relationShipOfficeHolders);
            const authOfficeHolder = directors[0];

            changeAuthorisation.documentationType = documentationType;
            if(directors.length === 1) {
              documentationType = DocumentationType.Resolution;
              changeAuthorisation.documentationType = documentationType;
            }

            if(documentationType === DocumentationType.Resolution || documentationType === DocumentationType.FormOnly) {
              changeAuthorisation.authorisingParty = signingContact ? signingContact : authOfficeHolder;
              changeAuthorisation.signingManager = changeAuthorisation.authorisingParty.relationshipId;
              changeAuthorisation.authorisingParty.entityId = relationShipOfficeHolders[0].entityId;
            } else if (documentationType === DocumentationType.Minutes) {
              changeAuthorisation.authorisingParty = signingContact ? signingContact : authOfficeHolder;
              changeAuthorisation.signingManager = changeAuthorisation.authorisingParty.relationshipId;
              changeAuthorisation.chairperson = signingContact ? signingContact : authOfficeHolder;
              changeAuthorisation.authorisingParty.entityId = relationShipOfficeHolders[0].entityId;
              changeAuthorisation.attendees = directors.map(director => {
                return {
                  individual: director.individualDataOverride,
                  presence: MinutesMeetingPresence.PresentPhysically
                }
              }) as MinutesMeetingAttendee[];

              const meetingDate = this.form.value.changeDate?.toISOString().split('T')[0];
              changeAuthorisation.meetingDate = meetingDate ? meetingDate as unknown as Date : undefined;
              changeAuthorisation.meetingAddressType = MeetingLocationType.Registered;
              changeAuthorisation.meetingAddress = authOfficeHolder.entity?.registeredAddress;
            }

            if(officeHolder.officeHolders[0].relationship.end) {
              this.rows[index].error = 'Officer has already ceased'
            }

            this.rows[index].fullName = changeAuthorisation.authorisingParty.individualDataOverride?.fullName ?? '';
            this.rows[index].selectedOption = { label: this.rows[index].fullName ?? '', value: changeAuthorisation.authorisingParty.relationshipId };
            this.rows[index].options = directors.map(director => ({ label: director.individualDataOverride?.fullName ?? '', value: director.relationshipId }) );

            return { companyId: officeHolder.companyId, changeAuthorisation };
          });

          this.officeHoldersList = officeHolders
            .flatMap((item) => item.map(el => el.relationship));

          const uniqueIndividualIds = new Set<string>();
          this.uniqueOfficeHoldersList = this.officeHoldersList
            .filter(relationship => {
              if (relationship.individualId && !uniqueIndividualIds.has(relationship.individualId)) {
                uniqueIndividualIds.add(relationship.individualId);
                return true;
              }
              return false;
            });

          this.officersSelectOptions = this.uniqueOfficeHoldersList
            .map(item=> ({ label: item?.individualDataOverride?.fullName ?? '', value: item?.individualId }))
            .sort((a, b) => a.label.localeCompare(b.label));
        }
      });
  }

  selectName(data: {  entityId: string, selectedId: string }): void {
    let selectedOfficeholder: Relationship | undefined;
    this.officeHoldersByCompany.forEach(item => {
      if(item.companyId === data.entityId) {
        selectedOfficeholder = item.officeHolders.find(officeholder => {
          return officeholder.relationship.relationshipId === data.selectedId
        })?.relationship;
      }
    });

    this.changeAuthorisationByCompany.forEach(c => {
      if(c.companyId === data.entityId) {
        if(selectedOfficeholder) {
          c.changeAuthorisation.authorisingParty = selectedOfficeholder;
        }

        c.changeAuthorisation.signingManager = data.selectedId;
      }
    });
  }

  override setupChange(change: BulkCompanyChangeOfficer = this.formModel) {
    if (!this.isEdit && change === this.formModel) return;
    this.companiesList = change.companiesList;

    this.getOfficeholders();
  }

  override buildDocument(): Document[] | null {
    const bulkChangeOfficeholders = this.officeHoldersByCompany
      .filter((change, index) => !this.rows[index].error)
      .map(item => {
        const offices = item.officeHolders
          .filter(officeHolder => {
            return officeHolder.relationship.individualId === this.selectedOfficeholder?.individualId
              && officeHolder.relationship.type === this.form.get('position')?.value
          })
          .map(officeHolder => {
            officeHolder.relationship.end = this.form.controls.changeDate.value!;
            delete officeHolder.relationship.entity;
            return officeHolder.relationship;
          });

        return new BulkCompanyChangeOfficer({
          ...this.form.value,
          actionType: CompanyChangeOfficerType.Removal,
          changeDate: this.form.controls.changeDate.value!,
          offices
        });
    });

    try {
      return this.changeAuthorisationByCompany.map((item, index) => {
        delete item.changeAuthorisation.authorisingParty.entity;

        return new Document({
          changes: [bulkChangeOfficeholders[index]],
          changeAuthorisation: item.changeAuthorisation,
          entityId: item.companyId,
          type: 'c:484',
          documentId: this.companyChangeData?.documentId,
          documentationType: this.form.get('documentationType')?.value ?? 0
        });
      });
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  private setRows(appointedOfficerId: string, selectedPosition: RelationshipType): void {
    this.selectedOfficeholder = this.officeHoldersList.find(o => o.individualId === appointedOfficerId);
    this.rows.forEach(row => row.error = '');

    this.officeHoldersByCompany.forEach((officeHolder, index) => {
      const relationShipOfficeHolders = officeHolder.officeHolders.map(item => item.relationship);
      const directors = this.getOfficers(false, false, relationShipOfficeHolders);
      const nonCeasedDirectors = directors?.filter(officer => officer.type === RelationshipType.Director && !officer.end);

      const fullName = this.rows[index].officeHolders.find(el => el.individualId === appointedOfficerId)?.individualDataOverride?.fullName ?? '';
      const currentType = this.rows[index].officeHolders.find(el => el.individualId === appointedOfficerId)?.type;
      const isSameType = currentType === selectedPosition;
      const officeHolderNotAppointedToRoleInfo = `The current officeholder is not currently in a ${OfficerAppointmentTypeLabels[currentType ?? 0]} position at ${relationShipOfficeHolders[0]?.entity?.name}. No form will be generated for this company.`
      const officeHolderNotExistInfo = `The selected officeholder to be ceased is not a current officeholder for ${relationShipOfficeHolders[0]?.entity?.name}. No form will be generated for this company.`;
      const soleDirectorInfo = `You cannot cease the sole director of the company ${relationShipOfficeHolders[0]?.entity?.name}. Please appoint a new director before proceeding with this cessation.`;

      this.rows[index].relationshipId = appointedOfficerId ?? '';
      this.rows[index].fullName = this.selectedOfficeholder?.individualDataOverride?.fullName ?? '';

      if (fullName && nonCeasedDirectors.length <= 1 && currentType === RelationshipType.Director) {
        this.rows[index].error = soleDirectorInfo;
      } else if (!fullName) {
        this.rows[index].error = officeHolderNotExistInfo;
      } else if (fullName && !isSameType) {
        this.rows[index].error = officeHolderNotAppointedToRoleInfo;
      }
    });

    this.addErrorToRows();

    if(this.rows.some(row => row.error)) {
      this.form.get('cessationError')?.setValue(true);
    } else {
      this.form.get('cessationError')?.setValue(false);
    }
  }

  private listenAppointedOfficerIdChanges(): void {
    this.form.controls.appointedOfficerId.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((appointedOfficerId) => {
        if (appointedOfficerId) {
          clearFormArray(this.form.controls.types);

          const uniqueTypes: RelationshipType[] = this.officeHoldersList
            .filter(officeHolder => officeHolder.individualId === appointedOfficerId)
            .reduce((types, officeHolder) => {
              if (!types.includes(officeHolder.type)) {
                types.push(officeHolder.type);
              }
              return types;
            }, [] as RelationshipType[]);

          const selectedPosition = uniqueTypes[0];
          this.form.get('position')?.setValue(selectedPosition);

          this.officerPositionTypesOptions = uniqueTypes.map(type => ({
            label: `${ this.splitByCapitalLetterPipePipe.transform(RelationshipType[type]) }`,
            value: type
          }));

          this.setRows(appointedOfficerId, selectedPosition);
          this.form.controls.changeDate.setValue(DatepickerHelper.getToday());
        } else {
          clearFormArray(this.form.controls.types);
          this.officerPositionTypesOptions = [];
          this.form.controls.cessationReason.reset();
        }
      });
  }

  private listenOfficerTypesChange(): void {
    combineLatest([
      this.form.controls.position.valueChanges,
      this.form.controls.changeDate.valueChanges.pipe(startWith(this.form.controls.changeDate.value)),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.fillNoticeOfResignation());
  }

  private listenReasonOfCessationChange(): void {
    this.form.controls.cessationReason.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((reasonOfCessation) => {
        setControlDisabled(this.form.controls.noticeOfResignation, reasonOfCessation !== ReasonOfCessation.Resignation);

        this.form.controls.types.controls.forEach(control => {
          setControlDisabled(control, reasonOfCessation === ReasonOfCessation.Death);

          if (reasonOfCessation === ReasonOfCessation.Death) {
            control.setValue(true);
          }
        });

        if (reasonOfCessation === ReasonOfCessation.Resignation) {
          this.fillNoticeOfResignation();
        }
      });
  }

  private fillNoticeOfResignation(): void {
    const allowedToFillNoticeOfResignation =
      this.form.controls.cessationReason.value === ReasonOfCessation.Resignation
      && this.form.controls.appointedOfficerId.value !== null
      && this.form.controls.cessationReason.value !== null
      && this.form.controls.changeDate.value !== null;

    if (!allowedToFillNoticeOfResignation)
      return;

    const officerName = this.selectedOfficeholder?.individualDataOverride?.fullName ?? '';
    const officerAddress = this.selectedOfficeholder?.individualDataOverride?.address?.normalizedFullAddress ?? '';
    const positions = ' as ' + OfficerAppointmentTypeLabels[this.selectedOfficeholder?.type ?? 1];
    const nameOfCompanyAndACN = this.selectedOfficeholder?.entity?.name + (this.selectedOfficeholder?.entity?.entityNumber ? ` - ${ new ACNPipe().transform(this.selectedOfficeholder?.entity?.entityNumber) }` : '');
    const resignationDate = DatepickerHelper.toString(this.form.controls.changeDate.value as Date);

    const noticeOfResignation = `I, ${ officerName } of ${ officerAddress.trim() } hereby resign${ positions } of ${ nameOfCompanyAndACN } with effect from ${ resignationDate }`;
    this.form.controls.noticeOfResignation.setValue(noticeOfResignation);
  }

  private getOfficers(includeALt = true, icludeSec = true, officeHolders: Relationship[]) {
    let officersSelected: Relationship[] = [];
    const includedTypes: RelationshipType[] = [RelationshipType.Director];

    if (includeALt) {
      includedTypes.push(RelationshipType.AlternativeDirector);
    }
    if (icludeSec) {
      includedTypes.push(RelationshipType.Secretary);
    }

    officersSelected = officeHolders.filter(
      x => x.type != undefined && includedTypes.includes(x.type)
    );

    return officersSelected;
  }

  get noticeOfResignationHidden(): boolean {
    return this.form.controls.cessationReason.getRawValue() !== ReasonOfCessation.Resignation;
  }

  onStepChange(stepIndex: BulkAddressChangeStepsEnum): void {
    if(stepIndex === BulkAddressChangeStepsEnum.Summary) {
      if(this.form.get('changeDateError')?.value) {
        this.form.get('changeDateErrorState')?.patchValue(this.form.get('changeDateError')?.value ?? true);
        this.form.get('changeDateError')?.patchValue(null);
      }
    } else if(stepIndex === BulkAddressChangeStepsEnum.NewAddress){
      this.form.get('changeDateError')?.patchValue(this.form.get('changeDateErrorState')?.value ?? null);
      this.form.get('changeDateErrorState')?.patchValue(null);
    }
  }

  private addErrorToRows(): void {
    this.rowHasError = this.rows.some(row => row.error);

    if (this.rowHasError && !this.colDefs.some(c => c.field === 'error')) {
      this.colDefs.push({
        headerName: 'Error',
        field: 'error',
        flex: 1.7,
        cellRenderer: (params: { value: string }) => {
          const error = params.value;
          const splitError = this.splitTextInHalf(error);
          if (error) {
            return `<div style="color: #CE5252; line-height: 2px; padding-top: 8px;">${splitError[0]}</div>
                    <div style="color: #CE5252;">${splitError[1]}</div>`;
          } else {
            return '';
          }
        },
        menuTabs: []
      });
    } else if(!this.rowHasError && this.colDefs.length === this.colDefsWithErrorLength) {
      this.colDefs.pop();
    }

    this.rows = [...this.rows];
    this.colDefs = [...this.colDefs];
  }

  private listenChangeDate(): void {
    this.form.controls.changeDate.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(changeDate => {
        if(changeDate) {
          this.officeHoldersByCompany.map((officeHolder, index) => {
            const relationshipOfficeHolders = officeHolder.officeHolders.map(item => item.relationship);

            if(relationshipOfficeHolders[0].start) {
              if(!this.rows[index].error) {
                this.rows[index].error = changeDate <= new Date(relationshipOfficeHolders[0].start)
                  ? 'Date of cease cannot be earlier or same as the appointment date'
                  : '';
              }
            }
          });

          this.addErrorToRows();

          if(this.rows.some(row => row.error)) {
            this.form.get('changeDateError')?.setValue(true);
          } else {
            this.form.get('changeDateError')?.setValue(false);
          }
        }
      });
  }

  splitTextInHalf(text: string): string[] {
    const middle = Math.floor(text.length / 2);
    if (text[middle] === ' ') {
      return [text.slice(0, middle), text.slice(middle + 1)];
    }

    const splitPoint = text.indexOf(' ', middle);
    if (splitPoint === -1) {
      return [text, ''];
    }

    return [text.slice(0, splitPoint), text.slice(splitPoint + 1)];
  }
}
