import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ButtonComponent } from "../../../components/common/button/button.component";
import { LoaderComponent } from "../../../components/common/loader/loader.component";
import { PageTitleComponent } from "../../../components/common/page-title/page-title.component";
import { AppCopyDirective } from "../../../../directives/copy.directive";
import { DefaulValuePipe } from "../../../../pipes/enumsPipes/defaultValuePipe";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  EditDocumentSettingsModalComponent
} from "./edit-document-settings-modal/edit-document-settings-modal.component";
import {
  NotifyWhenAllPartiesSignedDocumentLabelRecord
} from "./edit-document-settings-modal/edit-document-settings-modal.constant";
import { HasRoleDirective } from '../../../../directives/has-role.directive';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../../../services/settings.service';
import { AnnualStatementSettingsData, DocumentSettingsData} from '../../../../models/documentSettingsData';
import { DocumentationType, MeetingLocationType, MeetingOnlineType } from '../../../../models/enums/documentConfirmEnums';
import { catchError, finalize, forkJoin, iif, mergeMap, of, tap } from 'rxjs';
import { DocumentSelectionComponent } from '../../../components/document-selection/document-selection.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DocumentSelection } from '../../../../models/document-selection';

@Component({
  selector: 'app-document-settings-page',
  standalone: true,
  imports: [
    ButtonComponent,
    LoaderComponent,
    PageTitleComponent,
    AppCopyDirective,
    DefaulValuePipe,
    HasRoleDirective,
    DocumentSelectionComponent
  ],
  templateUrl: './document-settings-page.component.html',
  styleUrls: ['./document-settings-page.component.scss', '../../settings-styles.scss']
})
export class DocumentSettingsPageComponent implements OnInit {
  private modalService = inject(NgbModal);
  #toast = inject(ToastrService);
  #destroyRef = inject(DestroyRef);
  #settingsService = inject(SettingsService);

  isLoading = false;
  documentSettings = new DocumentSettingsData();
  asSettings = new AnnualStatementSettingsData();

  initDocumentSelectionFiles: DocumentSelection[] = [];

  readonly NotifyWhenAllPartiesSignedDocumentLabelRecord = NotifyWhenAllPartiesSignedDocumentLabelRecord;
  readonly DocumentationType = DocumentationType;
  readonly MeetingLocationType = MeetingLocationType;
  readonly MeetingOnlineType = MeetingOnlineType;

  ngOnInit(): void {
    this.loadDocumentSettings();
  }

  openEditDocumentSettingsModal(): void {
    const modalRef = this.modalService.open(EditDocumentSettingsModalComponent, { size: 'lg' });
    const instance = modalRef.componentInstance as EditDocumentSettingsModalComponent;
    instance.documentSettings = this.documentSettings;
    instance.asSettings = this.asSettings;
    instance.initDocumentSelectionFiles = this.initDocumentSelectionFiles;

    modalRef.result.then(
      () => this.loadDocumentSettings(),
      () => {
      });
  }

  loadDocumentSettings(): void {
    this.isLoading = true;

    forkJoin({
      documentSettings: this.#settingsService.getDocumentSettings(),
      asSettings: this.#settingsService.getAnnualStatementSettings()
    }).pipe(
      tap(results => {
        this.documentSettings = results.documentSettings;
        this.asSettings = results.asSettings;
      }),
      mergeMap(() =>
        iif(
          () => this.asSettings.documentSelectionFiles.length === 0,
          this.#settingsService.getAnnualStatementSelection().pipe(
            tap(selection => {
              //to make ratch work properly
              this.initDocumentSelectionFiles = selection;
            })
          ),
          of(null)
        )
      ),
      catchError(() => {
        this.#toast.error('Failed to load document or annual statement settings');
        return of(null);
      }),
      finalize(() => {
        this.isLoading = false;
      }),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe();
  }
}
