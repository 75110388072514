import { Guid } from "../app/helpers/guid.helper";

export class Contact {
    contactId: string;
    individualId?: string;
    organisationId?: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    nickname: string
    carbonCopy: string;
    isDeleted: boolean;
    fullName: string;

    constructor(contact: Partial<Contact> = {}) {
        this.contactId = contact.contactId ?? Guid.EmptyGuid;
        this.individualId = contact.individualId;
        this.organisationId = contact.organisationId ?? Guid.EmptyGuid;
        this.firstName = contact.firstName ?? '';
        this.lastName = contact.lastName ?? '';
        this.email = contact.email ?? '';
        this.phone = contact.phone ?? '';
        this.carbonCopy = contact.carbonCopy ?? '';
        this.nickname = contact.nickname ?? '';
        this.isDeleted = contact.isDeleted ?? false;
        this.fullName = `${this.firstName} ${this.lastName}`;
    }
}

export enum ContactType{
    Billing,
    Signing
}
