import { Address } from "./address";
import { DocumentSelection } from "./document-selection";
import { DocumentationType, MeetingLocationType, MeetingOnlineType } from "./enums/documentConfirmEnums";

export class AnnualStatementSettingsData {
  generateAutomatically: boolean;
  documentTypeToGenerate: DocumentationType;
  addSignatoryToCompanyStatement: boolean;
  meetingLocationType: MeetingLocationType;
  meetingOnlineType?: MeetingOnlineType | null;
  otherAddress?: Address | null;
  documentSelectionFiles: DocumentSelection[]
  
    constructor(data: Partial<AnnualStatementSettingsData> = {}) {
      this.generateAutomatically = data.generateAutomatically ?? true;
      this.documentTypeToGenerate = data.documentTypeToGenerate ?? DocumentationType.Minutes;
      this.addSignatoryToCompanyStatement = data.addSignatoryToCompanyStatement ?? true;
      this.meetingLocationType = data.meetingLocationType ?? MeetingLocationType.Registered;
      this.meetingOnlineType = data.meetingOnlineType ?? null;
      this.otherAddress = data.otherAddress ?? null;
      this.documentSelectionFiles = data.documentSelectionFiles ?? [];
    }
  }
  
  export class DocumentSettingsData {
    esigningEmailRecipients: EsigningEmailRecipients;
  
    constructor(data: Partial<DocumentSettingsData> = {}) {
      this.esigningEmailRecipients = data.esigningEmailRecipients ?? EsigningEmailRecipients.DoNotSend;
    }
  }
  
  export enum EsigningEmailRecipients {
    DoNotSend,
    SenderOnly,
    RecipientsOnly,
    SenderAndRecipients
  }