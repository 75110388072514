import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { InlineEditInputComponent } from '../inline-edit-input/inline-edit-input.component';
import { SpanWithOpenIconComponent } from '../span-with-open-icon/span-with-open-icon.component';
import { ShareholderRelationshipDetails } from '../../../../models/relationship';
import { NgClass } from '@angular/common';
import { DefaulValuePipe } from '../../../../pipes/enumsPipes/defaultValuePipe';
import { from, tap } from 'rxjs';
import { SecurityService } from '../../../../services/security.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-beneficial-owner',
  standalone: true,
  templateUrl: './beneficial-owner.component.html',
  styleUrl: './beneficial-owner.component.scss',
  imports: [
    InlineEditInputComponent,
    SpanWithOpenIconComponent,
    NgClass,
    DefaulValuePipe
  ]
})
export class BeneficialOwnerComponent {
  @Input({ required: true }) details!: ShareholderRelationshipDetails;
  @Input({ required: true }) relationshipIds!: string[];
  @Input() showOpenIcon = false;
  @Input() controlLike = false;

  @Output() onDataSaved = new EventEmitter<string>();

  securityService = inject(SecurityService);
  toastr = inject(ToastrService);

  updateBeneficialOwner(relationshipIds: string[]) {
    return (newBOName: string) => {
      return from(relationshipIds.map(relationshipId => {
         this.securityService.updateBeneficialOwner(relationshipId, newBOName)
        .subscribe({
          next: () => {
            this.details.beneficialOwner = newBOName;
            this.toastr.success('Beneficial owner updated');
            this.onDataSaved.emit(newBOName);
          },
          error: () => {
            this.toastr.error('Failed to update beneficial owner');
          }
        })
      }));
    };
  }

}
