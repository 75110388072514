import { Component, DestroyRef, inject, Input, OnInit, Optional } from '@angular/core';
import { CommonModalFormComponent } from "../../../../modals/common-modal-form/common-modal-form.component";
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppCopyDirective } from "../../../../../directives/copy.directive";
import { DefaulValuePipe } from "../../../../../pipes/enumsPipes/defaultValuePipe";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectComponent } from "../../../../components/common/select/select.component";
import { CheckboxComponent } from "../../../../components/common/checkbox/checkbox.component";
import { RadioComponent } from "../../../../components/common/radio/radio.component";
import {
  DocumentTypeToGenerateOptions,
  MeetingLocationTypeOptions,
  MeetingOnlineTypeOptions,
  NotifyWhenAllPartiesSignedDocumentOptions
} from "./edit-document-settings-modal.constant";
import { ToastrService } from "ngx-toastr";
import { YesNoControlComponent } from "../../../../components/common/yes-no-control-component/yes-no-control.component";
import { AnnualStatementSettingsData, DocumentSettingsData, EsigningEmailRecipients } from '../../../../../models/documentSettingsData';
import { compare } from 'fast-json-patch';
import { SettingsService } from '../../../../../services/settings.service';
import { combineLatestWith, finalize } from 'rxjs';
import { DocumentationType, MeetingLocationType, MeetingOnlineType } from '../../../../../models/enums/documentConfirmEnums';
import { CommonModule } from '@angular/common';
import { AddressControlComponent } from '../../../../components/common/address-control/address-control.component';
import { Address } from '../../../../../models/address';
import { DocumentSelectionComponent } from '../../../../components/document-selection/document-selection.component';
import { DocumentSelection } from '../../../../../models/document-selection';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AddressStatus } from '../../../../../models/enums/addressEnums';

@Component({
  selector: 'app-edit-document-settings-modal',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    CommonModule,
    AddressControlComponent,
    AppCopyDirective,
    DefaulValuePipe,
    SelectComponent,
    CheckboxComponent,
    RadioComponent,
    ReactiveFormsModule,
    YesNoControlComponent,
    DocumentSelectionComponent,
    FormsModule,
  ],
  templateUrl: './edit-document-settings-modal.component.html',
  styleUrl: './edit-document-settings-modal.component.scss'
})
export class EditDocumentSettingsModalComponent implements OnInit {
  private activeModal = inject(NgbActiveModal);
  private toastr = inject(ToastrService);
  private settingsService = inject(SettingsService);
  private destroyRef = inject(DestroyRef);
  private fb: FormBuilder = inject(FormBuilder);
  
  addressStatus: AddressStatus | undefined;
  isInvalidSaveButton = false;

  @Input() documentSettings = new DocumentSettingsData();
  @Input() asSettings = new AnnualStatementSettingsData();
  @Input() initDocumentSelectionFiles: DocumentSelection[] = [];

  readonly NotifyWhenAllPartiesSignedDocumentOptions = NotifyWhenAllPartiesSignedDocumentOptions;
  readonly DocumentTypeToGenerateOptions = DocumentTypeToGenerateOptions;
  readonly MeetingLocationTypeOptions = MeetingLocationTypeOptions;
  readonly MeetingOnlineTypeOptions = MeetingOnlineTypeOptions;
  readonly MeetingLocationType = MeetingLocationType;

  formAddress!: FormGroup;
  isLoading = false;
  form = new FormGroup({
    // documents
    receiveEmailsForDocs: new FormControl<EsigningEmailRecipients | null>(EsigningEmailRecipients.DoNotSend),

    // annual statement
    generateAutomatically: new FormControl<boolean>(true),
    documentTypeToGenerate: new FormControl<DocumentationType>(DocumentationType.Minutes),
    addSignatoryToCompanyStatement: new FormControl<boolean>(true),
    meetingLocationType: new FormControl<MeetingLocationType>(MeetingLocationType.Registered),
    meetingOnlineType: new FormControl<MeetingOnlineType | null>(null),
    otherAddress: new FormControl<Address | null>(null),
    documentSelectionFiles: new FormControl<DocumentSelection[]>([])
  });

  ngOnInit() {
    this.activeModal.update({ modalDialogClass: 'common-modal-form-dialog overflow-y-auto' });

    this.form.patchValue({
      receiveEmailsForDocs: this.documentSettings.esigningEmailRecipients,
      generateAutomatically: this.asSettings.generateAutomatically,
      documentTypeToGenerate: this.asSettings.documentTypeToGenerate,
      addSignatoryToCompanyStatement: this.asSettings.addSignatoryToCompanyStatement,
      meetingLocationType: this.asSettings.meetingLocationType,
      meetingOnlineType: this.asSettings.meetingOnlineType,
      otherAddress: this.asSettings.otherAddress,
      documentSelectionFiles: this.asSettings.documentSelectionFiles
    });

    this.formAddress = this.fb.group({
      address: [new Address()], 
    });

    this.formAddress.get('address')?.patchValue(this.asSettings.otherAddress);

  }

  onDataStatusChange(addressStatus: AddressStatus | undefined): void {
      this.addressStatus = addressStatus;
      if(addressStatus === AddressStatus.WARNING) {
        this.isInvalidSaveButton = true;
      } else {
        this.isInvalidSaveButton = false;
      }
    }

  confirm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const documentSettings = new DocumentSettingsData({
      esigningEmailRecipients: this.form.value.receiveEmailsForDocs!
    });
    const documentSettingsPatch = compare(this.documentSettings, documentSettings);

    const asSettings = new AnnualStatementSettingsData({
      generateAutomatically: this.form.value.generateAutomatically!,
      documentTypeToGenerate: this.form.value.documentTypeToGenerate!,
      addSignatoryToCompanyStatement: this.form.value.addSignatoryToCompanyStatement!,
      meetingLocationType: this.form.value.meetingLocationType!,
      meetingOnlineType: this.form.value.meetingOnlineType!,
      otherAddress: this.form.value.otherAddress,
      documentSelectionFiles: this.form.value.documentSelectionFiles!
    });

    if (asSettings.meetingLocationType !== MeetingLocationType.Other) {
      asSettings.otherAddress = null;
    }
    else{
      asSettings.otherAddress = this.formAddress.get('address')?.getRawValue() as Address;
    }
    const asSettingsPatch = compare(this.asSettings, asSettings);

    this.isLoading = true;

    const documentPatch$ = this.settingsService.patchDocumentSettings(documentSettingsPatch);
    const asPatch$ = this.settingsService.patchAnnualStatementSettings(asSettingsPatch);

    documentPatch$.pipe(
      combineLatestWith(asPatch$),
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe({
      next: () => {
        this.toastr.success('Data saved successfully', 'Success');
        this.activeModal.close();
      },
      error: () => {
        this.toastr.error('Error happened while saving data', 'Error');
      }
    });
  }

  public onDocumentSelectionChange(documentSelections: DocumentSelection[]): void {
    this.form.value.documentSelectionFiles = documentSelections;
  }

  onMeetingAddressTypeSelect(type: MeetingLocationType): void {
    if(type === MeetingLocationType.Other) {
      this.form.get('otherAddress')?.patchValue(new Address());
    } else {
      this.form.get('otherAddress')?.patchValue(null);
    }
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
