<div class="select-container">
  <div class="header">
    @if (label) {
      <div class="label">{{ label }}</div>
    }

    @if (link && text) {
      <div>
        <app-link [link]="link" [text]="text"></app-link>
      </div>
    }
  </div>

  <div
    ngbDropdown
    class="dropdown input-container"
    [open]="dropdownOpen"
    (openChange)="onDropdownChange($event)"
  >
    <div
      class="input-wrapper"
      [ngClass]="{'opened': dropdownOpen,
        'error': displayValidationError
      }"
    >
      <input
        ngbDropdownToggle
        tabindex="0"
        class="input"
        type="text"
        [formControl]="searchControl"
        [placeholder]="placeholder"
        (keydown)="dropdownOpen = true"
        (keydown.escape)="dropdownOpen = false"
        (blur)="onBlur()"
      >
      <span ngbDropdownToggle class="icon ic-select-icon input-icon"></span>
    </div>
    <div ngbDropdownMenu class="dropdown-menu">
      @for (option of filteredOptionsSignal(); track option; let index = $index) {
        <button
          ngbDropdownItem
          type="button"
          class="dropdown-button"
          [class.selected]="option.label === value?.label && option.value === value?.value"
          (click)="selectOption(option, index)"
        >
          <span class="label">{{ option.label }}</span>

          <span class="icon ic-circle-check"></span>
        </button>
      }

      @if (searchControl.value?.length && !filteredOptionsSignal().length) {
        <div class="dropdown-button">No options found for "{{ searchControl.value }}".</div>
      }

      @if (!options.length) {
        <div class="dropdown-button">No options.</div>
      }
    </div>
  </div>

  @if (helperText) {
    <div class="helper">{{ helperText }}</div>
  }

  @if (displayValidationError) {
    <app-validation-error [customErrors]="customErrors"></app-validation-error>
  }
</div>
