import { Component, DestroyRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { catchError, EMPTY, filter, from, Observable, of, Subject, switchMap, tap } from "rxjs";
import { ButtonComponent } from "../../components/common/button/button.component";
import { RadioComponent } from "../../components/common/radio/radio.component";
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { SelectOption } from "../../../models/selectOptions";
import { CarbonCopy, ESignBody, Signatory, SignRow, UpdateRecipientInfoModel } from "../../../models/signDocuments";
import { ColDef, GridApi } from "ag-grid-community";
import { ListGridComponent } from "../../components/common/grid/components/list-grid/list-grid.component";
import { GridOptions, ValueGetterParams } from "ag-grid-enterprise";
import { CustomFormValidators } from "../../../custom-form-validators/custom-form-validators";
import { EmailTemplate } from "../../../models/email-templates/emailTemplate";
import { EmailTemplatesService } from '../../../services/email-templates.service';
import { ToastrService } from "ngx-toastr";
import { EmailPreviewComponent } from "../../components/email-preview/email-preview.component";
import { ModalFormsService } from "../../../services/modal-forms.service";
import { AddApproverComponent } from "../../modals/annual/add-approver/add-approver.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MultipleInputEmailComponent } from "../../components/common/multiple-input-email/multiple-input-email.component";
import { AnnualStatementsService } from "../../../services/annual-statements.service";
import {
  AgAnnualSignActionsComponent
} from "../../components/common/grid/components/annual/ag-annual-sign-actions/ag-annual-sign-actions.component";
import { AddCarbonCopyComponent } from "../../modals/annual/add-carbon-copy/add-carbon-copy.component";
import { ConfirmComponent } from "../../modals/confirm/confirm.component";
import { NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  SignGroup,
  AnnualStatementStatus,
  SendOption,
  SigningStatus
} from "../../../models/enums/annualStatementEnums";
import { Guid } from "../../helpers/guid.helper";
import { ESignService } from "../../../services/e-sign.service";
import { Signature } from "../../../models/documentEnteties/signature";
import { EmailPayloadBase } from "../../../models/email-templates/emailPayloadBase";
import {
  AgSignatoryStatusComponent
} from "../../components/common/grid/components/ag-signatory-status/ag-signatory-status.component";
import { ApproverStatus } from "../../../models/enums/approverStatus";
import { DocumentSigning } from "../../../models/documentEnteties/document-signing";
import { NotificationComponent } from "../../components/common/notification/notification.component";
import {
  AttachedFileLabelComponent
} from "../../components/notes/components/attached-file-label/attached-file-label.component";
import { DatePickerComponent } from "../../components/common/date-picker/date-picker.component";
import { FileUploadComponent } from "../../components/common/file-upload/file-upload.component";
import { bytesToMegabytes } from "../../../functions/bytes-to-megabytes";
import { DatepickerHelper } from "../../../custom-form-validators/date-picker-form-validators";
import { DatePipe, formatDate } from "@angular/common";
import { Company } from "../../../models/company";
import { ChangeAuthorisation } from "../../../models/changeAuthorisation";
import { LodgementDeadline } from "../../../models/documents";
import { Operation } from "fast-json-patch";
import { toBase64 } from "../../../functions/to-base64";
import { EmailMultipleAddress } from "../../../models/email-templates/emailMultipleAddress";
import {
  AgAnnualSignEditEmailComponent
} from "../../components/common/grid/components/annual/ag-annual-sign-edit-email/ag-annual-sign-edit-email.component";
import {
  AgAnnualSignEditPhoneComponent
} from "../../components/common/grid/components/annual/ag-annual-sign-edit-phone/ag-annual-sign-edit-phone.component";
import { EmailBulkSendRequest } from "../../../models/email-templates/emailBulkSendRequest";
import { DocumentStatusEnum } from '../../../models/enums/documentStatusEnum';
import { AnnualStatement } from "../../../models/annualStatement";
import { PhonePipe } from "../../../pipes/phonePipe";
import { EsignProvider } from "../../../models/enums/esignEnums";
import { DocumentsService } from "../../../services/documents.service";
import {
  AgAnnualSignNameCategoryComponent
} from "../../components/common/grid/components/annual/ag-annual-sign-name-category/ag-annual-sign-name-category.component";
import { Document } from "../../../models/document";
import { DocumentSigningsService } from "../../../services/document-signing.service";
import { SignalRService } from '../../../services/signalr.service';
import { ChangeSignatoryInfoModalComponent } from '../../modals/signing/change-signatory-info-modal/change-signatory-info-modal.component';
import { DocumentBase } from '../../../models/documentEnteties/document-base';

enum SignColumn {
  Name = 0,
  Email = 1,
  Phone = 2,
  Status = 3,
  Action = 4,
}

@Component({
  selector: 'app-annual-sign-documents',
  standalone: true,
  templateUrl: './annual-sign-documents.component.html',
  imports: [
    ButtonComponent,
    RadioComponent,
    ReactiveFormsModule,
    ListGridComponent,
    FormsModule,
    EmailPreviewComponent,
    MultipleInputEmailComponent,
    NotificationComponent,
    AttachedFileLabelComponent,
    DatePickerComponent,
    FileUploadComponent,
    DatePipe
],
  providers: [EmailTemplatesService],
  styleUrl: './annual-sign-documents.component.scss'
})
export class AnnualSignDocumentsComponent implements OnInit, OnDestroy {
  @Input() documentBase!: DocumentBase;
  @Input() documentId: string | undefined;
  @Input() documentSigning: DocumentSigning | null | undefined;
  @Input() documentStatus: DocumentStatusEnum | undefined;
  @Input() annualStatementStatus: AnnualStatementStatus | undefined;
  @Input() documentType: string | undefined;
  @Input() changeAuthorisation: ChangeAuthorisation | null | undefined;
  @Input() lodgement: LodgementDeadline | undefined;
  @Input() paymentDeadline: string | null | undefined;
  @Input() reviewDate: string | null | undefined;
  @Input() company: Company | undefined;
  @Input() confirmAction$: Subject<boolean> | undefined;
  @Input() confirmSentESignAction$: Subject<boolean> | undefined;
  @Input() confirmPaperSignAction$: Subject<boolean> | undefined;
  @Input() confirmSendEmailAction$: Subject<boolean> | undefined;
  @Input() confirmNotLodgedFormAction$: Subject<boolean> | undefined;

  @Output() updateDocuments = new EventEmitter<boolean>();
  @Output() nextStep = new EventEmitter<boolean>();

  private annualStatementsService = inject(AnnualStatementsService);
  protected documentsService = inject(DocumentsService);
  protected documentSigningsService = inject(DocumentSigningsService);
  protected eSignService = inject(ESignService);
  private emailTemplatesService = inject(EmailTemplatesService);
  private modalFormsService = inject(ModalFormsService);
  private modalService = inject(NgbModal);
  private fb: FormBuilder = inject(FormBuilder);
  protected toastr = inject(ToastrService);
  protected destroyRef = inject(DestroyRef);
  protected signalRService = inject(SignalRService);

  protected readonly EsignProvider = EsignProvider;
  protected readonly SigningStatus = SigningStatus;
  protected readonly AnnualStatementStatus = AnnualStatementStatus;

  sendByOptions: SelectOption[] = [
    { label: 'eSign', value: SendOption.Sign, disabled: false },
    { label: 'Email', value: SendOption.Email, disabled: false },
    { label: 'Paper', value: SendOption.Paper, disabled: false },
  ];

  readonly maxCarbonCopyNumber = 15;
  readonly eSignMsg = ' Please enter the contact details for the signatories below.' +
    ' Note that the email and mobile phone numbers provided above will be used for sending reminder notifications.' +
    ' Ensure all details are accurate before proceeding.';
  readonly paperSignMsg = 'When you select "Paper Signing," you can upload the manually signed documents and ASIC Form.' +
    ' This will allow you to enter the signing date required when submitting the form to ASIC.' +
    ' Uploading the signed documents and ASIC Form does not automatically lodge the form with ASIC.' +
    ' Click the "Confirm Signing" button to proceed with the form lodgement.';

  readonly phoneMinLen = 6;
  readonly allowedMaxFileSizeMB = 5;
  readonly allowedTotalFileSizeMB = 10;
  readonly maxFilesNumber = 10;
  readonly acceptedFormat = '.pdf';
  readonly uploadNotification = 'Only pdf files. 5Mb max file size, 10Mb total files size, 10 max files';
  errorMessage = '';
  uploadedFiles: File[] = [];
  downloadDocumentsClicked = false;
  signingDate = new Date();
  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  formattedSigningDate = '';
  successMessage = '';
  approver: Signatory | undefined;
  carbonCopy: Signatory[] = [];
  signatories: Signatory[] = [];
  payloadModel!: EmailPayloadBase;
  SendOption = SendOption;
  SignColumn = SignColumn;
  emailList: EmailMultipleAddress[] = [];
  carbonCopyEmails: EmailMultipleAddress[] = [];

  form!: FormGroup;
  gridApi!: GridApi;
  colDefs: ColDef[] = [];
  rows: SignRow[] = [];
  gridOptions: GridOptions = { headerHeight: 0 };

  template!: EmailTemplate;
  emailTemplate = '';
  isSignSent = false;
  loading = false;
  envelopeLoading = false;
  disabledVoidEnvelopeBtn = false;

  constructor() {
    this.toastr.toastrConfig.positionClass = 'toast-top-right';
  }

  ngOnInit(): void {
    this.isSignSent = this.documentSigning?.signingStatus === SigningStatus.Sent;
    this.showVoidEnvelope(this.isSignSent);
    this.listenSignalRMessages();

    this.initForm();
    this.setSendByOption();
    this.setSigningDateParams();
    this.setApprover();
    this.setSignatories();
    this.setCarbonCopies();
    this.setGridRows();
    this.listenSendByButton();
    this.listenHeaderButton();

    if(this.documentSigning && this.documentSigning?.signingStatus !== SigningStatus.ReadyToSend) {
      this.setEnvelopeGridConfig();
      this.showStatuses(true);
    } else {
      this.setGridConfig();
    }
  }

  ngOnDestroy(): void {
    this.showSendPaperBtn(false);
    this.showSendEmailBtn(false);
  }

  public initForm(): void {
    this.form = this.fb.group({
      sendBy: [SendOption.Sign],
      email: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
      emailForm: this.fb.group({
        subjectTemplate: ['', [Validators.required, CustomFormValidators.minLength(1), CustomFormValidators.maxLength(100)]],
        bodyTemplate: ['', [Validators.required, CustomFormValidators.minLength(1), CustomFormValidators.maxLength(5000)]],
        footerTemplate: ['', [CustomFormValidators.maxLength(1000)]],
      })
    });
  }

  public setSendByOption(): void {
    if(this.documentSigning?.signedByPaper) {
      this.sendByOptions.filter(o => o.value === SendOption.Sign)[0].disabled = true;
      this.form.get('sendBy')?.patchValue(SendOption.Paper);
      this.showSendPaperBtn(true);
      this.showSendEmailBtn(false);
    } else if(this.documentSigning?.signingStatus === SigningStatus.Signed) {
      this.sendByOptions.filter(o => o.value === SendOption.Paper)[0].disabled = true;
      this.form.get('sendBy')?.patchValue(SendOption.Sign);
    } else {
      if(this.form.get('sendBy')?.value === SendOption.Sign) {
        this.setSignatories();
      } else {
        if(this.documentStatus && this.documentStatus > DocumentStatusEnum.SignaturePending) {
          this.disableHeaderBtn(true);
        }
      }

      this.showSendEmailBtn(this.form.get('sendBy')?.value === SendOption.Email);
    }
  }

  public setSigningDateParams(): void {
    const signedOn = this.documentSigning?.signedOn ? new Date(this.documentSigning.signedOn) : new Date();
    this.signingDate = signedOn;
    this.formattedSigningDate = formatDate(signedOn, 'YYYY-MM-dd', 'en-US');
    this.minDate = DatepickerHelper.getStructFromDate(signedOn);
  }

  public addCarbonCopy(): void {
    const componentInstance = this.modalFormsService.openModal(AddCarbonCopyComponent, {
      size: 'sm'
    }).componentInstance as AddCarbonCopyComponent;

    componentInstance.confirm.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((email: string) => {
      this.carbonCopy.push({
        id: '',
        fullName: '',
        email: email,
        phone: '',
        signatureId: '',
        disabled: false
      });
      this.setGridRows();
    });
  }

  public addApprover(): void {
    const componentInstance = this.modalFormsService.openModal(AddApproverComponent, {
      size: 'sm'
    }).componentInstance as AddApproverComponent;

    componentInstance.confirm.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((approver: Signatory) => {
      this.approver = approver;
      this.setGridRows();
    });
  }

  public voidEnvelope(): void {
    this.sendSignLoading(true);
    this.envelopeLoading = true;
    const documentId = this.documentId ?? '';

    const modalRef = this.modalService.open(ConfirmComponent);
    (modalRef.componentInstance as ConfirmComponent).title = `Warning`;
    (modalRef.componentInstance as ConfirmComponent).message = `Once voided, the eSigning envelope cannot be restored,
    and any pending or completed signatures within it will be cancelled. To initiate the signing process again,
    a new envelope must be created. Are you sure you want to proceed?`;
    (modalRef.componentInstance as ConfirmComponent).confirmText = 'Continue';
    (modalRef.componentInstance as ConfirmComponent).confirm = () => {
      return this.eSignService.voidEnvelope(documentId).pipe(
        switchMap((res) => {
          if(this.documentStatus !== null && this.documentStatus !== undefined) {
            return this.changeDocumentStatusOperation(documentId, DocumentStatusEnum.SignaturePending);
          } else {
            return of(res);
          }
        }),
        tap(() => {
          this.setGridConfig();
          this.showStatuses(false);
          this.sendByOptions = this.sendByOptions.map(o => { o.disabled = false; return o; });
          this.updateDocuments.emit(true);
          this.toastr.success('Envelope was voided', 'Success');
        }),
        catchError(err => {
          this.toastr.error('There was an error processing the envelope', 'Error');
          console.error(err);
          return of('ERR')
        }),
      );
    }

    modalRef.closed.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      this.sendSignLoading(false);
      this.envelopeLoading = false;
    });
  }

  public voidPaperSign(): void {
    this.envelopeLoading = true;
    const documentId = this.documentId ?? '';

    const modalRef = this.modalService.open(ConfirmComponent);
    (modalRef.componentInstance as ConfirmComponent).title = `Warning`;
    (modalRef.componentInstance as ConfirmComponent).message = `All uploaded documents will be permanently
    voided by clicking the "Void Paper Signing" button. This action cannot be undone. If you wish to proceed,
    click "Continue". Otherwise, cancel to keep your documents intact.`;
    (modalRef.componentInstance as ConfirmComponent).confirmText = 'Continue';
    (modalRef.componentInstance as ConfirmComponent).confirm = () => {
      return this.eSignService.voidPaperSign(documentId).pipe(
        tap(() => {
          this.setGridConfig();
          this.showStatuses(false);
          this.sendByOptions = this.sendByOptions.map(o => { o.disabled = false; return o; });
          this.updateDocuments.emit(true);
          this.toastr.success('Paper signing was voided', 'Success');
          this.showSendPaperBtn(false);
        }),
        catchError(err => {
          this.toastr.error('There was an error processing the envelope', 'Error');
          console.error(err);
          return of('ERR')
        }),
      );
    }

    modalRef.closed.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      this.sendSignLoading(false);
      this.envelopeLoading = false;
    });
  }

  public onChangeEmail(emailData: Partial<ESignBody>): void {
    this.template = emailData.emailTemplate as unknown as EmailTemplate;
    this.payloadModel = emailData.payloadModel as unknown as EmailPayloadBase;
  }

  public onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
  }

  public setApprover(): void {
    if(this.documentSigning?.approver) {
      const { email, recipientId, phone, fullName, approverStatus, approvedOn} = this.documentSigning.approver;
      this.approver = {
        id: '',
        email: email ?? '',
        phone: phone ?? '',
        fullName: fullName ?? '',
        status: approverStatus ?? 0,
        approvedOn: approvedOn ?? '',
        signatureId: recipientId,
        disabled: false
      };
    }
  }

  public setSignatories(): void {
    const signatures = this.documentSigning?.signatures ?? [];
    this.signatories = signatures.map((signature: Signature) => ({
      id: Guid.generate(),
      fullName: signature.signatory.fullName,
      firstName: signature.signatory.firstName,
      lastName: signature.signatory.lastName,
      email: signature.email,
      phone: signature.phone,
      status: signature.status,
      signatureId: signature.signatoryId,
      signedOn: signature.signedOn,
      disabled: false
    }));

    if(
      !this.signatories.length
      || !this.signatories.every(item => item.email)
      || this.isSignSent && this.documentSigning?.signingStatus !== SigningStatus.Signed
    ) {
      this.disableHeaderBtn(true);
    } else {
      this.disableHeaderBtn(false);
    }
  }

  public setCarbonCopies(): void {
    if(this.documentSigning?.carbonCopies?.length) {
      this.carbonCopy = this.documentSigning.carbonCopies.map(item => ({
        id: '',
        fullName: item.fullName,
        email: item.email,
        phone: item.phone,
        status: this.documentSigning?.signingStatus ?? 0,
        signatureId: '',
        disabled: false,
      }));
    }
  }

  public setGridRows(): void {
    const rows: SignRow[] = [];
    const approverAdded = !!this.approver?.email;
    const approverApproved = !!this.approver?.email && this.approver.status === ApproverStatus.Approved;

    if(this.approver) {
      rows.push({ ...this.approver, groupName: 'Approver', type: SignGroup.Approver, signedOn: '', approverAdded, approverApproved });
    }

    if(this.signatories.length) {
      this.signatories.forEach(item => {
        rows.push({ ...item, groupName: 'Signatory', type: SignGroup.Signatories, signedOn: item.signedOn, approverAdded, approverApproved });
      });
    }

    if(this.carbonCopy.length) {
      this.carbonCopy.forEach(item => {
        rows.push({ ...item, groupName: 'Carbon Copy', type: SignGroup.CarbonCopy, signedOn: '', approverAdded, approverApproved });
      });
    }

    this.rows = [...rows];
  }

  public updateRow(index: number, email: string, phone: string): void {
    const signatoryId = this.rows[index].signatureId;

    if(this.rows[index].type === SignGroup.Signatories) {
      const signatory = this.signatories.find(s => s.signatureId === signatoryId)
      if(signatory) {
        signatory.email = email;
        signatory.phone = phone;
      }
    }

    if(this.rows[index].type === SignGroup.Approver) {
      this.approver!.email = email;
      this.approver!.phone = phone;
    }

    this.setGridRows();
  }

  public setGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'Name',
        field: 'fullName',
        flex: 1,
        cellRenderer: AgAnnualSignNameCategoryComponent,
        menuTabs: [],
      },
      {
        headerName: 'Email',
        field: 'email',
        flex: 0.5,
        cellRenderer: AgAnnualSignEditEmailComponent,
        cellRendererParams: {
          edit: this.edit.bind(this),
        },
        menuTabs: [],
      },
      {
        headerName: 'Phone',
        field: 'phone',
        flex: 0.45,
        hide: false,
        cellRenderer: AgAnnualSignEditPhoneComponent,
        cellRendererParams: {
          edit: this.edit.bind(this),
        },
        menuTabs: [],
      },
      {
        headerName: 'Status',
        field: 'status',
        width: 180,
        flex: 1,
        hide: true,
        editable: false,
        cellRenderer: AgSignatoryStatusComponent,
        menuTabs: [],
      },
      {
        headerName: '',
        field: '',
        flex: 0.08,
        hide: false,
        sortable: false,
        cellClass: 'action-cell',
        cellRenderer: AgAnnualSignActionsComponent,
        cellRendererParams: {
          delete: this.delete.bind(this),
          resend: this.resend.bind(this),
          editInfo: this.editInfo.bind(this)
        },
        suppressHeaderMenuButton: true,
      },
    ];
  }

  public setEnvelopeGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'Name',
        field: 'fullName',
        flex: 1.5,
        cellRenderer: AgAnnualSignNameCategoryComponent,
        menuTabs: [],
      },
      {
        headerName: 'Status',
        field: 'status',
        flex: 1.5,
        hide: false,
        cellRenderer: AgSignatoryStatusComponent,
        menuTabs: [],
      },
      {
        headerName: 'Email',
        field: 'email',
        hide: false,
        flex: 1.5,
        menuTabs: [],
      },
      {
        headerName: 'Phone',
        field: 'phone',
        flex: 1,
        valueGetter: (params: ValueGetterParams<SignRow, SignRow>): string => {
          return new PhonePipe().transform(params.data?.phone);
        },
        hide: false,
        menuTabs: [],
      },
      {
        headerName: '',
        field: '',
        width: 60,
        hide: false,
        sortable: false,
        cellRenderer: AgAnnualSignActionsComponent,
        cellRendererParams: {
          delete: this.delete.bind(this),
          resend: this.resend.bind(this),
          editInfo: this.editInfo.bind(this)
        },
        suppressHeaderMenuButton: true,
      },
    ];
  }

  edit(data: { value: string, index: number; column: string }): void {
    const currentIndex = this.rows.some(r => r.type === SignGroup.Approver) ? data.index - 1 : data.index;
    const ccIndex = currentIndex - this.signatories.length;

    if(this.rows[data.index].type === SignGroup.Signatories) {
      if(data.column === 'email') {
        this.signatories[currentIndex].email = data.value;
      } else if(data.column === 'phone') {
        this.signatories[currentIndex].phone = data.value;
      } else if(data.column === 'fullName')  {
        this.signatories[currentIndex].fullName = data.value;
      }
    }

    if(this.rows[data.index].type === SignGroup.Approver && this.approver) {
      if(data.column === 'email') {
        this.approver.email = data.value;
      } else if(data.column === 'phone') {
        this.approver.phone = data.value;
      }
    }

    if(this.rows[data.index].type === SignGroup.CarbonCopy && this.carbonCopy.length) {
      if (data.column === 'email') {
        this.carbonCopy[ccIndex].email = data.value;
      }
    }

    const invalidApproverEmail = !!this.approver && !this.approver.email;
    const invalidApproverPhone = !this.isPhoneValid(this.approver?.phone ?? '');
    const invalidSignatoryName = !this.signatories?.every(item => item.fullName);
    const invalidSignatoryEmail = !this.signatories?.every(item => item.email);
    const invalidSignatoryPhone = this.signatories?.some(item => {
       !this.isPhoneValid(item.phone);
    });
    const invalidCarbonCopyEmail = !this.carbonCopy?.every(item => item.email);

    this.disableHeaderBtn(invalidApproverEmail || invalidApproverPhone || invalidSignatoryName|| invalidSignatoryEmail || invalidSignatoryPhone || invalidCarbonCopyEmail);
  }

  public isPhoneValid(phone: string): boolean {
    const control = { value: phone } as AbstractControl;
    const errors = CustomFormValidators.phoneNumberValidator(control);

    return errors === null;
  }

  refreshSigningInfo(): void {
    if (!this.documentSigning) {
      return;
    }

    this.documentSigningsService
      .getDocumentSigning(this.documentSigning.documentSigningId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (documentSigning) => {
          this.documentSigning = documentSigning;
          this.updateView();
        },
        error: (err) => {
          console.error('Error fetching document signing info:', err);
        },
      });
  }

  private updateView(): void {
    this.setSigningDateParams();
    this.setApprover();
    this.setSignatories();
    this.setCarbonCopies();
    this.setGridRows();
  }

  public listenSignalRMessages(): void {
    this.signalRService
      .onDocumentSigningStatusChanged()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (message) => {
          if (this.shouldRefreshSigningInfo(message)) {
            console.log('Refreshing signing info for message:', message);
            this.refreshSigningInfo();
          }
        },
        error: (err) => {
          console.error('Error handling SignalR message:', err);
        },
      });
  }

  private shouldRefreshSigningInfo(message: string): boolean {
    return (
      message === this.documentId &&
      this.documentSigning?.signingStatus !== SigningStatus.ReadyToSend
    );
  }

  public listenSendByButton(): void {
    this.form.get('sendBy')?.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((sendBy: SendOption) => {
      if(sendBy === SendOption.Sign) {
        this.setSignatories();
      } else {
        if(this.documentStatus && this.documentStatus > DocumentStatusEnum.SignaturePending) {
          this.disableHeaderBtn(this.documentSigning?.signingStatus !== SigningStatus.Signed);
        }
      }

      this.showSendEmailBtn(sendBy === SendOption.Email);
      this.showSendPaperBtn(sendBy === SendOption.Paper);
    });
  }

  public listenHeaderButton(): void {
    this.confirmSentESignAction$?.pipe(
      filter(confirm => confirm),
      switchMap(() => this.sendToESign()),
      tap(() => this.confirmSentESignAction$?.next(false)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.confirmPaperSignAction$?.pipe(
      filter(confirm => confirm),
      switchMap(() => this.paperSign(this.documentId ?? '')),
      tap(() => this.confirmPaperSignAction$?.next(false)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.confirmAction$?.pipe(
      filter(confirm => confirm),
      switchMap(() => this.confirm()),
      tap(() => this.confirmAction$?.next(false)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  public delete(index: number): void {
    const type = this.rows[index].type;
    const rowToDelete = this.rows[index];
    const modalRef = this.modalService.open(ConfirmComponent);
    (modalRef.componentInstance as ConfirmComponent).title = `Delete`;
    (modalRef.componentInstance as ConfirmComponent).message = `Are you sure you want to delete ${rowToDelete.email} ?`;
    (modalRef.componentInstance as ConfirmComponent).confirmText = 'Ok';
    (modalRef.componentInstance as ConfirmComponent).confirm = () => of(true).pipe(
      switchMap((res) => {
        if (type === SignGroup.Approver) {
          if (this.documentSigning?.approver?.recipientId) {
            return this.documentSigningsService.deleteApprover(this.documentSigning.documentSigningId).pipe(
              tap(() => {
                this.approver = undefined;
                this.rows.splice(index, 1);
                this.rows = [...this.rows];
                this.toastr.success('Approver has been deleted!', 'Success')
              })
            );
          } else {
            this.approver = undefined;
            this.rows.splice(index, 1);
            this.rows = [...this.rows];
            this.toastr.success('Approver has been deleted!', 'Success');
            return of(res);
          }
        } else if (type === SignGroup.CarbonCopy) {
          const cIndex = index - this.signatories.length - (this.approver ? 1 : 0);
          const rIndex = index - (this.approver ? 1 : 0);

          if(this.documentSigning?.carbonCopies?.length) {
            const updatedCarbonCopy = this.carbonCopy
              .filter((cc, index) => cIndex !== index)
              .map(cc => ({
                email: cc.email,
                phone: cc.phone,
                fullName: cc.fullName,
                emailSendId: Guid.EmptyGuid,
              })) as CarbonCopy[];

            return this.documentSigningsService.updateCarbonCopies(this.documentSigning.documentSigningId, updatedCarbonCopy).pipe(
              tap(() => {
                this.carbonCopy.splice(cIndex, 1);
                this.rows.splice(rIndex, 1);
                this.rows = [...this.rows];
                this.toastr.success('Carbon copy has been deleted!', 'Success');
              })
            );
          } else {
            this.carbonCopy.splice(cIndex, 1);
            this.rows.splice(rIndex, 1);
            this.rows = [...this.rows];
            this.toastr.success('Carbon copy has been deleted!', 'Success');
            return of(res);
          }
        }

        return of(res);
      }),
      catchError(err => {
        this.toastr.error('Delete error', 'Error');
        console.error(err);
        return of(null)
      }),
    );

    modalRef.closed.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  protected patchOperation(documentId: string, operations: Operation[]): Observable<AnnualStatement> {
    return this.annualStatementsService.patchAnnualOperation(documentId, operations);
  }

  protected changeDocumentStatusOperation(documentId: string, documentStatus: DocumentStatusEnum): Observable<AnnualStatement> {
    const changeDocumentStatusOperation: Operation[] = [
      {
        path: '/documentStatus',
        op: 'replace',
        value: documentStatus,
      }
    ];

    return this.patchOperation(documentId, changeDocumentStatusOperation);
  }

  public resend(index: number): void {
    const documentId = this.documentId ?? '';
    const signatureId = this.rows[index].signatureId ?? '';
    this.eSignService.resendESign(documentId, signatureId).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  public editInfo(index: number) {
    if(this.documentSigning?.envelopeId == null) {
      this.toastr.error('Envelope is not created yet', 'Error');
      return;
    }

    const componentInstance = this.modalFormsService.openModal(ChangeSignatoryInfoModalComponent, {
      size: 'sm'
    }).componentInstance as ChangeSignatoryInfoModalComponent;

    componentInstance.email = this.rows[index].email;
    componentInstance.phone = this.rows[index].phone;
    componentInstance.id = this.rows[index].signatureId!;
    componentInstance.fullName = this.rows[index].fullName;
    componentInstance.envelopeId = this.documentSigning.envelopeId;

    componentInstance.confirm.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((info: UpdateRecipientInfoModel) => {
     
      this.updateRow(index, info.email, info.phone);
    });

  }

  public sendToESign(): Observable<DocumentSigning> {
    if(!this.signatories.length || !this.template) {
      this.toastr.error('Signatories and email are required', 'Error');
      return EMPTY;
    }

    const signatoriesHasEmail = this.signatories.every(item => item.email);
    if(!signatoriesHasEmail) {
      this.toastr.error('Signatories email is required', 'Error');
      return EMPTY;
    }

    let signatures: Signature[] = [];
    if(this.documentSigning?.signatures?.length) {
      signatures = this.documentSigning.signatures.map(((item, index) => {
        item.email = this.signatories[index].email;
        item.phone = this.signatories[index].phone;
        item.signatory.fullName = this.signatories[index].fullName;
        item.signatory.firstName = this.signatories[index].firstName ?? '';
        item.signatory.lastName = this.signatories[index].lastName ?? '';

        return item;
      }));
    }

    const approver = {
      recipientId: '',
      email: this.approver?.email ?? '',
      phone: this.approver?.phone ?? '',
      fullName: this.approver?.fullName ?? '',
      emailSendId: Guid.EmptyGuid,
      status: ApproverStatus.WaitingForApproval
    }

    const carbonCopyList: CarbonCopy[] = this.carbonCopy?.map(item => ({
      recipientId: '',
      email: item.email,
      phone: item.phone,
      fullName: item.fullName,
      emailSendId: Guid.EmptyGuid,
    }));

    // const attachedNameFilesToSend = this.annualStatementsService.documentsToAttach()
    //   .filter(document => document.selected)
    //   .map(document => document.documentName);

    const eSignBody = {
      signatures,
      carbonCopyList,
      approver: this.approver?.email ? approver : null,
      carbonCopyEmails: this.carbonCopy.map(item => item.email),
      payloadModel: this.payloadModel,
      emailTemplate: this.template
    };

    this.sendSignLoading(true);
    this.disableRow(true);
    const documentId = this.documentId ?? '';

    return this.eSignService.sendToESign(documentId, eSignBody).pipe(
      tap(res => {
        if(res) {
          this.updateDocuments.emit(true);
          this.showStatuses(true);
          this.sendSignLoading(false);
          this.disableRow(false);

          if(eSignBody.approver) {
            this.toastr.success("Your documents were successfully sent to the approver for the review", "Success");
          } else {
            this.toastr.success("Your documents were successfully sent for eSigning", "Success");
          }
        }
      }),
      catchError(err => {
        this.sendSignLoading(false);
        this.disableRow(false);
        this.toastr.error('eSign error', 'Error');
        console.error(err);
        return EMPTY;
      })
    );
  }

  protected sendEmail(): Observable<string> {
    this.sendSignLoading(true);
    const attachedNameFilesToSend = this.annualStatementsService.documentsToAttach()
      .filter(document => document.selected)
      .map(document => document.documentName);
    const emailSendRequest: EmailBulkSendRequest = {
      recipientsList: this.emailList.map(item => item.email),
      payloadModel: this.payloadModel,
      template: this.template,
      attachedNameFilesToSend: this.documentStatus ? [] : attachedNameFilesToSend,
      logo: '',
      brand: null,
    };

    return this.emailTemplatesService.sendBulkEmailTemplate(emailSendRequest, this.documentId ?? '').pipe(
      tap(() => {
        this.toastr.success('Email(s) has been successfully sent', 'Success');
        this.showSendEmailNotification(emailSendRequest.recipientsList);
        this.emailList = [];
        this.carbonCopyEmails = [];
        this.sendSignLoading(false);
      }),
      catchError(err => {
        this.toastr.error('Send email error', 'Error');
        console.error(err);
        this.sendSignLoading(false);
        return EMPTY;
      })
    );
  }

  protected paperSign(documentId: string): Observable<AnnualStatement | DocumentSigning> {
    return from(Promise.all(
      this.uploadedFiles.map(async (file: File) => ({
        fileName: file.name,
        base64FileContent: await toBase64(file),
      }))
    )).pipe(
      switchMap(attachmentsToUpload => {
        const paperSignBody = {
          signedDate: this.formattedSigningDate,
          attachmentsToUpload
        };

        return this.eSignService.paperSign(documentId, paperSignBody).pipe(
          tap(() => {
            this.toastr.success("Paper sign document confirmed", "Success");
            this.updateDocuments.emit(true);
          }),
          catchError(err => {
            this.toastr.error('Paper sign error', 'Error');
            console.error(err);
            return EMPTY;
          })
        );
      }),
    );
  }

  protected confirm(): Observable<AnnualStatement | Document | string> {
    if (this.form.get('sendBy')?.value === SendOption.Email) {
      return this.sendEmail();
    }

    return of('');
  }

  public showStatuses(show: boolean): void {
    this.isSignSent = show;
    this.showVoidEnvelope(this.isSignSent);
    this.colDefs[this.SignColumn.Status].hide = !show;
    this.colDefs = [...this.colDefs];
  }

  public disableHeaderBtn(value: boolean): void {
    this.annualStatementsService.disabledHeaderBtn.set(value);
  }

  public showVoidEnvelope(value: boolean): void {
    value ? this.setEnvelopeGridConfig() : this.setGridConfig();
    this.annualStatementsService.showVoidEnvelope.set(value);
  }

  public showSendEmailBtn(value: boolean): void {
    this.annualStatementsService.showSendEmailBtn.set(value);
  }

  public showSendPaperBtn(value: boolean): void {
    this.annualStatementsService.showSendPaperBtn.set(value);
  }

  public sendSignLoading(value: boolean): void {
    this.annualStatementsService.sendSignLoading.set(value);
  }

  onFilesLoaded(files: File[]): void {
    if (this.uploadedFiles.length + files.length > this.maxFilesNumber) {
      this.setErrorMessage('The maximum number of files to upload is 10. Please use the allowed number of files.');
      return;
    }

    const isAllowedFileExtension = files.some(file => {
      return file.name.endsWith(this.acceptedFormat);
    });
    if (!isAllowedFileExtension) {
      this.setErrorMessage(`Uploaded file has not allowed file extension. Please use one of allowed file extensions: ${ this.acceptedFormat }`);
      return;
    }

    const isNotAllowedFileSize = files.some(file => bytesToMegabytes(file.size) > this.allowedMaxFileSizeMB);
    if (isNotAllowedFileSize) {
      this.setErrorMessage(`It seems that you have uploaded a file that exceeds the allowed size. Please ensure that the file is not larger than ${ this.allowedMaxFileSizeMB } MB`);
      return;
    }

    const totalFilesSize = files.reduce((acc, file) => acc + file.size, 0);
    if (bytesToMegabytes(totalFilesSize) > this.allowedTotalFileSizeMB) {
      this.setErrorMessage(`Uploaded files has not allowed file is too big. Max file size is ${ this.allowedMaxFileSizeMB } MB`);
      return;
    }

    this.uploadedFiles = [...this.uploadedFiles, ...files];

    if(this.uploadedFiles.length && this.signingDate) {
      this.disableHeaderBtn(false);
    }

    this.setErrorMessage('');
  }

  setErrorMessage(errorMessage: string): void {
    this.errorMessage = errorMessage;
  }

  deleteFile(index: number): void {
    this.uploadedFiles.splice(index, 1);
    if(!this.uploadedFiles.length) {
      this.disableHeaderBtn(true);
    }
  }

  onChangeEmailList(emailList: string[]): void {
    if(emailList.length) {
      this.disableHeaderBtn(false);
    } else {
      this.disableHeaderBtn(true);
    }
  }

  onSigningDateChange(signingDate: Date | NgbDateStruct): void {
    if(signingDate && this.uploadedFiles.length) {
      const date = signingDate instanceof Date ? signingDate : DatepickerHelper.getDateFromStruct(signingDate);
      this.formattedSigningDate = formatDate(date, 'YYYY-MM-dd', 'en-US');
      this.disableHeaderBtn(false);
    } else {
      this.disableHeaderBtn(true);
    }
  }

  disableRow(value: boolean): void {
    this.setGridRows();
    this.rows.forEach(row => row.disabled = value);
    this.rows = [...this.rows];
  }

  showSendEmailNotification(recipientsList: string[]): void {
    const emailList = recipientsList.join(', ');
    const currentDate = formatDate(new Date(), 'dd MMM yyyy', 'en-US');
    this.successMessage = `The annual statement was successfully emailed to ${emailList} on ${currentDate}`;
  }
}
