@if(form.get('sendBy')?.value === SendOption.Email && successMessage) {
  <div class="msg-container success">
    <div class="icon-container">
      <i class="icon ic-circle-check"></i>
    </div>
    <div>{{successMessage}}</div>
  </div>
}

@if(documentSigning?.signingStatus === SigningStatus.Signed) {
  <div class="d-flex flex-column gap-24 mb-4">
    <app-lodge-documents
      [documentStatus]="documentStatus"
      [sendBy]="form.get('sendBy')?.value"
      [documentId]="documentId"
      [lodgement]="lodgement"
      [confirmAction$]="confirmAction$"
      (nextStep)="nextStep.emit(true)"
      (updateDocuments)="updateDocuments.emit(true)"
    ></app-lodge-documents>
  </div>
}

<div class="sign-documents-container" [formGroup]="form">
  @if(documentSigning?.signingStatus !== SigningStatus.Signed) {
    <div class="title-container">
      <h3>Sign Documents</h3>
    </div>
  }

  <div class="sent-container">
    <app-radio
      formControlName="sendBy"
      [label]="'Send by'"
      [options]="sendByOptions"
    ></app-radio>

    <div class="buttons-container">
      @if((form.get('sendBy')?.value === SendOption.Sign)) {
        @if(isSignSent && !documentSigning?.signedByPaper && (documentStatus === DocumentStatusEnum.SignaturePending || documentStatus === DocumentStatusEnum.LodgementPending)) {
          <app-button
            [text]="'Void Envelope'"
            [loading]="envelopeLoading"
            [isDisabled]="envelopeLoading || disabledVoidEnvelopeBtn"
            (click)="voidEnvelope()"
          ></app-button>
        } @else {
          <app-button
            [text]="'Carbon Copy'"
            [iconClass]="'icon ic-plus'"
            [btnColor]="'white'"
            [isDisabled]="carbonCopy.length === maxCarbonCopyNumber"
            (click)="(carbonCopy.length <= maxCarbonCopyNumber) && addCarbonCopy()"
          ></app-button>

          <app-button
            [text]="'Approver'"
            [iconClass]="'icon ic-plus'"
            [btnColor]="'white'"
            [isDisabled]="!!approver"
            (click)="!approver && addApprover()"
          ></app-button>
        }
      }

      @if(documentSigning?.signedByPaper && form.get('sendBy')?.value === SendOption.Paper) {
        <app-button
          [text]="'Void Paper Signing'"
          [loading]="envelopeLoading"
          [isDisabled]="envelopeLoading || disabledVoidEnvelopeBtn"
          (click)="voidPaperSign()"
        ></app-button>
      }
    </div>
  </div>

  <div class="form-body-container">
    @if(form.get('sendBy')?.value === SendOption.Sign) {
      @if (documentSigning?.signingStatus !== SigningStatus.Signed) {
        @if (!isSignSent) {
          <app-notification
            [iconClass]="'icon ic-md ic-circle-info'"
            [color]="'orange'"
            [text]="eSignMsg"
          ></app-notification>
        }
      }

      <div class="subject">
        <app-list-grid
          class="sign-list"
          [rowData]="rows"
          [colDefs]="$any(colDefs)"
          [gridOptions]="gridOptions"
          (gridReady)="onGridReady($event)"
          [suppressRowClickSelection]="true"
          [suppressCellFocus]="true"
          [pagination]="false"
          [paginationPageSizeSelector]="false"
        ></app-list-grid>
      </div>
    } @else if(form.get('sendBy')?.value === SendOption.Email) {
      <app-multiple-input-email
        [label]="'To'"
        [placeholder]="'Enter email'"
        [(ngModel)]="emailList"
        (ngModelChange)="onChangeEmailList($event)"
        [ngModelOptions]="{standalone: true}"
      ></app-multiple-input-email>
    } @else if(form.get('sendBy')?.value === SendOption.Paper) {
      @if(documentSigning?.signingStatus !== SigningStatus.Signed) {
        <app-notification
          [iconClass]="'icon ic-md ic-circle-info'"
          [color]="'orange'"
          [text]="paperSignMsg"
        ></app-notification>

        <div class="signing-contact">
          <app-date-picker
            label="Signing Date"
            [minDate]="minDate"
            [(ngModel)]="signingDate"
            (ngModelChange)="onSigningDateChange($event)"
            [ngModelOptions]="{standalone: true}"
          ></app-date-picker>
        </div>

        @if(errorMessage) {
          <app-notification
            [iconClass]="'icon ic-md ic-cancel-circle'"
            [color]="'red'"
            [text]="errorMessage"
          ></app-notification>
        }

        @if(uploadedFiles.length) {
          <div class="files-container">
            @for(file of uploadedFiles; track $index) {
              <app-attached-file-label
                [readonly]="false"
                [filename]="file.name"
                [hideDownloadBtn]="true"
                (deleteFile)="deleteFile($index)"
              ></app-attached-file-label>
            }
          </div>
        } @else {
          <div>
            <app-file-upload
              [multiple]="true"
              [acceptedFormatsList]="[acceptedFormat]"
              [lodgeView]="true"
              (change)="onFilesLoaded($event)"
            >
              <div class="note">{{uploadNotification}}</div>
            </app-file-upload>
          </div>
        }
      } @else {
        <app-notification
          [iconClass]="'icon ic-md ic-circle-check'"
          [color]="'green'"
          [text]="'Documentation was signed by Paper on ' + (signingDate | date: 'dd MMM yyyy')"
        ></app-notification>
      }
    }

    @if(form.get('sendBy')?.value === SendOption.Sign && !isSignSent || form.get('sendBy')?.value === SendOption.Email) {
      <div class="mw-100 overflow-auto">
        <div class="email-preview-wrapper">
          <app-email-preview
            [documentBase]="documentBase"
            [sendByMethod]="form.get('sendBy')?.value"
            [lodgement]="lodgement"
            [isDocument]="true"
            (changeEmail)="onChangeEmail($event)"
          ></app-email-preview>
        </div>
      </div>
    }
  </div>
</div>
