import { Component, DestroyRef, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DragulaModule, DragulaService } from "ng2-dragula";
import { AsyncPipe, NgClass } from "@angular/common";
import { CheckboxComponent } from "../common/checkbox/checkbox.component";
import { DocumentSelection } from "../../../models/document-selection";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DocumentationType } from "../../../models/enums/documentConfirmEnums";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { isDocumentVisible } from "./utils/is-document-visible";

@Component({
  selector: 'app-document-selection',
  standalone: true,
  imports: [
    DragulaModule,
    AsyncPipe,
    CheckboxComponent,
    NgClass,
    NgxSkeletonLoaderModule
  ],
  templateUrl: './document-selection.component.html',
  styleUrl: './document-selection.component.scss'
})
export class DocumentSelectionComponent implements OnInit {
  @Input() title = '';
  @Input() set documentSelectionList(value: DocumentSelection[] | undefined) {
    if(value === undefined) { return }
      this.documentsSelection = value.sort((a, b) => a.sequence - b.sequence);
  }
  @Input() documentationType: DocumentationType | undefined;
  @Input() isDisabled = false;
  @Output() documentSelectionChange = new EventEmitter<DocumentSelection[]>();

  @HostBinding('class.display-none') get hasDocuments() {
    return !this.documentsSelection.length;
  }

  protected readonly groupName = 'DOCUMENTS_SELECTION';
  private _documentsSelection: DocumentSelection[] = [];

  constructor(
    private readonly destroyRef: DestroyRef,
    protected readonly dragulaService: DragulaService
  ) {}

  ngOnInit(): void {
    if (this.isDisabled) {
      return;
    }
    this.documentsSelection = this.documentsSelection.sort((a, b) => a.sequence - b.sequence);

    this.dragulaService.drop(this.groupName)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((el) => {
        el.el.classList.remove('cursor-move');
        this.updateSequenceOnDrop();
      });
    this.dragulaService.drag(this.groupName)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((el) => {el.el.classList.add('cursor-move')});
  }

  onCheckboxChange(selected: boolean, activeDocumentSelection: DocumentSelection): void {
    this.documentsSelection = this.documentsSelection.map(selection => ({
      ...selection,
      included: (selection.fileId && selection.fileId === activeDocumentSelection.fileId || selection.fileName && selection.fileName === activeDocumentSelection.fileName) ? selected : selection.included,
    }));
  }

  updateSequenceOnDrop(): void {
    this.documentsSelection = this.documentsSelection.map((documentSelection, i) => ({
      ...documentSelection,
      sequence: i + 1,
    }));
  }

  set documentsSelection(value: DocumentSelection[]) {
    this._documentsSelection = value;
    this.documentSelectionChange.emit(this._documentsSelection);
  }

  get documentsSelection(): DocumentSelection[] {
    return this._documentsSelection;
  }

  protected readonly isDocumentVisible = isDocumentVisible;
}
