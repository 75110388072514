import { Pipe, PipeTransform } from '@angular/core';
import { SigningStatus } from '../../models/enums/annualStatementEnums';

@Pipe({
  standalone: true,
  name: 'signingStatus'
})
export class SigningStatusPipe implements PipeTransform {
  transform(value: SigningStatus | undefined): string {
    switch (value) {
      case SigningStatus.ReadyToSend:
        return 'Ready to Send';
      case SigningStatus.Created:
      case SigningStatus.Sent:
        return 'Sent';
      case SigningStatus.Signed:
        return 'Signed';
      case SigningStatus.Rejected:
        return 'Rejected';
      default:
        return '-';
    }
  }
}
