import { Component, DestroyRef, EventEmitter, inject, Input, Output, Signal, signal } from '@angular/core';
import { ButtonComponent } from "../../../components/common/button/button.component";
import { CommonModalFormComponent } from "../../common-modal-form/common-modal-form.component";
import { DatePipe, formatDate } from "@angular/common";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import {
  MultipleInputEmailComponent
} from "../../../components/common/multiple-input-email/multiple-input-email.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { RadioComponent } from "../../../components/common/radio/radio.component";
import { ShadowDomViewerComponent } from "../../../components/common/shadow-dom-viewer/shadow-dom-viewer.component";
import { Company } from "../../../../models/company";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EmailTemplatesService } from "../../../../services/email-templates.service";
import { OrganisationService } from "../../../../services/organisation.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { SelectOption } from "../../../../models/selectOptions";
import { SendOption } from "../../../../models/enums/annualStatementEnums";
import { EmailTemplate } from "../../../../models/email-templates/emailTemplate";
import { EmailMultipleAddress } from "../../../../models/email-templates/emailMultipleAddress";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { catchError, EMPTY, finalize, firstValueFrom, map, Observable, of, tap } from "rxjs";
import { IEmailPreviewResponse } from "../../../../models/email-templates/IEmailPreviewResponse";
import { EmailTemplatesHelper, IEmailAndTextTemplatePayloadData } from "../../../../models/email-templates/emailTemplatesHelper";
import { TextMessageTemplatesService } from "../../../../services/sms-templates.service";
import { TextMessagesTemplateSettingsBase } from "../../../../models/text-messages/textMessagesTemplateSettingsBase";
import { UIProfile } from "../../../../models/uiProfile";
import { SelectComponent } from "../../../components/common/select/select.component";
import { ChangeAuthorisation } from "../../../../models/changeAuthorisation";
import { RemindersService } from "../../../../services/reminders.service";
import { DocumentSigning } from "../../../../models/documentEnteties/document-signing";
import { SignatureStatus } from "../../../../models/enums/signatureStatus";
import { TextMessageTemplate } from "../../../../models/text-messages/textMessageTemplate";
import { Guid } from "../../../helpers/guid.helper";
import { ReminderSendingType } from "../../../../models/enums/reminderEnums";
import { setControlDisabled } from "../../../../functions/set-control-disabled";
import { TextMessagesHelper } from "../../../../models/text-messages/textMessagesHelper";
import { AdHocSigningReminderBody } from "../../../../models/adHocSigningReminderBody";
import { ReminderRecord } from '../../../../models/reminderRecord';
import { EmailPayloadBase } from '../../../../models/email-templates/emailPayloadBase';
import { DocumentBase } from '../../../../models/documentEnteties/document-base';
import { DocumentTypePipe } from '../../../../pipes/document-type.pipe';

@Component({
  selector: 'app-ad-hoc-signing-reminder',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModalFormComponent,
    DatePipe,
    FormsModule,
    MultipleInputEmailComponent,
    NgxSkeletonLoaderModule,
    RadioComponent,
    ReactiveFormsModule,
    ShadowDomViewerComponent,
    SelectComponent
],
  providers: [EmailTemplatesService, TextMessageTemplatesService],
  templateUrl: './ad-hoc-signing-reminder.component.html',
  styleUrl: './ad-hoc-signing-reminder.component.scss'
})
export class AdHocSigningReminderComponent {
  @Input() isAnnualReminder = true;
  @Input() lastSendReminderDate: Date | null | undefined;
  @Input() documentBase!: DocumentBase;
  @Input() annualPaymentDeadline: string | null | undefined;
  @Output() sendSigningAdHocReminder = new EventEmitter<ReminderRecord>();

  private activeModal = inject(NgbActiveModal);
  private emailTemplatesService = inject(EmailTemplatesService);
  private textMessagesTemplateService = inject(TextMessageTemplatesService);
  private organisationService = inject(OrganisationService);
  private remindersService = inject(RemindersService);
  private sanitizer = inject(DomSanitizer);
  private toastr = inject(ToastrService);
  private destroyRef = inject(DestroyRef);
  #documentTypePipe = inject(DocumentTypePipe);

  protected readonly SendOption = SendOption;

  public sendByOptions: SelectOption[] = [
    { label: 'Email', value: SendOption.Email },
    { label: 'SMS', value: SendOption.Sms },
  ];

  public loading = false;
  public isSending = false;
  public recipientEmailList: SelectOption[] = [];
  public recipientPhoneList: SelectOption[] = [];
  
  private changeAuthorisation: ChangeAuthorisation | undefined;
  private company: Company | undefined;
  private documentSigning: DocumentSigning | null | undefined;

  public template!: EmailTemplate;
  public smsTemplate!: TextMessagesTemplateSettingsBase;
  private emailTemplates: EmailTemplate[] = [];
  private smsTemplates: Record<string, TextMessagesTemplateSettingsBase> | undefined;
  private payloadModel: Partial<IEmailAndTextTemplatePayloadData> | undefined;
  private smsPayloadModel: Partial<IEmailAndTextTemplatePayloadData> | undefined;
  form = new FormGroup({
    sendBy: new FormControl<SendOption>(SendOption.Email),
    recipientEmails: new FormControl<EmailMultipleAddress[]>([], CustomFormValidators.minArrayLength(1)),
    recipientPhones: new FormControl<SelectOption | null>(null, Validators.required)
  });

  htmlContent = signal<SafeHtml | null>(null);
  smsHtmlContent = signal<SafeHtml | null>(null);
  logo = toSignal(this.organisationService.getSmallLogo()
    .pipe(map(b => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(b))))
    .pipe(catchError(() => of('assets/svg/bolt-logo-icon.svg'))));

  phoneNumber: Signal<string | undefined> = toSignal(
    this.organisationService.getProfile().pipe(
      map(profile => profile.phoneNumber ?? '')
    )
  );

  userProfile: Signal<UIProfile | undefined> = toSignal(this.organisationService.getProfile());

  async ngOnInit(): Promise<void> {
    this.changeAuthorisation = this.documentBase?.changeAuthorisation;
    this.company = this.documentBase?.company;
    this.documentSigning = this.documentBase?.documentSigning

    this.fillEmailsRecipient();
    this.getRecipientPhoneList();
    this.listenSendBy();
    this.listenRecipientPhone();

    this.loading = true;
    this.emailTemplates = await firstValueFrom(this.emailTemplatesService.loadEmailTemplates());
    this.smsTemplates = await firstValueFrom(this.textMessagesTemplateService.loadSmsDefaultTemplates());

    this.setSmsPreview();
    this.setEmailPreview();
    setControlDisabled(this.form.controls.recipientPhones, true);
  }

  private fillEmailsRecipient(): void {
    this.recipientEmailList = this.documentSigning?.signatures
      .filter(signature => signature.status === SignatureStatus.Sent)
      .map(signature => {
        return { label: `${signature.signatory.fullName} (${signature.email ?? ''})`, value: signature.email ?? '' }
      }) ?? [];

    const recipients: EmailMultipleAddress[] = this.documentSigning?.signatures
      .filter(signature => signature.status === SignatureStatus.Sent)
      .map(signature => {
        return { email: signature.email, fullName: signature.signatory.fullName }
      }) ?? [];

    if(recipients.length) {
      this.form.controls.recipientEmails.setValue(recipients);
    }
  }

  private getRecipientPhoneList(): void {
    this.recipientPhoneList = this.documentSigning?.signatures
      .filter(signature => signature.status === SignatureStatus.Sent)
      .map(signature => {
        return { label: `${signature.signatory.fullName} (${signature.phone ?? ''})`, value: signature.phone ?? '' }
      }) ?? [];

    if(this.recipientPhoneList.length) {
      this.form.get('recipientPhones')?.setValue(this.recipientPhoneList[0]);
    }
  }

  private listenSendBy(): void {
    this.form.get('sendBy')?.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(sendBy => {
      setControlDisabled(this.form.controls.recipientPhones, sendBy === SendOption.Email);
      setControlDisabled(this.form.controls.recipientEmails, sendBy === SendOption.Sms);
    });
  }

  private listenRecipientPhone(): void {
    this.form.get('recipientPhones')?.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(recipientPhone => {
      if(recipientPhone) {
        this.setSmsPreview();
      }
    });
  }

  private setSmsPreview(): void {
    if (this.isAnnualReminder) {
      this.selectSmsTemplate('as-signing-reminder');
    } else {
      this.selectSmsTemplate('asic-document-siging-reminder');
    }
  }

  private selectSmsTemplate(templateCode: string): void {
    if(this.smsTemplates) {
      this.recipientPhoneList.forEach(item => {
        if(!this.form.get('recipientPhones')?.value && item.value === this.userProfile()?.externalUserId) {
          this.form.get('recipientPhones')?.patchValue(item);
        }
      });

      const smsTemplate = Object.values(this.smsTemplates)
        .filter(template => template.templateCode === templateCode);
      this.smsTemplate = smsTemplate[0];

      const type = TextMessagesHelper.buildTextMessageTemplatePayload(templateCode).$type;
      this.smsPayloadModel = this.buildPayload(type);
      this.smsPayloadModel.directorFirstName = this.form.get('recipientPhones')?.value
        ? this.form.get('recipientPhones')?.value?.label?.split(' ')[0] ?? ''
        : this.changeAuthorisation?.authorisingParty?.individualDataOverride?.firstName ?? '';

      const replacedSmsTemplate = this.smsTemplate.defaultTemplate
        .replace('{{CompanyName}}', this.smsPayloadModel.companyName ?? '')
        .replace('{{AnnualReviewFeeDeadline}}', this.smsPayloadModel.annualReviewFeeDeadline ?? '')
        .replace('{{OrganisationName}}', this.smsPayloadModel?.organisationName ?? '')
        .replace('{{DirectorFirstName}}', this.smsPayloadModel?.directorFirstName ?? '');

      this.smsHtmlContent.set(this.sanitizer.bypassSecurityTrustHtml(replacedSmsTemplate));
    }
  }

  private buildPayload(type: string): Partial<IEmailAndTextTemplatePayloadData> {
    const billingContactFullName = (this.company?.billingContact?.firstName ?? '') + ' '
      + (this.company?.billingContact?.lastName ?? '');

    const payload = {
      $type: type,
      organisationName: this.userProfile()?.organisationName ?? '',
      organisationEmail: this.userProfile()?.email ?? '',
      organisationPhone: this.userProfile()?.phoneNumber ?? '',
      companyName: this.company?.name ?? '',
      username: this.organisationService.getCurrentUser()?.fullName ?? '',
      directorFirstName: this.changeAuthorisation?.authorisingParty?.individualDataOverride?.firstName ?? this.changeAuthorisation?.chairperson?.individualDataOverride?.firstName ?? '',
      directorFullName: this.changeAuthorisation?.authorisingParty?.individualDataOverride?.fullName ?? this.changeAuthorisation?.chairperson?.individualDataOverride?.fullName ?? '',
      companyACN: this.company?.acn ?? '',
      partner: this.company?.partnerManager?.fullName ?? '',
      asicContact: '',
      accountManager: this.company?.accountManager?.fullName ?? '',
      billingContactFirstName: this.company?.billingContact?.firstName ?? '',
      billingContactFullName: billingContactFullName.trim() ?? '',
    }

    if(this.isAnnualReminder) {
      return {
        ...payload,
        annualReviewFeeDeadline: this.annualPaymentDeadline ? formatDate(this.annualPaymentDeadline, 'dd MMM yyyy', 'en-US') : '',
        annualReviewFeeDeadlineShortFormat: this.annualPaymentDeadline ? formatDate(this.annualPaymentDeadline, 'dd/MM/yyyy', 'en-US') : '',
      }
    } else {
      return {
        ...payload,
        documentName:`ASIC ${this.#documentTypePipe.transform(this.documentBase?.type ?? '')}`,
        documentType: this.changeAuthorisation?.documentationType?.toString() ?? '',
        lodgementDeadline: '',
        lodgementDeadlineShortFormat: '',
        tenDaysBeforeLodgementDeadline: '',
        tenDaysBeforeLodgementDeadlineShortFormat: ''
      }
    }
  }

  private setEmailPreview(): void {
    if (this.isAnnualReminder) {
      this.selectEmailTemplate('as-esign-reminder');
    } else {
      this.selectEmailTemplate('asic-documents-esign-reminder');
    }
  }

  private selectEmailTemplate(templateCode: string): void {
    const emailTemplate = this.emailTemplates.filter(template => template.code === templateCode);
    if (emailTemplate.length) {
      this.template = emailTemplate[0];
      const type = EmailTemplatesHelper.buildEmailTemplatePayload(templateCode).$type;
      this.payloadModel = this.buildPayload(type);
      this.loadPreview().subscribe(() => {
        this.loading = false;
      });
    }
  }


  private loadPreview(): Observable<IEmailPreviewResponse> {
    const errorFormResponse = of({ subject: '', htmlContent: '' });

    return this.emailTemplatesService.loadPreview(EmailTemplatesHelper.buildEmailPreviewRequest(this.buildTemplate(), this.payloadModel as EmailPayloadBase))
      .pipe(
        tap({
          next: (res) => {
            this.htmlContent.set(this.sanitizer.bypassSecurityTrustHtml(res.htmlContent) ?? '')
            this.template.subjectTemplate = res.subject;
          },
          error: (error) => {
            console.warn('[loadPreview] error: ', error);
            this.toastr.error("Something happened while loading preview", "Error");
            this.loading = false;
          }
        }),
        catchError(() => errorFormResponse),
      );
  }

  private buildTemplate(): EmailTemplate {
    return new EmailTemplate({
      ...this.template,
      subjectTemplate: this.template?.subjectTemplate,
      bodyTemplate: this.template.bodyTemplate,
      footerTemplate: this.template.footerTemplate,
    });
  }

  private buildMessageTemplate(): TextMessageTemplate {
    return new TextMessageTemplate({
      textMessageTemplateId: Guid.EmptyGuid,
      organisationId: this.userProfile()?.organisationId,
      code: this.smsTemplate.templateCode,
      template: this.smsTemplate.defaultTemplate
    });
  }

  public sendReminder(): void {
    this.isSending = true;
    const sendingType = this.form.get('sendBy')?.value === SendOption.Email ? ReminderSendingType.Email : ReminderSendingType.SMS;
    const emails = sendingType === ReminderSendingType.Email
      ? (this.form.get('recipientEmails')?.value as unknown as EmailMultipleAddress[])?.map(item => item?.email)
      : [];
    const phones = sendingType === ReminderSendingType.SMS
      ? [this.form.get('recipientPhones')?.value?.value as string]
      : [];

    const emailRecord = {};
    this.documentSigning?.signatures.forEach(signature => {
      const signatoryId = signature.signatoryId;
      if(signatoryId && emails?.includes(signature.email)) {
        emailRecord[signatoryId] = signature.email;
      }
    });

    const body = {
      documentId: this.documentSigning?.documentId,
      entityId: this.company?.entityId,
      sendingType,
      emails: emailRecord,
      phones,
      emailPayloadModel: this.payloadModel,
      emailTemplate: this.buildTemplate(),
      textPayloadModel: this.smsPayloadModel,
      textTemplate: this.buildMessageTemplate()
    } as AdHocSigningReminderBody;

    this.remindersService.sendSigningAdHocReminder(body).pipe(
      tap((reminder ) => {
        this.toastr.success("The signing reminder has been sent", "Success");
        this.sendSigningAdHocReminder.emit(reminder);
        this.activeModal.close();
      }),
      catchError(() => {
        this.toastr.error("The signing reminder hasn't been sent", "Error");
        return EMPTY
      }),
      takeUntilDestroyed(this.destroyRef),
      finalize(() => this.isSending = false)
    ).subscribe();
  }

  public close(): void {
    this.activeModal.close();
  }
}
