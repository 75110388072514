<app-stepper-form
  header="Add Historical Transaction"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
  [submitButtonText]="'Add Transaction'"
  [showSaveAndCompleteLaterBtn]="false"
>
  <div class="step-content full-width" [formGroup]="stepperForm">
    <section
      class="step-section"
      [formGroupName]="StepsEnum.TransactionType"
      [hidden]="currentStep !== StepsEnum.TransactionType"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>
      <app-radio
        formControlName="transactionType"
        [options]="transactionTypeOptions"
        [horizontalAlign]="false">
      </app-radio>
    </section>
    <section
      class="step-section"
      [formGroupName]="StepsEnum.Issue"
      [hidden]="currentStep !== StepsEnum.Issue"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>
      
      <div class="form-group">
        <app-date-picker
          label="Date of Issue"
          formControlName="date"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [smallWidth]="true"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <app-share-issue-block
        [formGroup]="stepperForm.controls[StepsEnum.Issue]"
        [securityRegistryRecords]="securityRegistryRecords"
        [securityTypes]="securityTypes"
        [companyChangeData]="companyChangeData"
      >
      </app-share-issue-block>
    </section>
  </div>
</app-stepper-form>
