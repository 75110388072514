import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PascalCaseToText } from '../../../../../../functions/enums-to-list-formatter';
import { Company } from '../../../../../../models/company';
import { Document } from '../../../../../../models/document';
import { EntityChangeData } from '../../../../../../models/entityChangeData';
import { CompanyChangeData } from '../../../../../../models/enums/companyChangeData';
import { RelationshipType } from '../../../../../../models/enums/relationshipTypeEnum';
import { SelectOption } from '../../../../../../models/selectOptions';
import { ChangeDictionaryHelper } from '../../../../../../models/shared/change-dictionary-helper.model';
import { A2CompanyChangePartyNameComponent } from './a2-company-change-party-name.component';
import { Relationship } from "../../../../../../models/relationship";

export class CompanyChangePartyName extends EntityChangeData {
  static override readonly $type = 'CompanyChangePartyName';

  firstName: string;
  middleName1: string;
  middleName2: string;
  lastName: string;
  companyName: string;
  relationshipIds: string[] = [];

  constructor(data: Partial<CompanyChangePartyName> = {}) {
    super(data);

    this.firstName = data?.firstName ?? '';
    this.middleName1 = data?.middleName1 ?? '';
    this.middleName2 = data?.middleName2 ?? '';
    this.lastName = data?.lastName ?? '';
    this.companyName = data?.companyName ?? '';
    this.relationshipIds = data?.relationshipIds?.filter(Boolean) ?? [];
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict.addDate('Date of Change', this.changeDate);

    // if (this.relationshipIds.length)
    //   dict.addIds('Date of Change', this.relationshipIds);


    if (this.companyName) {
      dict.add('New Company Name', this.companyName);
    } else {
      const newName = [this.firstName, this.middleName1, this.middleName2, this.lastName]
        .filter(Boolean)
        .join(' ');
      dict.add('New Name', newName);
    }

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as A2CompanyChangePartyNameComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as A2CompanyChangePartyNameComponent;

    instance.isEdit = isEdit;
    instance.formModel = structuredClone(this);
    instance.companyChangeData = new CompanyChangeData(company);

    // TODO: remove after individualId works fine
    const officerIndividualsIdDictionary: Record<string, string> = {};

    const nonCorporateSecurityHolders = company.securityholders.filter((securityholder) => securityholder?.individualDataOverride);

    instance.officersChangeNameOptions = structuredClone([...company.officers.filter(r => !r.end), ...nonCorporateSecurityHolders])
      .reduce((officersSelectOptions: SelectOption[], officer) => {
          // TODO: remove after individualId works fine
          if (!(officer.individualDataOverride!.fullName in officerIndividualsIdDictionary)) {
            officerIndividualsIdDictionary[officer.individualDataOverride!.fullName] = Math.random().toString(36).substring(7);
          }

          // TODO: remove after individualId works fine
          officer.key = officer.individualId ?? officerIndividualsIdDictionary[officer.individualDataOverride!.fullName];

          const officerOption: SelectOption = {
            label: officer.individualDataOverride!.fullName,
            value: officer.key,
          };

          if (!(officer.key in instance.officers)) {
            instance.officers[officer.key] = [];

            officersSelectOptions?.push(officerOption);
          }

          instance.officers[officer.key]?.push(officer);

          return officersSelectOptions;
        },
        []
      )
      .sort((a, b) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase()));

    instance.officersChangeNameOptions = instance.officersChangeNameOptions.map((officerOption) => {
      const officerTypes = (instance.officers[officerOption.value as string] as Relationship[])
        .reduce((types: string[], officer) => {
          const type = officer.type === RelationshipType.Securityholder
            ? 'Shareholder'
            : PascalCaseToText(RelationshipType[officer.type]);

          if (!types.includes(type)) {
            types.push(type);
          }

          return types;
        }, []);

      return {
        label: `${ officerOption.label } (${ officerTypes.join(', ') })`,
        value: officerOption.value,
      };
    });
    return modalRef;
  }
}
