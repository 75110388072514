import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SecurityHolding } from '../../../../models/securityHolding';
import { StepperFormComponent } from '../../stepper-form/stepper-form.component';
import { BaseShareFormComponent } from '../components/base-share-form/base-share-form.component';
import { HistoricalTransaction, HistoricalTransactionStepsEnum } from './historical-transaction.model';
import { RadioComponent } from '../../../components/common/radio/radio.component';
import { DatepickerHelper } from '../../../../custom-form-validators/date-picker-form-validators';
import { DatePickerComponent } from '../../../components/common/date-picker/date-picker.component';
import { ShareIssueBlock } from '../components/share-issue-block/share-issue-block.component';
import { SecurityRegistryRecord } from '../../../../models/securityRegistryRecord';
import { SecurityType } from '../../../../models/securityType';
import { Guid } from '../../../helpers/guid.helper';
import { CompanySecurityIssue } from '../share-issue/share-issue.model';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-share-issue',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    StepperFormComponent,
    RadioComponent,
    DatePickerComponent,
    ShareIssueBlock
  ],
  templateUrl: './historical-transaction.component.html',
  styleUrls: [
    '../../stepper-form/base-stepper-component/base-stepper-form.component.scss',
    '../share-cancellation/share-cancellation.component.scss'
  ]
})
export class HistoricalTransactionComponent extends BaseShareFormComponent<HistoricalTransactionStepsEnum, HistoricalTransaction> implements OnInit {
  @Input() companySecurityStructures: SecurityHolding[] = [];

  @ViewChild(ShareIssueBlock) shareIssueBlock!: ShareIssueBlock;

  override readonly StepsEnum = HistoricalTransactionStepsEnum;

  private readonly historicalTransactionSteps = [
    { step: HistoricalTransactionStepsEnum.TransactionType, label: 'Transaction Type' },
    { step: HistoricalTransactionStepsEnum.Issue, label: 'Issue' },
  ];

  override steps = this.historicalTransactionSteps;
  override currentStepIndex = 0;
  override currentStep = this.steps[0].step;

  securityTypes: SecurityType[] = [];

  override stepperForm = new FormGroup({
    [HistoricalTransactionStepsEnum.TransactionType]: new FormGroup({
      transactionType: new FormControl('', Validators.required),
    }),
    [HistoricalTransactionStepsEnum.Issue]: new FormGroup({
      date: new FormControl<Date | null>(null, [Validators.required]),
      ...ShareIssueBlock.defineFormControls(),
    }),
  });

  transactionTypeOptions = [
    {
      label: 'Issue',
      value: 'issue'
    },
    {
      label: 'Cancellation',
      value: 'cancellation'
    },
    {
      label: 'Transfer',
      value: 'transfer'
    },
    // {
    //   label: 'Conversion',
    //   value: 'conversion'
    // },
    {
      label: 'Subdivision or Consolidation',
      value: 'subdivision'
    }
  ];


  constructor() {
    super();
    this.updateFormSteps();
    this.redirectAfterSubmit = false;

    this.stepperForm.controls[HistoricalTransactionStepsEnum.TransactionType].controls.transactionType.valueChanges.subscribe(() => {
      this.updateFormSteps();
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.maxDate = DatepickerHelper.getTodayStruct();
  }

  private updateFormSteps(): void {
    const steps = this.historicalTransactionSteps.filter((step) => {
      if (step.step === HistoricalTransactionStepsEnum.Issue) {
        return this.stepperForm.controls[HistoricalTransactionStepsEnum.TransactionType].controls.transactionType.value === 'issue';
      }

      return true;
    });

    this.steps = steps;
  }


  override setCurrentStep(newStepIndex: number): void {
    this.currentStep = this.steps[newStepIndex].step;
    this.currentStepIndex = newStepIndex;

    this.updateFormSteps();
  }

  override onShareholdersLoadSuccess(shareholders: SecurityRegistryRecord[]): void {
    this.securityRegistryRecords = shareholders.filter(holder => holder?.holders.length > 0);
    this.isLoading = false;

    const allSecurityTypes = shareholders.flatMap(shareholder => shareholder.holdings.map(holding => holding.securityType));
    this.securityTypes = [...new Map<string, SecurityType>(allSecurityTypes.map(type => [type.securityTypeId, type])).values()]
      .filter(type => type.entityId && type.entityId !== Guid.EmptyGuid);
    this.cdr.detectChanges();
    this.setupChange();
  }

  override buildShareFormChange(): CompanySecurityIssue {
    const companySecurityIssue = this.shareIssueBlock.buildShareFormChange();

    companySecurityIssue.changeDate = this.stepperForm.controls[HistoricalTransactionStepsEnum.Issue].value.date!;
    companySecurityIssue.issue.transactionDate = this.stepperForm.controls[HistoricalTransactionStepsEnum.Issue].value.date!;

    return companySecurityIssue;
  }

  public override submit(): void {
    if (this.stepperForm.invalid) {
      this.toastr.error('Please fill required fields', 'Error');
      this.stepperForm.controls[this.currentStep]?.markAllAsTouched();

      return;
    }

    let issue = this.buildShareFormChange();

    issue = issue.prepareToRequest();

    this.isLoading = true;
    this.stepperForm.disable();

    this.securityService.addHistoricalTransaction(this.companyChangeData.entityId, issue)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.stepperForm.enable();
          this.afterSubmit([issue]);
        })
      )
      .subscribe({
        next: () => {
          this.toastr.success('Transaction added.', 'Success');
          this.activeModal.close([issue]);
        },
        error: (error) => {
          console.warn(error);
          this.toastr.error('Failed to add Transaction.', 'Error');
        }
      });
  }
}

