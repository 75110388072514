import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Operation } from 'fast-json-patch';
import { map, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { filterPatchOperations } from '../functions/filter-patch-operations';
import { AsicAgentData } from '../models/AsicAgentData.model';
import { AnnualStatementSettingsData, DocumentSettingsData } from '../models/documentSettingsData';
import { ReminderSettingsData } from '../models/reminderSettingsData';
import { ReminderSettingTypeEnum } from '../models/enums/reminderEnums';
import { DocumentSelection } from '../models/document-selection';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private api: HttpClient) {
  }

  getAgentSettings(): Observable<AsicAgentData> {
    return this.api.get<AsicAgentData>(`${ environment.api_url }/settings/asic`)
      .pipe(map(agent => new AsicAgentData(agent ?? {})));
  }

  patchAgentChanges(operations: Operation[]): Observable<AsicAgentData> {
    return this.api.patch<AsicAgentData>(`${ environment.api_url }/settings/asic`, filterPatchOperations(operations), { headers: { 'Content-Type': 'application/json-patch+json' } });
  }

  getDocumentSettings(): Observable<DocumentSettingsData> {
    return this.api.get<DocumentSettingsData>(`${ environment.api_url }/settings/document`)
      .pipe(map(settings => new DocumentSettingsData(settings ?? {})));
  }

  patchDocumentSettings(operations: Operation[]): Observable<DocumentSettingsData> {
    return this.api.patch<DocumentSettingsData>(`${ environment.api_url }/settings/document`, filterPatchOperations(operations), { headers: { 'Content-Type': 'application/json-patch+json' } });
  }

  getAnnualStatementSettings(): Observable<AnnualStatementSettingsData> {
    return this.api.get<AnnualStatementSettingsData>(`${ environment.api_url }/settings/annual-statement`)
      .pipe(map(settings => new AnnualStatementSettingsData(settings ?? {})));
  }
  getAnnualStatementSelection(): Observable<DocumentSelection[]> {
    return this.api.get<DocumentSelection[]>(`${ environment.api_url }/settings/annual-statement/selection`);
  }

  patchAnnualStatementSettings(operations: Operation[]): Observable<AnnualStatementSettingsData> {
    return this.api.patch<AnnualStatementSettingsData>(`${ environment.api_url }/settings/annual-statement`, filterPatchOperations(operations), { headers: { 'Content-Type': 'application/json-patch+json' } });
  }

  getRemiderSettings(): Observable<ReminderSettingsData> {
    return this.api.get<ReminderSettingsData>(`${ environment.api_url }/settings/reminder`)
      .pipe(map(settings => new ReminderSettingsData(settings ?? {})));
  }

  patchReminderSettings(type: ReminderSettingTypeEnum, operations: Operation[]): Observable<ReminderSettingsData> {
    return this.api.patch<ReminderSettingsData>(`${ environment.api_url }/settings/reminder/${type}`, filterPatchOperations(operations), { headers: { 'Content-Type': 'application/json-patch+json' } });
  }
}
