<div class="card document-settings-page settings-page">
  <app-page-title
    header="Document Settings"
    iconClass="ic-settings"
    backButtonPath="settings"
  >
    <app-button
      id="add-tag-dd"
      text="Edit"
      [iconClass]="'icon ic-xs ic-edit'"
      (clickBtn)="openEditDocumentSettingsModal()"
      *appHasRole="['Admin', 'Manager']"
    />
  </app-page-title>

  <div class="document-settings-page-content">
    @if (!isLoading) {
      <div class="documents-part">
        <div class="part-title">eSigning</div>
        <div class="grid">
          <div class="form-group">
            <label class="title mb-1">Receive email for eSigned documents</label>
            <span class="property"
                  appCopy>{{ NotifyWhenAllPartiesSignedDocumentLabelRecord[documentSettings.esigningEmailRecipients] | defaultValue }}</span>
          </div>
        </div>
      </div>

      <div class="annual-statement-part">
        <div class="part-title">Annual Statement</div>

        <div class="grid">
          <div class="form-group">
            <label class="title mb-1">Do you want to generate an annual statement automatically?</label>
            <span class="property"
                  appCopy>{{ (asSettings.generateAutomatically ? 'Yes' : 'No') | defaultValue }}</span>
          </div>
          <div class="form-group">
            <label class="title mb-1">Do you want to add Director's Signature to the Company Statement?</label>
            <span class="property"
                  appCopy>{{ (asSettings.addSignatoryToCompanyStatement ? 'Yes' : 'No') | defaultValue }}</span>
          </div>
          <div class="form-group">
            <label class="title mb-1">Type of document to generate</label>
            <span class="property"
                  appCopy>{{ (DocumentationType[asSettings.documentTypeToGenerate]) }}</span>
          </div>
          <div class="form-group">
            <label class="title mb-1">Meeting address type</label>
            <span class="property"
                  appCopy>{{ MeetingLocationType[asSettings.meetingLocationType] }}</span>
          </div>
          
          @if(asSettings.meetingLocationType == MeetingLocationType.Online){
            <div class="form-group">
              <label class="title mb-1">Metting online type</label>
              <span class="property"
                    appCopy>{{ MeetingOnlineType[asSettings.meetingOnlineType ?? 0] }} </span>
            </div>
          }

          @if(asSettings.meetingLocationType == MeetingLocationType.Other){
            <div class="form-group">
              <label class="title mb-1">Metting other address</label>
              <span class="property"
                    appCopy>{{ asSettings.otherAddress?.normalizedFullAddress ?? 'N/A'}} </span>
            </div>
          }
        </div>
        <div class="document-selection">
          <app-document-selection
            [title]="'Documents to Generate'"
            [documentSelectionList]="asSettings.documentSelectionFiles.length == 0 ? initDocumentSelectionFiles : asSettings.documentSelectionFiles"
            [documentationType]="asSettings.documentTypeToGenerate"
            [isDisabled]="true"
          ></app-document-selection>
        </div>
      </div>
    }

    <app-loader [loading]="isLoading"></app-loader>
  </div>
</div>
