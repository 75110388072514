import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from '../environments/environment';
import { filterPatchOperations } from '../functions/filter-patch-operations';
import { PageResult } from '../models/pageResult';
import { DocumentRecord } from '../models/documentRecord';
import { Document } from '../models/document';
import { catchError, concatMap, delay, firstValueFrom, forkJoin, from, map, Observable, of, toArray } from 'rxjs';
import { FilterModel, IServerSideGetRowsRequest } from 'ag-grid-enterprise';
import { Relationship } from '../models/relationship';
import { DocumentStatusEnum } from "../models/enums/documentStatusEnum";
import { generate, Observer, Operation } from 'fast-json-patch';
import { ChangeAuthorisation } from "../models/changeAuthorisation";
import { DocumentSelection, DocumentSelectionDto } from "../models/document-selection";
import { DocumentAuthorise } from "../models/documentAuthorise";
import { LodgementDeadline } from "../models/documents";
import { DocumentSigning } from "../models/documentEnteties/document-signing";

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  patchLoading = signal(false);
  saveLoading = signal(false);
  sendSignLoading = signal(false);
  lodgeNowLoading = signal(false);
  disabledHeaderBtn = signal(false);
  showVoidEnvelope = signal(false);
  showSendEmailBtn= signal(false);
  showSendPaperBtn = signal(false);

  constructor(private api: HttpClient) { }

  public createOrUpdateDocument(document: Document, saveAndCompleteLater = false): Observable<{ id: string }> {
    const options = { params: { saveAndCompleteLater } };
    return this.api.post<{ id: string }>(`${ environment.api_url }/documents`, Document.prepareToRequest(document), options);
  }

  public createOrUpdateBulkDocuments(documents: Document[], saveAndCompleteLater = false): Observable<{ id: string }[]> {
    const batches = this.chunkArray(documents, 10);
    return from(batches).pipe(
      concatMap(batch => forkJoin(batch.map(document => this.createOrUpdateDocument(document, saveAndCompleteLater))).pipe(
        delay(1000)
      )),
      toArray(),
      concatMap(responses => of(...responses).pipe(concatMap(res => res), toArray()))
    );
  }

  private chunkArray<T>(array: T[], size: number): T[][] {
    const result: T[][] = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

  public patchDocument(id: string, observer: Observer<Document>) {
    const filteredPatchOperations: Operation[] = observer ? filterPatchOperations(generate(observer)) : [];

    return this.api.patch<Document>(`${environment.api_url}/documents/${id}`, filteredPatchOperations, { headers: { 'Content-Type': 'application/json-patch+json' } })
      .pipe(map((data) => new Document(data)));
  }

  public patchDocumentOperation(id: string, operation: Operation[]) {
    const filteredPatchOperations: Operation[] = operation?.length ? filterPatchOperations(operation) : [];

    return this.api.patch<Document>(`${environment.api_url}/documents/${id}`, filteredPatchOperations, { headers: { 'Content-Type': 'application/json-patch+json' } })
      .pipe(map((data) => new Document(data)));
  }

  public getById(documentId: string) {
    return this.api.get<Document>(`${environment.api_url}/documents/${documentId}`)
      .pipe(map(data => new Document(data)));
  }

  public lodgeDocument(documentId: string, merge = true) {
    return this.api.post<string[]>(`${environment.api_url}/documents/lodge?merge=${merge}`, [documentId]);
  }
  
  public lodgeDocumentBulk(documentsId: string[], merge = true) {
    return this.api.post<string[]>(`${environment.api_url}/documents/lodge?merge=${merge}`, documentsId);
  }

  public getDocumentList(request: IServerSideGetRowsRequest, search?: string) {
    //this is a temporary workaround for polymorphic serialization issue in dot net - discriminator should always come first in the json
    const filterModel = request.filterModel as FilterModel;
    if (filterModel && Object.keys(filterModel)?.length > 0) {
      Object.keys(filterModel).forEach(element => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        filterModel[element] = Object.assign({ filterType: null }, filterModel[element]);
      });
    }

    let params = new HttpParams();
    if (search) {
      params = params.append('search', search);
    }

    return this.api.post<PageResult<DocumentRecord>>(`${environment.api_url}/documents/grid`, request, { params: params })
      .pipe(map(data => { data.records = data.records.map(d => new DocumentRecord(d)); return data; }));
  }
  
  async getCompanyDocuments(companyId: string): Promise<DocumentRecord[]> {
    const request: unknown = {
      filterModel: {
        entityId: {
          filterType: 'set',
          values: [companyId]
        }
      }
    };

    const documents = await firstValueFrom(this.getDocumentList(request as IServerSideGetRowsRequest));
    documents.records.sort((a, b) => {
      return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
    });
    return Object.values(documents.records);
  }

  async getPendingDocuments(companyId: string): Promise<DocumentRecord[]> {
    const status: string[] = [
      DocumentStatusEnum.Draft.toString(),
      DocumentStatusEnum.AuthorisationPending.toString(),
      DocumentStatusEnum.SignaturePending.toString(),
      DocumentStatusEnum.LodgementPending.toString()
    ];
    const request: unknown = {
      filterModel: {
        status: {
          filterType: 'set', values: status
        },
        entityId: {
          filterType: 'set',
          values: [companyId]
        }
      }
    };

    const documents = await firstValueFrom(this.getDocumentList(request as IServerSideGetRowsRequest));
    documents.records.sort((a, b) => {
      return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
    });
    return Object.values(documents.records);
  }


  //TODO for test
  public addtoIndividual(officer: Relationship) {
    return this.api.post<string>(`${environment.api_url}/individuals`, officer);
  }

  authoriseDocument(documentId: string, body: ChangeAuthorisation): Observable<DocumentAuthorise | null> {
    return this.api.post<DocumentAuthorise>(`${environment.api_url}/documents/${documentId}/authorise`, body).pipe(
      catchError(() => of(null))
    );
  }

  authoriseBulkDocuments(createdDocuments: { id: string }[], documentsWithChangeAuthorization: Document[]): Observable<(DocumentAuthorise | null)[]> {
    const authoriseBatches = this.chunkArray(createdDocuments, 10);

    return from(authoriseBatches).pipe(
      concatMap(batch =>
        forkJoin(batch.map(doc => {
          const body = documentsWithChangeAuthorization.find(document => {
            return document.documentId === doc.id
          })?.changeAuthorisation as ChangeAuthorisation;

          return this.authoriseDocument(doc.id, body)
        })).pipe(
          delay(1000)
        )
      ),
      toArray(),
      concatMap(authoriseResponses => of(...authoriseResponses).pipe(concatMap(res => res), toArray()))
    );
  }

  // TODO define the return type
  mergeDocuments(documentIds: string[]): Observable<{documentId: string} | null> {
    return this.api.post<{documentId: string}>(`${environment.api_url}/documents/merge`, documentIds);
  }

  deleteDocumentChange(documentId: string): Observable<null> {
    return this.api.delete<null>(`${environment.api_url}/documents/${documentId}`);
  }

  deleteDocumentChangeBulk(documentsId: string[]): Observable<void> {
    return this.api.post<void>(`${environment.api_url}/documents/bulk/delete`, documentsId);
  }
  
  markAsLodge(documentsId: string[], asicNumber: string): Observable<void> {
    return this.api.post<void>(`${ environment.api_url }/documents/mark-as-lodged?asicNumber=${asicNumber}`, documentsId);
  }

  getDocumentsSelection(documentId: string): Observable<DocumentSelection[]> {
    return this.api.post<DocumentSelectionDto>(`${environment.api_url}/documents/${documentId}/selection`, {}).pipe(
      map(r => r.selection),
    );
  }

  getLodgementDeadline(documentIds: string[]): Observable<LodgementDeadline> {
    return this.api.post<LodgementDeadline>(`${environment.api_url}/documents/lodgement-deadline`, documentIds);
  }

  updateSignDate(documentId: string, signDate: string): Observable<DocumentSigning> {
    const body = { headers: { 'Content-Type': 'application/json-patch+json' } };
    return this.api.patch<DocumentSigning>(`${environment.api_url}/documents/${documentId}/sign-date?signDate=${signDate}`, body);
  }
}
